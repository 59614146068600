import { combineReducers } from 'redux';
import userReducer, { UserData } from './UserReducer';
import adminReducer, { AdminData } from './AdminReducer';

import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from "redux-persist";

export type RootState = {
    userReducer: UserData,
    adminReducer: AdminData
}

const persistConfig = {
    key: "root",
    storage:storageSession,
    // whitelist: ["userReducer"]
    //혹시 유저 정보에 민감 정보가 추가되는 경우 reducer를 분리하거나 session에 저장되는 부분에 암호화 필요
    // blacklist -> 그것만 제외합니다
};


export const rootReducer = combineReducers({
    userReducer,adminReducer
});
export default persistReducer(persistConfig, rootReducer);