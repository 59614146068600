import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers/index';
import * as ROUTES from '../../../../constants/router'
import { Modal } from 'antd';
import './css/UserMain.css';
import metaversgoBtn from './css/images/Button.png';
import campusIcon from './css/images/campusIcon.png'
import monitor from './css/images/monitor.png';
import project from './css/images/project.png';
import kids from './css/images/kids.png';
import modalCloseIcon from './css/images/close.png'

export default function Main() {
    let navigate = useNavigate();
    const {userData, loginStatus} = useSelector((state : RootState) => state.userReducer);
    const onClickHandler = () => {
        if(loginStatus){
            navigate(ROUTES.USER_DOWNLOAD_F)
        } else {
            return(
                Modal.info({
                content : (<div className='mainCampusModalContent'>로그인이 필요합니다.</div>),
                okText:"확인",
                closable:true,
                centered:true,
                className:"mainCampusModal",
                onOk() { 
                  navigate(ROUTES.USER_LOGIN_F, { state: {page:"", type:'login', data: ROUTES.USER_DOWNLOAD_F} })
                },
                onCancel(){
                  navigate(ROUTES.USER_MAIN_F)
                },
                closeIcon:(<img src={modalCloseIcon}/>)
              })
              )
        }
    }
    return (
        <>
        <div className='UserMain01'>                    
            <div className='mainArticle_1'>
                <div className='mainArticle_1-1'>교육에 미래 지향적인 재미를 더하다!</div>
                <div className='mainArticle_1-2'>새로운 학습 경험을 제공하다!</div>
            </div>
            <div className='mainArticle_1-3'>메타캠퍼스는 교사와 학생이 손쉽게 배우고 활용할 수 있는 메타버스 생태계입니다.<br/>
            나만의 월드를 만들고 친구들의 월드를 구경할 수 있습니다.<br/>
            교육공간을 가상공간으로 확장한 메타캠퍼스에서 새로운 학습을 경험해 보세요!</div>
            <MetacampusBtn onClick={()=>onClickHandler()}/>
        </div>
        <div className='UserMain02 userMainFlex'>
            <div className='mainArticleWrapper'>
                <div className='mainArticle_2-1'>가상수업</div>
                <div className='mainArticle_2-2'>가상공간으로 확장된 학교 교육공간에서 </div>
                <div className='mainArticle_2-3'> 생생한 수업을 만나보세요.</div> 
            </div>
            <div className='mainArticleImgWrapper'>
                <img className='monitor' src={monitor} alt="모니터이미지" />
            </div>
        </div>
        <div className='UserMain03 userMainFlex'>
            <div className='mainArticleImgWrapper reverse'>
                <img className='project' src={project} alt="창작에디터프로젝트 이미지" />
            </div>
            <div className='mainArticleWrapper reverse'>
                <div className='mainArticle_3-1'>창작에디터</div>
                <div className='mainArticle_3-2'>콘텐츠를 직접 <strong>창작-공유-구현</strong>하며</div>
                <div className='mainArticle_3-3'>창작 역량을 키워보세요.</div> 
            </div>
        </div>
        <div className='UserMain04'>
            <div className='UserMain04Wrapper'>
                <div className='mainArticle_4-1'>나만의 아바타</div>
                <div className='mainArticle_4-2'>다양한 아이템으로</div>
                <div className='mainArticle_4-3'><strong>나만의 아바타</strong>를 만들어 보세요.</div>
                <div className='mainArticle_4-container'>
                    <div className='mainArticle_4-slide-container'>
                        <div className='slide-image'>
                            <img className='kids' src={kids} alt="다양한 아바타 이미지들" />
                        </div>
                    </div>
                    <div className='mainArticle_4-slide-container'>
                        <div className='slide-image'>
                            <img className='kids' src={kids} alt="다양한 아바타 이미지들" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className='UserMain05'>
            <div className='UserMain05Wrapper'>
                <div className='mainArticle_5'>무한한 창의력을 가진 여러분을 <strong>인천메타캠퍼스</strong>에 초대합니다.</div>
                <div className='MetaversegoWrapper'><MetacampusBtn onClick={()=>onClickHandler()}/></div>
            </div>
        </div>
        </>
    );
}

const MetacampusBtn = (props: any) => {
    let {onClick=()=>console.log("no onClick")} = props;
    return(
        <div className='campusIconWrapper pointer' onClick={onClick}>
            <div>메타캠퍼스로 등교하기</div>
            <img className='campusIcon' src={campusIcon} alt="메타캠퍼스로등교하기버튼" />
        </div>
    )
}