import axios from 'axios';
import { initialState } from '../_reducers/InquiryReducer'

export async function submitInquiry(dataToSubmit:any) {
    console.log(dataToSubmit)
    return {data : true}
}

export async function getInquiryItem(dataToSubmit:any) {
    console.log(dataToSubmit)
    let testResult = {
        inquiryBody : "안녕하세요 여기는 문의하기 내용 영역입니다.<br/>안녕하세요<br/>여기는 문의하기 내용 영역입니다.",
        // inquiryCategory : 0,
        createdDate : new Date(),
        inquiryHeader : "안녕하세요 여기는 문의하기 제목 영역입니다.",
        writerEmail : "wjpark@thehrder.com",
        writerPhone : "01012341234"
    }
    return {data : testResult}
}
export async function saveInquiry(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveInquiry`, dataToSubmit);
}
export async function getInquiryList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getInquiryList`, dataToSubmit);
}
export async function getInquiry(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getInquiry`, dataToSubmit);
}
export async function saveReply(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveReply`, dataToSubmit);
}
export async function deleteInquiry(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteInquiry`, dataToSubmit);
}