import { Button, Input, InputNumber, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, getAdmissionYearList, getUserAccount, onlyNumberAndEnglish } from '../../../../constants/common';
import { getAdminInfo, setInitUserPw } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { alertMessage, confirmMessage, infoMessageTitle } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;

function Register(props:any) {
  const navigate = useNavigate();
  const params = useParams();
  // let {isEditing=false, userData, setInitPassword}=props;
  const {schoolInfo:{schoolUuid} } = useSelector((state : RootState) => state.adminReducer);
  const [userName, setUserName] = useState("");
  const [userAccount, setUserAccount] = useState("");
  const [userUuid, setUserUuid] = useState("");
  const [adminSchoolname, setAdminSchoolname] = useState("");
  const [isEditing, setIsEditing] = useState(true);

  const [msg, setMsg] = useState("");
  const onChangeUserName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setUserName(trimAndNoSpecial(val));       
  };
  const onChangeUserAccount = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setUserAccount(onlyNumberAndEnglish(val));       
  };
  const onChangeKidsName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setAdminSchoolname(trimAndNoSpecial(val));       
  };
  useEffect(()=>{
    if(params && params.detail){
      if(params.detail.length>0) {
        setIsEditing(true)
        setUserAccount(params.detail)
      }
    }
  }, [params])
  const {schoolInfo} = useSelector((state : RootState) => state.adminReducer);
  useEffect(()=>{
    if(isEditing && userAccount.length>0){
      getAdminInfo({account : userAccount, schoolUuid: schoolInfo.schoolUuid})
        .then((res)=>{
          console.log(res.data)
            if(res.data){
              setUserName(res.data.userName)
              setUserUuid(res.data.userUuid)
              setAdminSchoolname(res.data.adminSchoolname)
            }
        })
      
    }
  }, [userAccount])

  const submitHandler=()=>{
    navigate(ROUTES.ADMIN_TEACHER_F)
} 
const setInitPassword = (list:any) => {
  confirmMessage("비밀번호 초기화", <div>정말로 비밀번호를 초기화 하시겠습니까?<br/>진행하시면 초기 비밀번호 ‘1111’로 변경됩니다.</div>, 'primary', ()=>{
      setInitUserPw(list)
      .then((res)=>{
          if(res.data) console.log('초기화 완료')
      })
  })
}
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>교사 관리{isEditing ? "- 조회" : "- 등록 하기"}</div>
        <div>
            <div className='adminRegisterWrapper'>
              <div className='adminRegisterRowWrapper'>
                <div className='adminRegisterRow'>
                  <div className='adminRegisterTitle'>이름</div>
                  <div className='adminRegisterInput'><span>{userName}</span></div>
                </div>
                <div className='adminRegisterRow'>
                  <div className='adminRegisterTitle'>아이디</div>
                  <div className='adminRegisterInput'><span>{userAccount}</span></div>
                </div>
                <div className='adminRegisterRow'>
                  <div className='adminRegisterTitle'>소속</div>
                  <div className='adminRegisterInput'><span>{adminSchoolname}</span></div>
                </div>
              </div>
              <div className='adminExcelRegisterBtn'>
                <Button size='large' onClick={()=>setInitPassword([userAccount])}>비밀번호 초기화</Button>
                <Button type='primary' size='large' onClick={submitHandler}>확인</Button>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Register