import React, { useEffect, useState } from 'react'
import { Button, Table, Input,Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import RegisterByExcel from './RegisterByExcel';
import Register from './Register';
import { confirmMessage } from '../../../../Utils/Utils';
import { deleteStudentList, getUserListBySchool, setInitUserPw } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

export interface DataType {
    kidsName: string;
    userName : string;
    userAccount : string;
    userUuid : string;
  }
  function ParentsList() {
    const {schoolInfo:{schoolUuid}} = useSelector((state : RootState) => state.adminReducer);
    const navigate = useNavigate();
    const columns: ColumnsType<DataType> = [
        {
            title: '이름',
            dataIndex: 'userName',
            sorter: {
                compare: (a, b) => a.userName.localeCompare(b.userName),
            },
            width:'30%'
        },
        {
            title: '자녀',
            dataIndex: 'kidsName',
            sorter: {
                compare: (a, b) => a.kidsName.localeCompare(b.kidsName),
            },
            width:'30%'
        },
        {
            title: '아이디',
            dataIndex: 'userAccount',
            sorter: {
                compare: (a, b) => a.userAccount.localeCompare(b.userAccount),
            },
            width:'30%'
        }
    ];
    const startEditmode = (row:DataType) => {
        navigate(ROUTES.ADMIN_PARENTSITEM_I+row.userAccount)
        setPage("- 수정 하기")
        setSelectedUserData(row)
    }
    const endEditmode = () => {
        setPage("")
        setSelectedUserData(null)
    }
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [searchName, setSearchName] = useState("");
    const [page, setPage] = useState("");
    const [studentList, setStudentList] = useState([]);
    const [studentListSaved, setStudentListSaved] = useState([]);
    const [selectedlist, setSelectedlist] = useState([]);
    const [selectedUserData, setSelectedUserData] = useState<DataType|null>();
    const [searchCategory, setSearchCategory] = useState("");
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    const pageHandler=(data:any)=>{
        // 목록 다시불러오는 함수 추가
        if(data === "") getUserList()
        setPage(data)
    }
    useEffect(()=>{
        if(page==="") getUserList()
    },[page])
    const getUserList = () => {
        getUserListBySchool({schoolUuid : schoolUuid, userType : 2})
        .then((res)=>{
            if(res.data){
                // console.log(res.data)
                let temp = res.data.sort((a: { userAccount: any; },b: { userAccount: any; })=>a.userAccount.localeCompare(b.userAccount))
                setStudentList(temp)
                setStudentListSaved(temp)
            }  
        })
    }
const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRowKeys)
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    }),
};
const setInitPassword = (list:any) => {
    confirmMessage("비밀번호 초기화", <div>정말로 비밀번호를 초기화 하시겠습니까?<br/>진행하시면 초기 비밀번호 ‘1111’로 변경됩니다.</div>, 'primary', ()=>{
        setInitUserPw(list)
        .then((res)=>{
            if(res.data) console.log('초기화 완료')
        })
    })
}
const deleteUser = () => {
    // console.log(selectedlist)
    confirmMessage("학부모 정보 삭제", <div>정말로 삭제하시겠습니까?<br/>삭제하시면 데이터복구 불가능합니다.</div>, 'primary', ()=>{
        deleteStudentList(selectedlist)
        .then((res)=>{
            if(res.data) console.log('삭제 완료')
        })
    })
}
const searchHandler = () => {
    let temp = studentListSaved.slice()
    if(searchName !== ""){
        if(searchCategory==="") temp = temp.filter((c:DataType) => c.kidsName.includes(searchName) || c.userName.includes(searchName) || c.userAccount.includes(searchName))
        if(searchCategory==="userName") temp = temp.filter((c:DataType) => c.userName.includes(searchName))
        if(searchCategory==="userAccount") temp = temp.filter((c:DataType) => c.userAccount.includes(searchName))
        if(searchCategory==="kidsName") temp = temp.filter((c:DataType) => c.kidsName.includes(searchName))
    }
    if(searchName !== "") temp = temp.filter((c:DataType) => c.kidsName.includes(searchName) || c.userName.includes(searchName) || c.userAccount.includes(searchName))
    setStudentList(temp)
}
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학부모 관리{page}</div>
        {page === '' ?
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                    <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                        <Option value= "">전체</Option>
                        <Option value= "userName">이름</Option>
                        <Option value= "kidsName">자녀</Option>
                        <Option value= "userAccount">아이디</Option>
                    </Select>
                    <Input value={searchName} placeholder='학생 이름을 입력해 주세요.' onChange={onChangeSearchName}/>
                    <Button onClick={()=>searchHandler()}>검색</Button>
                </div>
                <div className='adminBtnSection'>
                    <Button disabled={selectedlist.length===0} onClick={()=>setInitPassword(selectedlist)}>비밀번호 초기화</Button>
                    <Button disabled={selectedlist.length===0} onClick={()=>deleteUser()}>삭제</Button>
                    <Button onClick={()=>navigate(ROUTES.ADMIN_PARENTSCREATELIST_F)}>일괄 등록</Button>
                    <Button onClick={()=>navigate(ROUTES.ADMIN_PARENTSCREATE_F)}>등록</Button>
                </div>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={studentList} tableLayout={"auto"}
            rowKey={(render)=>render.userAccount}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {startEditmode(record)}, // click row
                };
              }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}/>
        </div>
        :page === "- 일괄 등록"?
        <RegisterByExcel pageHandler={pageHandler}/>
        :page === "- 등록 하기"?
        <Register pageHandler={pageHandler}/>
        :page === "- 수정 하기"?
        <Register pageHandler={endEditmode} isEditing={true} userData={selectedUserData} setInitPassword={setInitPassword}/>
        :null
        }
    </div>
  )
}

export default ParentsList
