import React, { useEffect, useState } from 'react'
import { Button, Input, Select, Modal } from 'antd';
import { alertMessage, infoMessage } from '../../../../Utils/Utils';
import { checkUserGradeInfo, getUserInfo, editGradeHistory, getUserGradeHistory, } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
import { userTypeStringToInt } from '../../../../constants/auth';
const { Option } = Select;
export interface DataType {
    admissionYear: string;
    grade: number;
    gradeClass: number;
    gradeNumber: number;
    userName : string;
    userAccount : string;
  }
const EditStudent = (props:any) => {
    const params = useParams();
    // let {endEditmode, userData, schoolUuid} = props;
    const [userInfo, setUserInfo] = useState<DataType>();
    const [userName, setUserName] = useState("");
    const [userAccount, setUserAccount] = useState("");
    const [gradeList, setGradeList] = useState([]);
    const [maxGrade, setMaxGrade] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentInfo, setCurrentInfo] = useState(false);
    const [changed, setChanged] = useState(false);
    const [grade, setGrade] = useState(0);
    const [gradeClass, setGradeClass] = useState(0);
    const [gradeNumber, setGradeNumber] = useState(0);
    const [admissionYear, setAdmissionYear] = useState("");
    const [errMsg, setErrMsg] = useState("");
    
    const {schoolInfo} = useSelector((state : RootState) => state.adminReducer);
    useEffect(()=>{
        if(params && params.detail){
          if(params.detail.length>10) {
            setUserAccount(params.detail)
          }
        }
      }, [params])
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setUserName(val)
    } 
    useEffect(()=>{
        if(userAccount.length>0){
        getUserInfo({account : userAccount, schoolUuid: schoolInfo.schoolUuid, userType:`${userTypeStringToInt('학생')}`})
        .then((res)=>{
            
            if(res.data){
                setUserInfo(res.data)
                setUserName(res.data.userName)
            }
        })
        .catch((err)=>console.log(err))
        getUserGradeHistory({account : userAccount, schoolUuid: schoolInfo.schoolUuid})
        .then((res)=>{
            console.log(res.data)
            if(res.data){
                setGradeList(res.data)
            }
        })
        .catch((err)=>console.log(err))
    }
    }, [userAccount])
    const userModalOpenHandler = (modal:boolean, currentInfo:boolean) => {
        setModalOpen(modal)
        setCurrentInfo(currentInfo)
    }
    const userModalCloseHandler = () => {
        setModalOpen(false)
        setGrade(0)
        setGradeClass(0)
        setGradeNumber(0)
        setCurrentInfo(false)
    }
    useEffect(()=>{
        if(errMsg!=="") setErrMsg("")
    }, [grade, gradeClass, gradeNumber])

    useEffect(()=>{
        setGrade(maxGrade+(currentInfo?0:1))
    }, [maxGrade, currentInfo])
    const checkUserGrade = () => {
        let data = {
            grade, gradeClass, gradeNumber,
            schoolUuid:schoolInfo.schoolUuid,
            userAccount
        }
        checkUserGradeInfo(data)
        .then((res)=>{
            if(res.data){
                let temp:any = gradeList.slice();
                let data = {grade, gradeClass, gradeNumber, userAccount, userName, admissionYear }
                if(currentInfo) temp.pop();
                temp.push(data)
                setUserInfo(data)
                // setChanged(true)
                setGradeList(temp)
                userModalCloseHandler()
            } else {
                setErrMsg("해당 학년 반 번호는 이미 등록되어 있습니다.")
            }
        })
        .catch((err)=>{
            if(err.response.data) alertMessage(err.response.data)
        })
    }
    const submitHandler = () => {
        let data = {
            gradeList,
            userInfo : {...userInfo, userName}
        }
        editGradeHistory(data)
        .then((res)=>{
            if(res.data) return infoMessage("저장되었습니다.", ()=>{})
        })
        .catch((err)=>{
            console.log(err)
            if(err.response.data) alertMessage(err.response.data)
        })
    }
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학생 관리 - 수정 하기</div>
        <div className='adminRegisterWrapper'>
            <div className='adminRegisterRowWrapper'>
                <div className='adminRegisterRow'>
                    <div className='adminRegisterTitle'>이름</div>
                    <div className='adminRegisterInput'><Input type='text' size='large' value={userName} onChange={onChangeSearchName}/></div>
                </div>
                <div className='adminRegisterRow'>
                    <div className='adminRegisterTitle'>아이디</div>
                    <div className='adminRegisterInput'>{userAccount}</div>
                </div>
                <div className='adminRegisterRow'>
                    <div className='adminRegisterTitle'>입학연도</div>
                    <div className='adminRegisterInput'>{userInfo?.admissionYear}</div>
                </div>
                <div className='adminRegisterRow adminRegisterRowW'>
                    <div className='adminRegisterTitle'>학년</div>
                    <div className='adminRegisterInput'>
                        {gradeList.length>0 && gradeList.map((c:any)=>{
                            let currentEdit = userInfo?.grade !==undefined ? userInfo.grade === c.grade : true;
                            if(c.grade>maxGrade) setMaxGrade(c.grade)
                            return(
                                <Button key={c.grade} disabled={!currentEdit} onClick={()=>userModalOpenHandler(true, currentEdit)}>{c.grade}학년 {c.gradeClass}반 {c.gradeNumber}번{currentEdit ? ' (현재)':null}</Button>
                            )
                        })}
                        {maxGrade<6 && !changed &&  <Button onClick={()=>userModalOpenHandler(true, false)}>+ 학년 추가</Button>}
                    </div>
                </div>
            </div>
            <div className='adminExcelRegisterBtn'>
                <Button type='primary' size='large' onClick={()=>submitHandler()}>저장</Button>
            </div>
            
            <Modal title="학년 추가 정보" centered open={modalOpen} okText="확인" onOk={() => checkUserGrade()} cancelText="닫기" onCancel={() => userModalCloseHandler()} width={900}>
            <div>
                <div>
                    학년 <Select disabled={currentInfo} value={grade} onChange={(val)=>setGrade(val)}>
                        {maxGrade<6 && Array.from({length: 6-maxGrade}, (_, i) => i + 1).map((c,id)=>{
                            let newGrade = maxGrade+id+1
                            return(<Option key={newGrade} value={newGrade}>{newGrade}</Option>)
                        })}
                    </Select>
                </div>
                <div>
                    반 <Select value={gradeClass} onChange={(val)=>setGradeClass(val)}>
                                    <Option value={0}>선택해 주세요.</Option>
                        {Array.from({length: 20}, (_, i) => i + 1).map((c,id)=>{
                            return(<Option key={c} value={c}>{c}</Option>)
                        })}
                    </Select>
                </div>
                <div>
                    번호 <Select value={gradeNumber} onChange={(val)=>setGradeNumber(val)}>
                                    <Option value={0}>선택해 주세요.</Option>
                        {Array.from({length: 40}, (_, i) => i + 1).map((c,id)=>{
                            return(<Option key={c} value={c}>{c}</Option>)
                        })}
                    </Select>
                </div>
                <div>{errMsg}</div>
            </div>
        </Modal>
        </div>
        </div>
  )
}
export default EditStudent