import React, { useEffect, useState } from 'react'
import { Button, Input, Select, Space, Table, Tabs, Tag } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import Inquiry from '../../../user/pages/mypage/Inquiry';
import dayjs from 'dayjs';
import { getInquiryList } from '../../../../_actions/InquiryAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers/index';
import { inquiryCategoryFromInt } from '../../../../constants/common.js';
import { userTypeStringToInt } from '../../../../constants/auth.js';
let {Option} = Select;
interface DataType {
  inquiryUuid : string;
  inquiryHeader: string;
  inquiryIndex: number;
  inquiryCategory : string;
  writerName: string;
  createdDate: string;
  inquiryStatus: number;
}

function InquiryList() {
  const {state} = useLocation();
  let navigate = useNavigate();
  const [tab, setTab] = useState(0); // 0 - 답변 관리, 1 - 나의 문의, 2- 글쓰기
  const [searchType, setSearchType] = useState("");
  const [selectedUuid, setSelectedUuid] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [inquiryList, setInquiryList] = useState<DataType[]>([]);
  const [inquiryListSaved, setInquiryListSaved] = useState<DataType[]>([]);
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  useEffect(()=>{
      getInquiryList({userUuid : adminData.adminUuid, limit : 500, showType:"notMine"})
      .then((res)=>{
        if(res.data) {
          console.log(res.data)
          setInquiryList(res.data); setInquiryListSaved(res.data)
        }
      })
  },[])

  const onChangeHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setSearchKeyword(event.currentTarget.value)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'inquiryIndex',
      key: 'inquiryIndex',
      sorter: {
        compare: (a, b) => a.inquiryIndex - b.inquiryIndex,
      },
      render: (text, record, index) => <div onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{(currentPage-1)*10+(index+1)}</div>
    },
    {
      title: '문의 유형',
      dataIndex: 'inquiryCategory',
      key: 'inquiryCategory',
      sorter: {
        compare: (a, b) => a.inquiryCategory.localeCompare(b.inquiryCategory),
      },
      render: (text, record) => <div onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{inquiryCategoryFromInt(text)}</div>
    },
    {
      title: '제목',
      dataIndex: 'inquiryHeader',
      key: 'inquiryHeader',
      sorter: {
        compare: (a, b) => a.inquiryHeader.localeCompare(b.inquiryHeader),
      },
      render: (text, record) => <div onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{text}</div>
    },
    {
      title: '사용자',
      dataIndex: 'writerName',
      key: 'writerName',
      sorter: {
        compare: (a, b) => a.writerName.localeCompare(b.writerName),
      },
      render: (text, record) => <div onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{text}</div>
    },
    {
      title: '문의 날짜',
      sorter: {
        compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
      },
      render: (text, record) => {let dayjsFormat = dayjs(text).format('YYYY-MM-DD HH:mm');
      return(<div onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{dayjsFormat}</div>)},
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: '처리상태',
      key: 'inquiryStatus',
      dataIndex: 'inquiryStatus',
      sorter: {
        compare: (a, b) => a.inquiryStatus-b.inquiryStatus,
      },
      render: (text) => {
            let color = !!!text ? 'geekblue' : 'green';
            return (
              <Tag color={color} key={text}>
                {!!!text ? "처리 전" : "답변 완료"}
              </Tag>
            );
          }
    },
    {
      title: ' ',
      key: 'inquiryUuid',
      dataIndex: 'inquiryUuid',
      render: (text, record) => <Button onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/reply/"+record.inquiryUuid)}} disabled={!!record.inquiryStatus}>답변하기</Button>
    }
  ];
  const searchHandler = () => {
    let temp = inquiryListSaved.slice()
        if(searchType === ""){
            temp = temp.filter((c:DataType) => 
            inquiryCategoryFromInt(c.inquiryCategory).includes(searchKeyword) || c.inquiryHeader.includes(searchKeyword) || c.writerName.includes(searchKeyword) )
        } else if(searchType === "inquiryCategory"){
          temp = temp.filter((c:DataType) => 
          inquiryCategoryFromInt(c.inquiryCategory).includes(searchKeyword))
        } else if(searchType === "inquiryHeader"){
          temp = temp.filter((c:DataType) => 
          c.inquiryHeader.includes(searchKeyword))
        } 
        else if(searchType === "writerName"){
          temp = temp.filter((c:DataType) => 
          c.writerName.includes(searchKeyword))
        } 
        setInquiryList(temp)
}
const statusHandler = (num:number) => {
  let temp = inquiryListSaved.slice()
      if(num<3) temp = temp.filter((c:DataType) => c.inquiryStatus===num)
      setInquiryList(temp)
}
const [currentPage, setcurrentPage] = useState(1);
const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setcurrentPage(pagination.current || 1);
    console.log('params', pagination, filters, sorter, extra);
};
  return (
    <div className='adminContentSection'>
      <div className='adminListHeader' style={{marginBottom:'15px'}}>{tab===0 ? "문의 관리" :tab===1?"문의 관리":tab===2?"문의 등록":tab===3?"문의 답변":tab===4?"문의 상세":null}</div>
     {tab===2 ?
     <Inquiry isUser={false} setTab={setTab}/>
     :<>
      <div>
      <Tabs defaultActiveKey={tab===1 ? "1" : "0"} items={
            adminData.userType !== userTypeStringToInt("슈퍼")?
            [
              {key: '0', label: `답변 관리`},
              {key: '1', label: `나의 문의`}
            ]
            :[{key: '0', label: `답변 관리`}]
          } onChange={(key)=>setTab(Number(key))}/>
      
      </div>
        {tab===1 ?
        <MyInquiryList setTab={setTab} tab={tab} setSelectedUuid={setSelectedUuid}/>
        :
        <div>
          <div className='adminSearcWrapper'>
            <div className='adminSearcSection'>
              <Select className='wideSelect' onChange={setSearchType} value={searchType} defaultValue={""}>
                  <Option value= "">전체</Option>
                  <Option value= "inquiryCategory">문의유형</Option>
                  <Option value= "inquiryHeader">제목</Option>
                  <Option value= "writerName">사용자</Option>
              </Select>
              <Input type='text' value={searchKeyword} onChange={onChangeHandler} placeholder='검색어를 입력하세요.'/>
              <Button type='primary' onClick={searchHandler}>검색</Button>
          </div>
          <div className='adminBtnSection'>
            <Select onChange={statusHandler} defaultValue={3}>
              <Option value={3}>처리 상태</Option>
              <Option value={0}>처리 전</Option>
              <Option value={1}>답변 완료</Option>
            </Select>
          </div>
          </div>
          < Table showSorterTooltip={false} columns={columns} dataSource={inquiryList} 
          onChange={onCurrentPageChange}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {setTab(4); setSelectedUuid(record.inquiryUuid)}, // click row
          //   };
          // }}
          rowKey={(render)=>render.inquiryUuid}/>
        </div>
        }
        </>}
    </div>
  )
}

export default InquiryList

//학생이랑 같음?
const MyInquiryList = (props:any) => {
  let {setTab, tab} = props;

  let navigate = useNavigate();
  const [inquiryList, setInquiryList] = useState<DataType[]>([]);
  const [inquiryListSaved, setInquiryListSaved] = useState<DataType[]>([]);
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  interface DataType {
    inquiryUuid : string;
    inquiryHeader: string;
    inquiryIndex: number;
    createdDate: string;
    inquiryStatus: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'inquiryIndex',
      key: 'inquiryIndex',
      render: (text, record, index) => <div>{(currentPage-1)*10+(index+1)}</div>,
      // width:'50%'
    },
    {
      title: '제목',
      dataIndex: 'inquiryHeader',
      key: 'inquiryHeader',
      render: (text, record) => <a onClick={()=>{navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid)}}>{text}</a>,
      // width:'50%'
    },
    {
      title: '문의 날짜',
      dataIndex: 'createdDate',
      key: 'createdDate',
      // sorter: {
      //   compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
      // },
      render: (text, row, index) => {
          let dayjsFormat = dayjs(text).format('YYYY-MM-DD HH:mm');
          return(<div>{dayjsFormat}</div>)
      }
    },
    {
      title: '처리상태',
      key: 'inquiryStatus',
      dataIndex: 'inquiryStatus',
      render: (text) => {
            let color = !!!text ? 'geekblue' : 'green';
            return (
              <Tag color={color} key={text}>
                {!!!text ? "답변 대기" : "답변 완료"}
              </Tag>
            );
          }
    }
  ];
  useEffect(()=>{
    if(adminData.userType===5){setTab(0)}
  },[adminData.userType])
  useEffect(()=>{
    // console.log(adminData.adminUuid)
    if(tab===1){
    getInquiryList({userUuid : adminData.adminUuid, limit : 500, showType:"mine"})
    .then((res)=>{
      if(res.data) setInquiryList(res.data); setInquiryListSaved(res.data)
    })}
  },[tab])
  const [searchKeyword, setSearchKeyword] = useState("");
  const onChangeHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setSearchKeyword(event.currentTarget.value)
  }
  const searchHandler = () => {
    let temp = inquiryListSaved.slice()
    if(searchKeyword !== "" ) temp = temp.filter((c:DataType) => c.inquiryHeader.includes(searchKeyword))
    setInquiryList(temp)
  }
  const [currentPage, setcurrentPage] = useState(1);
  const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
      setcurrentPage(pagination.current || 1);
      console.log('params', pagination, filters, sorter, extra);
  };
  return(
    <div>
          <div className='adminSearcWrapper'>
            <div className='adminSearcSection'>
              <Input type='text' value={searchKeyword} onChange={onChangeHandler} placeholder='검색할 제목을 입력해 주세요.'/>
              <Button type='primary' onClick={()=>searchHandler()}>검색</Button>
            </div>
            <Button onClick={()=>setTab(2)}>글쓰기</Button>
          </div>
          < Table showSorterTooltip={false} columns={columns} dataSource={inquiryList}  rowKey={(render)=>render.inquiryUuid} onChange={onCurrentPageChange} />
          
        </div>
  )
}