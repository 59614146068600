import React, { useEffect, useState } from 'react'
import { Button, Table, Input,Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Register from './Register';
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { deleteTeamList, getTeamList } from '../../../../_actions/ChannelAction';
import dayjs from 'dayjs';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;

export interface DataType {
    teamUuid:string;
    teamIndex:number;
    adminSchoolname: string;
    channelHeader : string;
    teamHeader : string;
    teamLimit : number;
    teamNumber : number;
    adminName:string;
    createdDate:string;
  }
  function ChannelTeam() {
    const navigate = useNavigate();
  
    const columns: ColumnsType<DataType> = [
        {
            title: '번호',
            dataIndex: 'index',
            render: (text, row, index) => {
                    return(<div>{(currentPage-1)*10+(index+1)}</div>)
                }
        },
        {
            title: '채널이름',
            dataIndex: 'channelHeader',
            sorter: {
                compare: (a, b) => a.channelHeader.localeCompare(b.channelHeader),
            },
        },
        {
            title: '팀 이름',
            dataIndex: 'teamHeader',
            sorter: {
                compare: (a, b) => a.teamHeader.localeCompare(b.teamHeader),
            }
        },
        {
            title: '인원',
            dataIndex: 'teamLimit',
            sorter: {
                compare: (a, b) => a.teamLimit-b.teamLimit,
            },
            render: (text, row, index) => {
                return(<div>{row.teamNumber}/{text}</div>)
            }
        },
        {
            title: '담당자',
            dataIndex: 'adminName',
            sorter: {
                compare: (a, b) => a.adminName.localeCompare(b.adminName),
            },
        },
        {
            title: '생성 날짜',
            dataIndex: 'createdDate',
            sorter: {
                compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
            },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD HH:mm');
                return(<div>{dayjsFormat}</div>)
            }
        },
    ];
    const startEditmode = (uuid:string) => {
        navigate(ROUTES.ADMIN_TEAMITEM_I+uuid)
    }
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [searchName, setSearchName] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [teamList, setTeamList] = useState<DataType[]>([]);
    const [teamListSaved, setTeamListSaved] = useState([]);
    const [selectedlist, setSelectedlist] = useState<string[]>([]);
    const {adminData} = useSelector((state : RootState) => state.adminReducer);
  
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    useEffect(()=>{
        getTeamListFunc()
    }, [])
    const getTeamListFunc = () => {
        getTeamList({adminUuid : adminData.adminUuid})
        .then((res)=>{
            if(res.data){
                let temp = res.data;
                setTeamList(temp)
                setTeamListSaved(temp)
            }  
        })
        .catch((error: { response: { data: any; }; "": any; })=>{
            if(error.response.data) alertMessage(error.response.data)
        })
    }
const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRowKeys)
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    }),
};
const deleteTeam = () => {
    // console.log(selectedlist)
    confirmMessage("팀 정보 삭제", <div>정말로 삭제하시겠습니까?<br/>삭제하시면 데이터복구 불가능합니다.</div>, 'primary', ()=>{
        deleteTeamList({selectedlist})
        .then((res)=>{
            if(res.data){ console.log('삭제 완료')
            //테스트 필요
            let teamListTemp = teamList.slice();
            teamListTemp = teamListTemp.filter(c => selectedlist.indexOf(c.teamUuid)<0);
            setTeamList(teamListTemp)
            setSelectedlist([])
        }
        })
    })
}
const searchHandler = () => {
    let temp = teamListSaved.slice()
    if(searchName !== ""){
        if(searchCategory==="") temp = temp.filter((c:DataType) => c.channelHeader.includes(searchName) || c.teamHeader.includes(searchName))
        if(searchCategory==="channelHeader") temp = temp.filter((c:DataType) => c.channelHeader.includes(searchName))
        if(searchCategory==="teamHeader") temp = temp.filter((c:DataType) => c.teamHeader.includes(searchName))
    }
    setTeamList(temp)
}
const [currentPage, setcurrentPage] = useState(1);
const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setcurrentPage(pagination.current || 1);
    console.log('params', pagination, filters, sorter, extra);
};
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>팀 관리</div>
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                    <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                        <Option value= "">전체</Option>
                        <Option value= "channelHeader">채널이름</Option>
                        <Option value= "teamHeader">팀 이름</Option>
                    </Select>
                    <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}/>
                    <Button onClick={()=>searchHandler()}>검색</Button>
                </div>
                <div className='adminBtnSection'>
                    <Button onClick={()=>deleteTeam()}>삭제</Button>
                    <Button type='primary' onClick={()=>navigate(ROUTES.ADMIN_TEAMCREATE_F)}>팀 생성</Button>
                </div>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={teamList} tableLayout={"auto"}
            rowKey={(render)=>render.teamUuid}
            onChange={onCurrentPageChange}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {startEditmode(record.teamUuid)}, // click row
                };
              }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}/>
        </div>
    </div>
  )
}

export default ChannelTeam
