import React, { useEffect, useState } from 'react'
import { Button, Input, Checkbox, Select, InputNumber, TimePicker, DatePicker, Modal } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { alertMessage } from '../../../../Utils/Utils';
import { searchTeacherList } from '../../../../_actions/AdminAction';
import { userTypeStringToInt } from '../../../../constants/auth';
import { getChannel, saveChannel } from '../../../../_actions/ChannelAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { TextArea } = Input;

const eventLocationArr = ["", "메인광장", "학교", "이벤트홀", "인천교육청"]
function ChannelItem() {
    const params = useParams();
    const navigate = useNavigate();
    const [selectedUuid, setSelectedUuid] = useState("");
    const [readOnlyValue, setReadOnlyValue] = useState(false);
    useEffect(()=>{
      if(params && params.detail){
        if(params.detail === 'create'){

        } else if(params.detail.length>10) {
          setReadOnlyValue(true)
          setSelectedUuid(params.detail)
        }
      }
    }, [params])
    const {state} = useLocation();
    const [eventLocation, setEventLocation] = useState(0);
    useEffect(()=>{
      if(state !==null && state.type ==="eventLocation"){
          setEventLocation(state.data)
      }
    }, [state])
    const {adminData} = useSelector((state : RootState) => state.adminReducer);
    let userType = adminData.userType
    const [channelHeader, setChannelHeader] = useState("");
    const [channelUuid, setChannelUuid] = useState("");
    const [channelCode, setChannelCode] = useState("");
    const [eventLimit, setEventLimit] = useState(1);
    const [activeDay1, setActiveDay1] = useState(false);
    const [activeDay2, setActiveDay2] = useState(true);
    const [activeDay3, setActiveDay3] = useState(true);
    const [activeDay4, setActiveDay4] = useState(true);
    const [activeDay5, setActiveDay5] = useState(true);
    const [activeDay6, setActiveDay6] = useState(true);
    const [activeDay7, setActiveDay7] = useState(false);
    const [openTime, setOpenTime] = useState('09:00');
    const [endTime, setEndTime] = useState('16:00');
    const [dates, setDates] = useState<RangeValue>(null);
    const [value, setValue] = useState<RangeValue>(null);
    const [msgModalOpen, setMsgModalOpen] = useState(false);
    const [firstMessage, setFirstMessage] = useState("");
    const [secondMessage, setSecondMessage] = useState("");
    const [firstMessageTemp, setFirstMessageTemp] = useState("");
    const [secondMessageTemp, setSecondMessageTemp] = useState("");
    const [isPassword, setIsPassword] = useState(false);
    const [channelPassword, setChannelPassword] = useState("");
    const [adminUuid, setAdminUuid] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminUuidTemp, setadminUuidTemp] = useState("");
    const [adminNameTemp, setAdminNameTemp] = useState("");
    const [adminList, setAdminList] = useState([]);
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [searchInit, setSearchInit] = useState(true);
    const [searchName, setSearchName] = useState("");

    useEffect(()=>{
      if(readOnlyValue && selectedUuid.length>0){
        getChannel({channelUuid : selectedUuid})
        .then((res)=>{
          setChannelUuid(res.data.channelUuid)
          setChannelHeader(res.data.channelHeader)
          setChannelCode(res.data.channelCode)
          setEventLimit(res.data.eventLimit)
          setActiveDay1(res.data.activeDay1)
          setActiveDay2(res.data.activeDay2)
          setActiveDay3(res.data.activeDay3)
          setActiveDay4(res.data.activeDay4)
          setActiveDay5(res.data.activeDay5)
          setActiveDay6(res.data.activeDay6)
          setActiveDay7(res.data.activeDay7)
          setOpenTime(res.data.openTime)
          setEndTime(res.data.endTime)
          setFirstMessage(res.data.firstMessage)
          setSecondMessage(res.data.secondMessage)
          setChannelPassword(res.data.channelPassword)
          setAdminName(res.data.adminName)
          setAdminUuid(res.data.adminUuid)
          setDates([dayjs(res.data.startDate), dayjs(res.data.endDate)])
        })
        .catch((err)=>{if(err.response.data) alertMessage(err.response.data)})
      }
    }, [readOnlyValue, selectedUuid])
    const onChangeChannelName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setChannelHeader(val)
    }
    const onChangeEventLimit =(val: any) => {
        // let val:number = Number(event.currentTarget.value);
        // if(val>0 && val<=36) setEventLimit(`${val}`);  
        if(val>0 && val<=36) setEventLimit(val);          
        };
    const onCheckFunc =  (e:CheckboxChangeEvent) => {
        if(e.target.name ==='activeDay1') setActiveDay1(!activeDay1)
        else if(e.target.name ==='activeDay2') setActiveDay2(!activeDay2)
         else if(e.target.name ==='activeDay3') setActiveDay3(!activeDay3)
         else if(e.target.name ==='activeDay4') setActiveDay4(!activeDay4)
         else if(e.target.name ==='activeDay5') setActiveDay5(!activeDay5)
         else if(e.target.name ==='activeDay6') setActiveDay6(!activeDay6)
         else if(e.target.name ==='activeDay7') setActiveDay7(!activeDay7)
         else if(e.target.name ==='isPassword') setIsPassword(!isPassword)
        
         else if(e.target.name ==='activeDay' && !(activeDay1&&activeDay2&&activeDay3&&activeDay4&&activeDay5&&activeDay6&&activeDay7)){
          setActiveDay1(true)
          setActiveDay2(true)
          setActiveDay3(true)
          setActiveDay4(true)
          setActiveDay5(true)
          setActiveDay6(true)
          setActiveDay7(true)
        } else if(e.target.name ==='activeDay') {
          setActiveDay1(false)
          setActiveDay2(false)
          setActiveDay3(false)
          setActiveDay4(false)
          setActiveDay5(false)
          setActiveDay6(false)
          setActiveDay7(false)
        }
    }
    const onChangeOpenTime = (time: Dayjs|null, timeString: string) => {
        setOpenTime(timeString || "00:00");
      };
    const onChangeEndTime = (time: Dayjs|null, timeString: string) => {
      setEndTime(timeString || "00:00");
    };
    const format = 'HH:mm';
    const disabledDate: RangePickerProps['disabledDate'] = (current:Dayjs) => {
      if (!dates || readOnlyValue) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'month') >= 3;
      const tooEarly = dates[1] && dates[1].diff(current, 'month') >= 3;
      return !!tooEarly || !!tooLate;
    };
    const onOpenChange = (open: boolean) => {
      if (open) {
        setDates([null, null]);
      }
    };
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if(e.target.name ==="firstMessage") setFirstMessageTemp(e.target.value)
      if(e.target.name ==="secondMessage") setSecondMessageTemp(e.target.value)
      console.log(e.target.name, ':', e.target.value);
    };
    const msgOpen = () => {
      setMsgModalOpen(true)
      setFirstMessageTemp(firstMessage)
      setSecondMessageTemp(secondMessage)
    }
    const msgClose = () => {
      setMsgModalOpen(false)
      setFirstMessage(firstMessageTemp)
      setSecondMessage(secondMessageTemp)
      Modal.info({
        title:"NPC 메시지",
        content:"저장되었습니다.",
        centered:true
      })
    }
    const onChangePassword = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
      let val:string = event.currentTarget.value.toString();
      setChannelPassword(val)
  }
  const adminOpen = () => {
    setAdminModalOpen(true)
    setAdminNameTemp("")
    setadminUuidTemp("")
    setSearchName("")
    setAdminList([])
  }
  const adminClose = () => {
    setSearchInit(true)
    setAdminModalOpen(false)
    setAdminName(adminNameTemp)
    setAdminUuid(adminUuidTemp)
  }
  const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setSearchInit(true)
    let val:string = event.currentTarget.value.toString();
    setSearchName(val)
  }
  const searchHandler = () => {
    searchTeacherList({searchName})
    .then((res)=>{
      if(res.data) setAdminList(res.data); setSearchInit(false);
    })
  }
  const searchResultSelectHandler = (uuid:string, name:string) => {
    setSearchName(name)
    setadminUuidTemp(uuid);
    setAdminNameTemp(name)
  }
  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      console.log(e)
     alertMessage('복사에 실패하였습니다. 직접 복사를 진행해주세요.');
    }
};
useEffect(()=>{
  if(adminData.userType !== userTypeStringToInt("슈퍼") && adminUuid==='') setAdminUuid(adminData.adminUuid)
},[adminData.userType])
  const submitHandler = () => {
    if(channelHeader.length ===0) return alertMessage("채널 이름을 입력하세요.")
    if(!(activeDay7||activeDay1||activeDay2||activeDay3||activeDay4||activeDay5||activeDay6)) return alertMessage("활성 날짜를 지정해주세요.")
    if(adminData.userType === userTypeStringToInt("슈퍼") &&adminUuid==='') return alertMessage("담당자를 지정해주세요.")
    if(!readOnlyValue && (!dates || !dates[0] || !dates[1])) return alertMessage("활성 기간을 선택해주세요.")
    if(isPassword && channelPassword ==="") return alertMessage("비밀번호를 설정해주세요.")
    let startDateFormat = dates !== null && dates[0] !==null ? dates[0].format("YYYY-MM-DD") : "YYYY-MM-DD";
    let endDateFormat = dates !== null && dates[1] !==null ? dates[1].format("YYYY-MM-DD") : "YYYY-MM-DD";
    if(startDateFormat==="YYYY-MM-DD" || endDateFormat==="YYYY-MM-DD") return alertMessage("활성 기간을 선택해주세요.")
    let data = {
      userUuid : adminData.adminUuid, // 생성자
      channelUuid,
      channelCode,
      channelHeader,
      startDate : startDateFormat, 
      endDate : endDateFormat, 
      eventLocation : eventLocation,
      eventLimit,
      adminUuid, // 담당자
      openTime,
      endTime, 
      channelPassword,
      firstMessage,
      secondMessage,
      activeDay1 : activeDay1 ? 1 : 0,
      activeDay2 : activeDay2 ? 1 : 0,
      activeDay3 : activeDay3 ? 1 : 0,
      activeDay4 : activeDay4 ? 1 : 0,
      activeDay5 : activeDay5 ? 1 : 0,
      activeDay6 : activeDay6 ? 1 : 0,
      activeDay7 : activeDay7 ? 1 : 0
    }
    saveChannel(data)
    .then((res)=>{
      if(res.data){
        let { channelCode , channelUuid } = res.data;
        Modal.info({
          title : "채널 등록 완료",
          okText:"확인",
          onOk() {
            !readOnlyValue ? navigate(ROUTES.ADMIN_CHANNELITEM_I+channelUuid, {state: {page:"", type:'eventLocation', data: eventLocation}})
            : navigate(ROUTES.ADMIN_CHANNELLIST_F, {state: {page:"", type:'eventLocation', data: eventLocation}})
          },
          content:(
            <div>채널 공유 코드 : <u onClick={()=>handleCopyClipBoard(channelCode)}>{channelCode}</u> <button onClick={()=>handleCopyClipBoard(channelCode)}>복사</button></div>
          ),
          centered:true
          
        })
      }
    })
    .catch((err)=>{
      if(err.response.data) alertMessage(err.response.data)
    })
  }
  const nullFunc = () => {

  }
  return (
    <div className='adminContentSection'>
      <div className='adminListHeader'>{eventLocationArr[eventLocation||0]} {readOnlyValue?'채널 상세':'채널 생성'}</div>
      <div className='adminRegisterWrapper'>
        <div className='adminRegisterRowWrapper adminRegisterRowWrapperW'>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>채널 이름</div>
            <div className='adminRegisterInput'><Input size='large' value={channelHeader} placeholder='채널 이름을 입력해 주세요.' onChange={onChangeChannelName}/></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>최대 인원</div>
            <div className='adminRegisterInput'><InputNumber size='large' controls={false} min={1} max={36} value={eventLimit} placeholder='' onChange={onChangeEventLimit}/></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>활성 날짜</div>
            <div className='adminRegisterInputF'>&nbsp;
              <Checkbox onChange={onCheckFunc } checked={activeDay2} name="activeDay2" value={activeDay2} /> 월요일
              <Checkbox onChange={onCheckFunc } checked={activeDay3} name="activeDay3" value={activeDay3} /> 화요일
              <Checkbox onChange={onCheckFunc } checked={activeDay4} name="activeDay4" value={activeDay4} /> 수요일
              <Checkbox onChange={onCheckFunc } checked={activeDay5} name="activeDay5" value={activeDay5} /> 목요일
              <Checkbox onChange={onCheckFunc } checked={activeDay6} name="activeDay6" value={activeDay6} /> 금요일
              <Checkbox onChange={onCheckFunc } checked={activeDay7} name="activeDay7" value={activeDay7} /> 토요일
              <Checkbox onChange={onCheckFunc } checked={activeDay1} name="activeDay1" value={activeDay1} /> 일요일
              <Checkbox onChange={onCheckFunc } checked={activeDay1&&activeDay2&&activeDay3&&activeDay4&&activeDay5&&activeDay6&&activeDay7} name="activeDay" value={activeDay1&&activeDay2&&activeDay3&&activeDay4&&activeDay5&&activeDay6&&activeDay7} /> 전체
            </div>
          </div>
          {userType == userTypeStringToInt("슈퍼") &&
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>담당자</div>
            <div className='adminRegisterInputF'><Input size='large' value={adminName} readOnly onClick={()=>adminOpen()}/>
            <Button type="primary" onClick={() =>adminOpen()}>조회하기</Button></div>
          </div>}
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>오픈 시간</div>
            <div className='adminRegisterInput'><TimePicker size='large' locale={locale} onChange={onChangeOpenTime} value={dayjs(openTime, 'HH:mm')} format={format} /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>마감 시간</div>
            <div className='adminRegisterInput'><TimePicker size='large' locale={locale} onChange={onChangeEndTime} value={dayjs(endTime, 'HH:mm')} format={format} /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>활성 기간</div>
            <div className='adminRegisterInput adminRegisterInputFC'>
              <RangePicker size='large' locale={locale} value={dates || value} disabledDate={disabledDate} onCalendarChange={(val) => setDates(val)}
                onChange={(val) => setValue(val)} onOpenChange={onOpenChange} /><span>최대 3개월까지 가능합니다.</span></div>
          </div>
          { channelCode.length>0 && 
            <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>채널 공유 코드</div>
            <div className='adminRegisterInput'><u>{channelCode}</u><Button onClick={()=>handleCopyClipBoard(channelCode)}>복사</Button></div>
          </div>}
          { eventLocationArr[eventLocation].includes("이벤트홀") && 
            <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>NPC 메시지</div>
            <div className='adminRegisterInput'><Button type="primary" onClick={() =>msgOpen()}>설정하기</Button></div>
          </div>}
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'><Checkbox onChange={onCheckFunc} checked={isPassword} name="isPassword" value={isPassword} />비밀번호</div>
            <div className='adminRegisterInput adminRegisterInputNM'><Input.Password size='large'
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            type='password' disabled={!isPassword} value={channelPassword} placeholder='비밀번호를 설정해 주세요.' onChange={onChangePassword}/></div>
          </div>
        </div>
          
          {readOnlyValue ? 
          <div className='adminExcelRegisterBtn'><Button size='large' type="primary" onClick={() =>submitHandler()}>저장</Button></div>
          :<div className='adminExcelRegisterBtn'><Button size='large' onClick={() =>navigate(ROUTES.ADMIN_CHANNELLIST_F, {state: {page:"", type:'eventLocation', data: eventLocation}})}>취소</Button><Button size='large' type="primary" onClick={() =>submitHandler()}>등록</Button></div>}




          <Modal title="NPC 메시지 설정" centered open={msgModalOpen} okText="저장" onOk={() => msgClose()} cancelText="닫기" onCancel={() => setMsgModalOpen(false)} width={700}
          bodyStyle={{height:'230px'}}>
            <div style={{ display:'flex', justifyContent:'space-around' }}>
              <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', width:'40%' }}>
                <span style={{textAlign:'center'}}>힘찬이</span>
                <TextArea
                showCount
                maxLength={50}
                style={{ height: 180, resize: 'none' }}
                onChange={onChange}
                placeholder=""
                name="firstMessage"
                value={firstMessageTemp}
              />
              </div>
              <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', width:'40%' }}>
                <span style={{textAlign:'center'}}>자람이</span>
                <TextArea
                showCount
                maxLength={50}
                style={{ height: 180, resize: 'none' }}
                onChange={onChange}
                placeholder=""
                name="secondMessage"
                value={secondMessageTemp}
              />
              </div>
            </div>
          </Modal>
          <Modal title="교사 검색" centered open={adminModalOpen} okText="등록" onOk={() => adminClose()} cancelText="닫기" onCancel={() => {setAdminModalOpen(false); setSearchInit(true);}} width={700}>
            <div style={{display:'flex', alignItems:'center'}}><Input value={searchName} placeholder='' onChange={onChangeSearchName}/><Button onClick={()=>searchHandler()}>검색</Button></div>
            <div style={{margin:'20px'}}>
              {
              adminList.length>0 ? adminList.map((c,i)=>{
                return (
                <div key={i}>
                  <input type='radio' name="adminNameTemp" value={c["admin_name"]} id={`${c["admin_uuid"]}`} onChange={()=>searchResultSelectHandler(c["admin_uuid"], c["admin_name"])}/> <label htmlFor={`${c["admin_uuid"]}`}>{c["admin_name"]}</label>
                </div>)
              })
              :!searchInit?<div style={{textAlign:'center'}}>검색 결과가 없습니다.</div>:null
              }</div>
          </Modal>
      </div>
    </div>
  )
}

export default ChannelItem