import React, { useState } from 'react'
import { passwordValidCheck } from '../../../../constants/common';
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { changePw } from '../../../../_actions/AdminAction';
import * as ROUTES from '../../../../constants/router'
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';



function PasswordChange() {
  let navigate = useNavigate();
  const {adminData:{adminName, adminAccount, adminSchoolname, adminSchooluuid, userType}} = useSelector((state : RootState) => state.adminReducer);
  const [adminPassword, setAdminPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [adminPasswordMsg, setAdminPasswordMsg] = useState("");
  const [newPasswordMsg, setNewPasswordMsg] = useState("");
  const [passwordConfirmMsg, setPasswordConfirmMsg] = useState("");
  const onPasswordHandler = (event: { currentTarget: { name : React.SetStateAction<string>, value: React.SetStateAction<string>; }; }) => {
    setAdminPasswordMsg(""); setNewPasswordMsg(""); setPasswordConfirmMsg("")
    if(event.currentTarget.name === 'adminPassword') setAdminPassword(event.currentTarget.value)
    if(event.currentTarget.name === 'newPassword') setNewPassword(event.currentTarget.value)
    if(event.currentTarget.name === 'passwordConfirm') setPasswordConfirm(event.currentTarget.value)
    }
const onSubmitHandler = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if(adminPassword ===""){
      setAdminPasswordMsg("비밀번호를 입력하세요.")
      return;}
    if(newPassword ===""){
      setNewPasswordMsg("비밀번호를 입력하세요.")
      return;}
    if(passwordConfirm ===""){
      setPasswordConfirmMsg("비밀번호를 입력하세요.")
      return;}
    if(newPassword !== passwordConfirm) return alertMessage("새로운 비밀번호를 확인해주세요.")
    if(!passwordValidCheck(newPassword)) return alertMessage("새로운 비밀번호 유효성을 확인해주세요.")
    
    let body = {account : adminAccount, password: passwordIncoding(adminPassword), newPassword: passwordIncoding(newPassword)}
    changePw(body).then(((response)=>{
         if(response.data) {
            setAdminPassword(""); setAdminPasswordMsg("")
            setNewPassword(""); setNewPasswordMsg("")
            setPasswordConfirm(""); setPasswordConfirmMsg("")
            Modal.info({
              title : '비밀번호 변경 완료',
              content: (
                <div style={{display:'flex', justifyContent:'center'}}>
                  <div>
                  변경하신 비밀번호입니다.
                  꼭 기억해 주세요.
                  <div><strong><u>{newPassword}</u></strong></div>
                  </div>
                </div>
              ),
              className:'pwChangeModal',
              okText:'확인',
              centered:true
          })
        }
    })).catch((error) => {
        console.log(error)
        if(error.response.data){
          if(error.response.data.resultMsg==="불일치"){
            setAdminPasswordMsg("불일치합니다.")
          }
        }
    });
    }
  return (
    <>
    <div  className='userInfoWrapper'>
        <div className='userInfoContainer userInfoContainer2 userInfoContainer3'>
          <div>기존 비밀번호 확인</div>
          <div>
            <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='adminPassword' value={adminPassword}
            placeholder='기존 비밀번호 입력' onChange={onPasswordHandler} required/>
            <div className='userInfoMsg'>{adminPasswordMsg}</div>
          </div>
        </div>
        <div className='userInfoContainer userInfoContainer2 userInfoContainer3'>
          <div>새로운 비밀번호</div>
          <div>
            <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='newPassword' value={newPassword}
            placeholder='새로운 비밀번호 입력' onChange={onPasswordHandler} required/>
            <div className='userInfoMsg'>{newPasswordMsg}</div>
            </div>
          </div>
        <div className='userInfoContainer userInfoContainer2 userInfoContainer3 userInfoContainerL userInfoContainerM'>
          <div>새로운 비밀번호 재입력</div>
          <div>
            <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='passwordConfirm' value={passwordConfirm}
            placeholder='새로운 비밀번호 재입력' onChange={onPasswordHandler} required/>
            <div className='userInfoMsg'>{passwordConfirmMsg===''?'최소8~최대16글자 (영문+숫자+기호) 기호 : !@#$%^&*()_+':passwordConfirmMsg}</div>
            </div>
        </div>
        <Button size='large' type='primary' onClick={onSubmitHandler}>변경</Button>
    </div>
    </>
  )
}

export default PasswordChange