import {USER_INITPWCHANGED, USER_LOGIN, USER_LOGOUT} from '../_actions/types';

export type UserData = {
    loginStatus : boolean,
    userData : {
        userUuid : string,
        result : number,
        userAccount : string,
        passwordChange : number,
        userName : string,
        userType: number,
        grade:number,
        gradeClass:number,
        gradeNumber:number,
        schoolName:string,
        kidsName:string
            }
}
export const initialState : UserData = {
        loginStatus : false,
        userData : {
            userUuid: "",
            result : 0,
            userAccount: '',
            passwordChange : 1,
            userName:'사용자',
            userType:0,
            grade:0,
            gradeClass:0,
            gradeNumber:0,
            schoolName:"",
            kidsName:"",
        }
};

export default function userReducer (state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, ...action.payload } // spread operator
        case USER_LOGOUT:
            return { ...initialState }
        case USER_INITPWCHANGED:
            return { ...state, userData :{...state.userData, passwordChange : action.payload.resultCode? 0 : 1} }
        default:{
            return state;
        }
    }
}