import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
import { passwordValidCheck } from '../../../../constants/common';
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { changePw2, checkNeis, getSessionId } from '../../../../_actions/AdminAction';
import { Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function FindAccounInfo() {
    const [page, setpage] = useState(0); // 0-기본, 1-아이디, 2-비밀번호
    const [tab, settab] = useState(1); // 1-아이디, 2-비밀번호
  return (
    <div className='adminCenterWrapper adminLoginWrapper'>
      <div className='adminCenterContainer'>
        {page === 0 ?
        <>
            <div className='adminCenterHeader'>ID/비밀번호 찾기</div>
            <div className='adminSignUpTypeBtnWrapper'>
                <Button onClick={()=>setpage(1)}>아이디<br/>찾기</Button>
                <Button onClick={()=>setpage(2)}>비밀번호<br/>재설정</Button>
            </div>
            {/* <div className='adminCenterBottom adminCenterBottom2' >
                {tab ===1 ?
                <Button size='large' type='primary' onClick={()=>setpage(1)}>아이디 찾기</Button>
                :<Button size='large' type='primary' onClick={()=>setpage(2)}>비밀번호 재설정</Button>
                }
            </div> */}
        </>
        :page === 1 ?
            <FindAccount setpage={setpage} settab={settab}/>
        :page === 2 ?
            <FindPassword setpage={setpage} settab={settab}/>
        :null
    }
        </div>
    </div>
  )
}

export default FindAccounInfo

const FindAccount = (props:any) => {
    let {setpage, settab} = props;
    let navigate = useNavigate();
    const [userAccount, setuserAccount] = useState("");
    const [output, setOutput] = useState("");
    useEffect(()=>{
        getSessionId()
        .then((res)=>{
            window.epkiInit(res.data)
        }) 
    }, [])
    const fincAccountFunc=()=>{
        window.epkiSign(`${process.env.REACT_APP_SALT}`, setOutput);
    }
    useEffect(()=>{
        if(output.length>0){
            checkNeis({output})
            .then((res)=>{
                console.log(res.data)
                if(!!res.data && res.data !=='실패'){
                    setuserAccount(res.data)
                } else {
                    alertMessage("없는 사용자입니다. 관리자에 문의하세요.")
                }
            })
        }
    }, [output])
    return(
        <>
        {userAccount.length>0 ? 
        <>
            <div className='adminCenterHeader'>아이디 찾기</div>
            <div className='adminCenterSubHeader adminFindSubHeader'>아이디 : <u><strong> {userAccount} </strong></u></div>
            <div className='flexFull adminLoginBtn adminFindBtn'>
                <Button size='large' type="primary" onClick={()=>navigate(ROUTES.ADMIN_LOGIN_F)}>확인</Button>
            </div>
        </>
        :
        <>
            <div className='adminCenterHeader'>아이디 찾기</div>
            <div className='adminSignUpTypeBtnWrapper'>
                <Button onClick={()=>{setpage(0); settab(1);}}>취소</Button><Button onClick={()=>fincAccountFunc()}>인증하기</Button>
            </div>
        </>
        }
        </>
        
    )
}
const FindPassword = (props:any) => {
    let {setpage, settab} = props;
    let navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const [output, setOutput] = useState("");
    const [userAccount, setuserAccount] = useState("");
    useEffect(()=>{
        getSessionId()
        .then((res)=>{
            window.epkiInit(res.data)
        }) 
    }, [])
    const fincAccountFunc=()=>{
        window.epkiSign(`${process.env.REACT_APP_SALT}`, setOutput);
    }
    useEffect(()=>{
        if(output.length>0){
            checkNeis({output})
            .then((res)=>{
                console.log(res.data)
                if(!!res.data && res.data !=='실패'){
                    setuserAccount(res.data)
                    setIsAuth(true)
                } else {
                    alertMessage("없는 사용자입니다. 관리자에 문의하세요.")
                }
            })
        }
    }, [output])
    const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userPasswordMsg, setUserPasswordMsg] = useState("");
  const [newPasswordMsg, setNewPasswordMsg] = useState("");
  const [passwordConfirmMsg, setPasswordConfirmMsg] = useState("");
  const onPasswordHandler = (event: { currentTarget: { name : React.SetStateAction<string>, value: React.SetStateAction<string>; }; }) => {
    setUserPasswordMsg(""); setNewPasswordMsg(""); setPasswordConfirmMsg("")
    if(event.currentTarget.name === 'newPassword') setNewPassword(event.currentTarget.value)
    if(event.currentTarget.name === 'passwordConfirm') setPasswordConfirm(event.currentTarget.value)
    }
const onSubmitHandler = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if(newPassword ===""){
      setNewPasswordMsg("비밀번호를 입력하세요.")
      return;}
    if(passwordConfirm ===""){
      setPasswordConfirmMsg("비밀번호를 입력하세요.")
      return;}
    if(!passwordValidCheck(newPassword)) return setNewPasswordMsg("비밀번호가 올바르지 않습니다.")
    if(newPassword !== passwordConfirm) return setPasswordConfirmMsg("비밀번호가 일치하지 않습니다.")
    
    
    let body = {
        newPassword: passwordIncoding(newPassword),
        userAccount
    }
    changePw2(body).then(((response)=>{
        if(response.data){
           navigate(ROUTES.ADMIN_LOGIN_F)
        }
    })).catch((error) => {
        console.log(error)
        if(error.response.data) alertMessage(error.response.data)
    });
    }
    return(
        <>
           {isAuth?
            <div>
                <div className='findInfoRow'>
                    <div>새로운 비밀번호</div>
                    <div>
                        <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='newPassword' value={newPassword}
                        placeholder='새로운 비밀번호 입력' onChange={onPasswordHandler} required/>
                    </div>
                </div>
                <div className='findInfoMsg'>{newPasswordMsg}</div>
                <div className='findInfoRow'>
                    <div>새로운 비밀번호 재입력</div>
                    <div>
                        <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='passwordConfirm' value={passwordConfirm}
                        placeholder='새로운 비밀번호 재입력' onChange={onPasswordHandler} required/>
                    </div>
                </div>
                <div className='findInfoMsg2'>최소8~최대16글자 (영문+숫자+기호) 기호 : !@#$%^&*()_+</div>
                <div className='findInfoMsg'>{passwordConfirmMsg}</div>
                
                <div className='flexFull adminLoginBtn adminFindBtn'>
                    <Button size='large' type="primary" onClick={onSubmitHandler}>변경</Button>
                </div>
            </div>
           :<>
            <div className='adminCenterHeader'>비밀번호 재설정</div>
                <div className='adminSignUpTypeBtnWrapper'>
                    <Button onClick={()=>{setpage(0); settab(2);}}>취소</Button>
                    <Button onClick={()=>fincAccountFunc()}>인증하기</Button>
                </div>
            </>}
        </>
    )
}