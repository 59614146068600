

//USER
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_INITPWCHANGED = "USER_INITPWCHANGED";

//ADMIN
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_SCHOOL = "ADMIN_SCHOOL";