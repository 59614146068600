import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router";
import { authenticateCheck } from '../../_actions/AdminAction';
import * as ROUTES from '../../constants/router'
import { useSelector } from "react-redux";
import { RootState } from "../../_reducers";
import { userTypeStringToInt } from "../../constants/auth";

const ProtectedRoutes:any = ({children, isAdmin=true, useTypeArr=[3,4,5], isSelectedSchool=false, returnPath=null} : any) => {
    const navigate = useNavigate();
    const location = useLocation();
    let pathname =  location.pathname;
    const {adminData, schoolInfo} = useSelector((state : RootState) => state.adminReducer);
    let RETURN_PATH = returnPath !== null ? returnPath : ROUTES.ADMIN_LOGIN_F
    useEffect(()=>{
        if(!isAdmin && adminData.adminUuid.length>0 && schoolInfo.schoolUuid.length<1){return navigate(ROUTES.ADMIN_SCHOOL_F, {replace:true})}
        if(!isAdmin && adminData.adminUuid.length>0 && schoolInfo.schoolUuid.length>1){return navigate(ROUTES.ADMIN_MAIN_F, {replace:true})}
        if(isAdmin && (!adminData || adminData.adminUuid.length <10)){return navigate(RETURN_PATH, {replace:true})}
        if(useTypeArr.indexOf(adminData.userType)<0){return navigate(ROUTES.ADMIN_MAIN_F, {replace:true})}
        if(isAdmin && (adminData.userType === userTypeStringToInt("슈퍼") || adminData.userType === userTypeStringToInt("교사"))
        && isSelectedSchool && schoolInfo.schoolUuid.length<1){return navigate(ROUTES.ADMIN_SCHOOL_F, {replace:true})}
    }, [location.pathname])
    return (
        children ? children : <Outlet/>
    )
};

export default ProtectedRoutes;