import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router";
// import { authenticateCheck } from '../../_actions/UserAction';
import * as ROUTES from '../../constants/router'
import { RootState } from "../../_reducers";
import { useSelector } from "react-redux";

const ProtectedRoutes:any = ({children, isUser=true, isInit=true, returnPath=null} : any) => {
    const {userData} = useSelector((state : RootState) => state.userReducer);
    const navigate = useNavigate();
    const location = useLocation();
    let pathname =  location.pathname;
    let RETURN_PATH = returnPath !== null ? returnPath : ROUTES.USER_LOGIN_F
    useEffect(()=>{
        if(pathname === ROUTES.USER_LOGIN_F && userData.userUuid.length>0){return navigate(ROUTES.USER_MAIN_F, {replace:true})}
        if(isUser && (!userData || userData.userUuid.length<10)){return navigate(RETURN_PATH, {replace:true})}
        if(userData.userUuid.length>10 && userData.passwordChange && isInit ){return navigate(ROUTES.USER_INITPW_F, {replace:true})} // userNav에 추가
        if(isUser && userData.userUuid.length<10){return navigate(ROUTES.USER_MAIN_F, {replace:true})}
    }, [location.pathname])
    return (
        children ? children : <Outlet/>
    )
};

export default ProtectedRoutes;