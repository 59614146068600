import React, { useCallback, useEffect, useState } from 'react'
import { passwordValidCheck } from '../../../../constants/common';
import * as ROUTES from '../../../../constants/router.js'
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { changeInitPw, logoutUser } from '../../../../_actions/UserAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './css/UserLogin.css';
import loginBtn2 from './css/images/loinBtn2.png';


// 뒤로가기 막기, 뒤로 오기 막기?
function InitPasswordPage() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const {state} = useLocation();
    const {userData} = useSelector((state : RootState) => state.userReducer);
    const [userPassword, setUserPassword] = useState("")
    const [userPasswordConfirm, setUserPasswordConfirm] = useState("")
    const [sendingRoute, setSendingRoute] = useState(ROUTES.USER_MAIN_F)
    const [msgId, setMsgId] = useState("")
    const [msgPw, setMsgPw] = useState("")
    const changeInitPwCallback = useCallback(async (body : any) => dispatch(await changeInitPw(body)),
    [dispatch]
    );
    const logoutUserCallback = useCallback(async () => dispatch(await logoutUser()), [dispatch]); 
    const handleLogout = () => {
        logoutUserCallback()
        .then((res)=>{
          if(!res.payload.loginStatus) navigate(ROUTES.USER_MAIN_F);
        })
    }
    useEffect(()=>{
        if(state !==null && state.type === 'initPw'){setSendingRoute(state.data);}
    }, [state])
    const onPasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        // console.log(passwordValidCheck(event.currentTarget.value))
        setUserPassword(event.currentTarget.value)
    }
    const onPasswordConfirmHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        setUserPasswordConfirm(event.currentTarget.value)
    }
    const onSubmitHandler = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if(userPassword !== userPasswordConfirm) return setMsgId("비밀번호가 일치하지 않습니다.")
        if(!passwordValidCheck(userPassword)) return setMsgId("올바른 비밀번호를 입력해 주세요.")
        
        let body = {account: userData.userAccount, password: passwordIncoding(userPassword), userUuid : userData.userUuid}
        changeInitPwCallback(body).then(((response)=>{
          console.log(response)
          if(response.payload.resultCode){
            // console.log(userData)
            navigate(sendingRoute)
          } else {
            console.log('err')
          }
        })).catch((error) => {
            console.log(error)
            if(error.response.data) alertMessage(error.response.data.resultMsg)
        });
      }
  return (
    <div className='userPasswordChangeWrapper'>
        <div className='loginSuccess'>로그인 성공!</div>
        <div className='pwChange1'>비밀번호를 변경해 주세요.</div>
        <div className='pwChange2'><span style={{color:'#1FC7DE'}}>최소8 ~ 최대16글자</span> (영문+숫자+기호) / 기호 : !@#$%^&*_</div>
        <div className='passwordBox userLoginInput'><Input.Password size='large' name='userPassword' value={userPassword}
        placeholder='새로운 비밀번호 입력' onChange={onPasswordHandler} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/></div>
        <div className='msgBox'>{msgId}</div>
        <div className='passwordBox userLoginInput'><Input.Password size='large' name='userPasswordConfirm' value={userPasswordConfirm}
        placeholder='비밀번호 재확인' onChange={onPasswordConfirmHandler} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/></div>
        <div className='msgBox'></div>
        <div className='loginBtnWrapper' onClick={onSubmitHandler}>
          <div >변경 후 로그인</div>
        </div>
    </div>
  )
}

export default InitPasswordPage