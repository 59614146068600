import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as ROUTES from '../../../../constants/router'
import { loginUser } from '../../../../_actions/UserAction'
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Button } from 'antd';
import { RootState } from '../../../../_reducers';
import './css/UserLogin.css';
import loginBtn from './css/images/loninBtn.png';

function LoginPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {state} = useLocation();
  const {userData, loginStatus} = useSelector((state : RootState) => state.userReducer);
  const loginUserCallback = useCallback(async (body : {account : string, password: string}) => dispatch(await loginUser(body)),
    [dispatch]
    );
  useEffect(()=>{
      if(loginStatus && !userData.passwordChange){
        navigate(ROUTES.USER_MAIN_F)
      } else if(loginStatus && userData.passwordChange){
        navigate(ROUTES.USER_INITPW_F)
      } 
  }, [])

  const [userAccount, setUserAccount] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [msg, setMsg] = useState("")
  const [msgId, setMsgId] = useState("")
  const [msgPw, setMsgPw] = useState("")
  const [psMsgShowing, setPsMsgShowing] = useState(false)
  const [sendingRoute, setSendingRoute] = useState(ROUTES.USER_COMMON)
  useEffect(()=>{
    if(state !==null && state.type === 'login'){
      setSendingRoute(state.data);
    }
}, [state])
  const onUserAccountHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setUserAccount(event.currentTarget.value)
  }
  const onPasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setUserPassword(event.currentTarget.value)
  }
  const onSubmitHandler = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if(userAccount ==="" ) return setMsgId("아이디를 입력해주세요.")
    if(userPassword ==="" ) return setMsgPw("비밀번호를 입력해주세요.")
    let body = {account: userAccount,password: passwordIncoding(userPassword)}
    loginUserCallback(body).then(((response)=>{
      if(response.payload.userData.userType < 3){ // 학생과 학부모만 로그인
        if(response.payload.loginStatus && !response.payload.userData.passwordChange){
          navigate(sendingRoute)
        } else if(response.payload.loginStatus && response.payload.userData.passwordChange){
          navigate(ROUTES.USER_INITPW_F, { state: {page:"", type:'initPw', data: sendingRoute} })
        } else {
          setMsg('아이디와 비밀번호를 확인해 주세요.')
        }
      // } else {
      //   setMsg('사용자 아이디와 비밀번호를 확인해 주세요.')
      }
      
    })).catch((error) => {
        console.log(error)
        if(error.response.data) setMsg(error.response.data.resultMsg)
    });
  }
  useEffect(()=>{
    setMsg(""); setMsgId(""); setMsgPw("");
  }, [userAccount, userPassword])
  return (
    <div className='userLoginWrapper'>
        <div className='msgBox'>{msg}</div>
        <div className='idBox userLoginInput'><Input size='large' type='text' name='userAccount' value={userAccount}
        placeholder='아이디' onChange={onUserAccountHandler}/></div>
        <div className='msgBox'>{msgId}</div> 
        <div className='passwordBox userLoginInput'><Input.Password size='large' name='userPassword' value={userPassword} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        placeholder='비밀번호' onChange={onPasswordHandler}/></div>
        <div className='msgBox'>{msgPw}</div>
        <div className='loginBtnWrapper' onClick={onSubmitHandler}>
          <div >로그인</div>
        </div>
        <div className='infoBox'>
          <span className='passwordsFindText pointer' onMouseOver={()=>setPsMsgShowing(true)} onMouseOut={()=>setPsMsgShowing(false)}><u>비밀번호 찾는 방법</u></span>
          {psMsgShowing && 
          <div className='passwordsFind '>비밀번호를 분실한 학생은<br/>담당 선생님께 문의해 주세요 {':)'}</div>
          }
        </div>
    </div>
    
  )
}

export default LoginPage 