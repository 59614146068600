import React, { useEffect, useState } from 'react'
import { Button, Table, Input,Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Register from './Register';
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import { deleteAdminList, getUserListBySchool, setInitUserPw } from '../../../../_actions/AdminAction';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { deleteNotice, getNoticeList } from '../../../../_actions/NoticeAction';
import dayjs from 'dayjs';
import { numberFormatter } from '../../../../constants/common';

import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;

export interface DataType {
    noticeUuid:string;
    noticeIndex:number;
    adminName: string;
    noticeHeader : string;
    noticeStatus1 : number;
    noticeStatus2 : number;
    noticeStatus3 : number;
    noticeStatus4 : number;
    schoolName : string;
    noticeView:number;
    createdDate:string;
  }
  function NoticeList() {
    const navigate = useNavigate();
    const columns: ColumnsType<DataType> = [
        {
            title: '번호',
            dataIndex: 'noticeIndex',
            sorter: {
                compare: (a, b) => a.noticeIndex-b.noticeIndex,
              },
            render: (text, row, index) => {
                return(<div onClick={()=>startEditmode(row.noticeUuid)}>{(currentPage-1)*10+(index+1)}</div>)
                }
        },
        {
            title: '제목',
            dataIndex: 'noticeHeader',
            sorter: {
                compare: (a, b) => a.noticeHeader.localeCompare(b.noticeHeader),
            },
            render: (text, row, index) => {
                return(<div onClick={()=>startEditmode(row.noticeUuid)}>{text}</div>)
            }
        },
        {
            title: '공개유형',
            dataIndex: 'noticeStatus',
            render: (text, row, index) => {
                return(<div onClick={()=>startEditmode(row.noticeUuid)}>{noticeStatusToString(row.noticeStatus1, row.noticeStatus2, row.noticeStatus3, row.noticeStatus4)}</div>)
            }
        },
        {
            title: '조회 수',
            dataIndex: 'noticeView',
            sorter: {
                compare: (a, b) => a.noticeView - (b.noticeView),
            },
            render: (text, row, index) => {
                return(<div onClick={()=>startEditmode(row.noticeUuid)}>{numberFormatter(text)}</div>)
            }
        },
        {
            title: '생성 날짜',
            dataIndex: 'createdDate',
            sorter: {
                compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
            },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD');
                return(<div onClick={()=>startEditmode(row.noticeUuid)}>{dayjsFormat}</div>)
            }
        },
        {
            title: '',
            dataIndex: 'noticeUuid',
            render: (text, row, index) => {
                return(<Button onClick={()=>deleteNoticeFunc(text)}>삭제</Button>)
            }
        },
    ];
    const noticeStatusToString = (status1:number,status2:number,status3:number,status4:number,) => {
        let temp = [];
        if(status1 === 1 && status2 === 1 && status3 === 1 && status4 === 1){
          temp.push("전체")
        } else {
          if(!!status1) temp.push("학생");
          if(!!status2) temp.push("학부모");
          if(!!status3) temp.push("직원");
          if(!!status4) temp.push("교사");
        }
        return temp.join(", ")  
      }
    const startEditmode = (uuid:string) => {
        navigate(ROUTES.ADMIN_NOTICELIST_F+'/'+uuid)
        setSelectedNoticeUuid(uuid)
        setPage("수정하기")
    }
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [searchName, setSearchName] = useState("");
    const [searchCategory, setSearchCategory] = useState("noticeStatus");
    const [page, setPage] = useState("");
    const [noticeList, setNoticeList] = useState<DataType[]>([]);
    const [noticeListSaved, setNoticeListSaved] = useState([]);
    const [selectedNoticeUuid, setSelectedNoticeUuid] = useState("");
    const {adminData} = useSelector((state : RootState) => state.adminReducer);
  
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    useEffect(()=>{
        getNoticeListFunc()
    },[])
    const getNoticeListFunc = () => {
        getNoticeList({userType : 5, limit : 100}) // 관리자 페이지에서는 전체 열람 가능
        .then((res)=>{
            if(res.data){
                console.log(res.data)
                let temp = res.data;
                setNoticeList(temp)
                setNoticeListSaved(temp)
            }  
        })
        .catch((error: { response: { data: any; }; "": any; })=>{
            if(error.response.data) alertMessage(error.response.data)
        })
    }
const deleteNoticeFunc = (uuid:string) => {
    // console.log(selectedlist)
    confirmMessage("글 삭제", <div>정말로 삭제하시겠습니까?<br/>삭제하시면 데이터복구 불가능합니다.</div>, 'primary', ()=>{
        deleteNotice({adminUuid : adminData.adminUuid, noticeUuid : uuid})
        .then((res:any)=>{
            if(res.data){ getNoticeListFunc();}
        })
    })
}
const searchHandler = () => {
    setSearchCategory(searchCategory)
    let temp = noticeListSaved.slice()
        if(searchCategory === "noticeStatus"){
            temp = temp.filter((c:DataType) => 
            c.noticeStatus1===1 && c.noticeStatus2===1 && c.noticeStatus3===1 && c.noticeStatus4===1 && (searchName.length>0 ? c.noticeHeader.includes(searchName) : c.noticeHeader))
        } else if(searchCategory === "noticeStatus1"){
            temp = temp.filter((c:DataType) => 
            c.noticeStatus1===1 && (searchName.length>0 ? c.noticeHeader.includes(searchName) : c.noticeHeader))
        } else if(searchCategory === "noticeStatus2"){
            temp = temp.filter((c:DataType) => 
            c.noticeStatus2===1 && (searchName.length>0 ? c.noticeHeader.includes(searchName) : c.noticeHeader))
        } else if(searchCategory === "noticeStatus3"){
            temp = temp.filter((c:DataType) => 
            c.noticeStatus3===1 && (searchName.length>0 ? c.noticeHeader.includes(searchName) : c.noticeHeader))
        } else if(searchCategory === "noticeStatus4"){
            temp = temp.filter((c:DataType) => 
            c.noticeStatus4===1 && (searchName.length>0 ? c.noticeHeader.includes(searchName) : c.noticeHeader))
        }
    setNoticeList(temp)
}
const [currentPage, setcurrentPage] = useState(1);
const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setcurrentPage(pagination.current || 1);
    console.log('params', pagination, filters, sorter, extra);
};
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>공지사항 {page ==='' ? '' : ' - ' + page}</div>
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                    <Option value= "noticeStatus">전체</Option>
                    <Option value= "noticeStatus1">학생</Option>
                    <Option value= "noticeStatus2">학부모</Option>
                    <Option value= "noticeStatus3">직원</Option>
                    <Option value= "noticeStatus4">교사</Option>
                </Select>
                <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}/>
                <Button type='primary' onClick={()=>searchHandler()}>검색</Button>
                </div>
                <div className='adminBtnSection'>
                    <Button type='primary' onClick={()=>navigate(ROUTES.ADMIN_NOTICECREATE_F)}>등록</Button>
                </div>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={noticeList} tableLayout={"auto"}
            rowKey={(render)=>render.noticeUuid}
            onChange={onCurrentPageChange}
            // onRow={(record, rowIndex) => {
            //     return {
            //       onClick: (event) => {startEditmode(record.noticeUuid)}, // click row
            //     };
            //   }}
            />
        </div>
    </div>
  )
}

export default NoticeList
