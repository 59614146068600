import {ADMIN_LOGIN, ADMIN_LOGOUT, ADMIN_SCHOOL} from '../_actions/types';

export type AdminData = {
    loginStatus : boolean,
    adminData : {
        result : number,
        adminUuid : string,
        adminAccount : string,
        adminName : string,
        userType: number,
        adminSchooluuid: string,
        adminSchoolname: string,
    }
    schoolInfo:{
        schoolUuid: string,
        schoolName: string,
    }
}
export const initialState : AdminData = {
        loginStatus : false,
        adminData : {
            adminUuid: "",
            result : 0,
            adminAccount: '',
            adminName:'관리자',
            userType:5,
            adminSchooluuid:"",
            adminSchoolname:""
        },
        schoolInfo:{
            schoolUuid: "",
            schoolName: "",
        }
};

export default function adminReducer (state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case ADMIN_LOGIN:
            return { ...state, ...action.payload } // spread operator
        case ADMIN_LOGOUT:
            return { ...initialState }
        case ADMIN_SCHOOL:
        return { ...state, schoolInfo : {...action.payload} }
        default:{
            return state;
        }
    }
}