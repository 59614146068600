import React, { useEffect, useState } from 'react'
import { alertMessage } from '../../../../Utils/Utils';
import { getVersionList, setVersion } from '../../../../_actions/AdminAction';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import dayjs from 'dayjs';
import { Button, Input } from 'antd';

function Version() {
    let navigate = useNavigate();
    const {adminData:{adminUuid}} = useSelector((state : RootState) => state.adminReducer);
    const [versionData, setVersionData] = useState("");
    const [versionList, setVersionList] = useState<any[]>([]);
    const getVersionFunc=()=>{
        getVersionList().then((res)=>{
            console.log(res.data)
            if(res.data) setVersionList(res.data)
        }).catch((error:any) => {
            if(error.response.data){
            alertMessage(error.response.data)
            }
        });
    }
    useEffect(()=>{
        getVersionFunc()
    },[])
    const onSubmitHandler = () => {
        let body = {
                adminUuid,
                version: versionData
            }
        setVersion(body).then(((response:any)=>{
            if(response.data) {
                setVersionData("")
                getVersionFunc()
            }
        })).catch((error:any) => {
            if(error.response.data){
            alertMessage(error.response.data)
            }
        });
    }
    const onChangeVersionData = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setVersionData(val)
    }
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>가상 캠퍼스 버전</div>
        <div className='adminRegisterRowWrapper'>
            <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>현 버전</div>
                <div className='adminRegisterInput'>{versionList.length>0 ? versionList[0]['version'] : '설정된 버전 없음'}</div>
            </div>
            <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>신 버전</div>
                <div className='adminRegisterInputF'>
                    <Input type="text" name='versionData' value={versionData} onChange={onChangeVersionData} required/>
                    <Button type="primary" onClick={onSubmitHandler}>저장</Button>
                </div>
            </div>
            <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>로그 기록</div>
                <div className='adminRegisterInput'></div>
            </div>
        </div>
        <div className='adminExcelInfo adminVersionList'>
        {versionList.length>0 && versionList.map((c:any)=>{
            let dayjsFormat = dayjs(c.created_date).format('YYYY-MM-DD HH:mm');
            return(<div key={c.version_index}><span>{c.version}</span>&nbsp;&nbsp;(<span>{dayjsFormat}&nbsp;&nbsp;</span>)</div>)
            }
        )}
        </div>
        
    </div>
  )
}

export default Version