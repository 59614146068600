
import SunEditor from 'suneditor-react';

export const isEmail = email => {
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
};

export const getByteString = (s,b,i,c)=>{
    //한글을 3바이트로
    // for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
    //한글을 2바이트로
    for(b=i=0;c=s.charCodeAt(i++);b+=c>>7?2:1);
    return b;
};
export const sliceByByte = (s,maxByte ,b,i,c) => {
	for(b=i=0;c=s.charCodeAt(i++);) {
      b+=c>>7?2:1;
      if (b > maxByte)
      break;
    }
  	return s.substring(0,i);
 }
 export const passwordValidCheck = (pw) => {

	var num = pw.search(/[0-9]/g);
  var eng = pw.search(/[a-z]/ig);
  var spe = pw.search(/[!@#$%^&*_]/gi);//!@#$%^&*()_+

  // console.log((pw.length < 8 || pw.length > 16),',',!(pw.search(/\s/)),',',(num<0 || eng<0 || spe<0)) 
  if(pw.length < 8 || pw.length > 16) return false;
  if(!pw.search(/\s/)) return false;
  if(num<0 || eng<0 || spe<0) return false;
  return true;
 }
 export const isNumeric = (data) => {
  if(data.length>11) return false;
  return !isNaN(Number(data));
}

 export const phoneformatter = (num, type) => {
  var formatNum = '';
   if(num.length === 11){
      if(type === 0){    
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');    
      }else{    
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');    
      }
  }else if(num.length === 9){    
      formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');    
  }else{    
      if(num.indexOf('02') === 0){    
          if(type === 0){
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');    
          }else{    
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
          }
      }else{
          if(type === 0){
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
          }else{
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          }
      }
  }
  return formatNum;
}
export const textareaStringToDB = (text) => {
    return text.replace(/(?:\r\n|\r|\n)/g,'<br />');
}
export const textareaDBToString = (text) => {
    return text.replace(/<br\s*\/?>/img,'\n');
}
export const textareaDBToSpace = (text) => {
    return text.replace(/<br\s*\/?>/img,' ');
}
export const numberToString=(num)=>{
    let data = num;
    if(typeof num !=="number") data = Number(num)
    return `${data<10?'0'+data:data}`
    // return num.toString();
    
}

export const getAdmissionYearList = () => {
    let gap = 10;
    let currentYear = new Date().getFullYear();
    let startYear = currentYear - gap;
    let temp = [];
    for(var i=gap; i>0; i--){
        temp.push(`${startYear+i}`)
    }
    return temp
}

export const getUserAccount=(schoolUuid, admissionyear, grade, gradeClass, gradeNumber)=>{
    if(!(schoolUuid||admissionyear||grade||gradeClass||gradeClass)) return false;
    let year = admissionyear.length ===4 ? admissionyear.substring(2,4) : admissionyear.length === 2 ? admissionyear : null;
    if(!admissionyear) return false;
    return `${schoolUuid}${year}${grade}${numberToString(gradeClass)}${numberToString(gradeNumber)}`
}
export const trimAndNoSpecial=(name)=>{
let username = name;
    username = username.replace(/ /g,"") // 공백 제거
    username = username.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, "") // 영어,숫자,한글만 입력 가능
    return username
}
export const onlyNumberAndEnglish=(name)=>{
    let username = name;
        username = username.replace(/ /g,"") // 공백 제거
        username = username.replace(/[^a-z|A-Z|0-9]/g, "") // 영어,숫자,한글만 입력 가능
        return username
    }
export const removeDuplication =(arr) => {
    let temp = arr.reduce((acc, current) => {
        const x = acc.find((item) => item.userAccount === current.userAccount);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    return temp;
}
export const objectArrToStringArr =(arr, key) => {
    let temp = arr.reduce((acc, current) => {
        return acc.concat([current[key]])
      }, []);
    return temp;
}

export const numberFormatter = n => {
    if (n < 1e3) return n
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
  }

  let Arr = ['시스템', '수업', '계정'] // 수정하지 말고 추가
export const inquiryCategoryToInt=(category)=>{
    return Arr.indexOf(category)
}
export const inquiryCategoryFromInt=(int)=>{
    return Arr[int]
}


//에디터 주석 데이터 삭제하는 메서드
export const deleteCommentOfEditor=(contents)=>{

    // <!-- 위치 검색
    const startIndex = contents.indexOf('<!--');

    // --> 위치 검색
    const endIndex = contents.indexOf('-->')+3;
    
    //삭제할 문구 선택
    const deleteTarget = contents.slice(startIndex,endIndex);

    const newString = contents.replace(deleteTarget,'');
    
    return newString;
}
export const noEditableSunEditor = (PRESENTATION_CONTENTS, fontSize) => {
    return(
        <SunEditor lang="ko" height='auto'
            setOptions={{ 
                resizingBar: false,
                defaultStyle : `font-size : ${fontSize ? fontSize : '1em'}`
            }}
            hideToolbar={true}
            disableToolbar={true}
            disable={true}
            setContents={PRESENTATION_CONTENTS}
            setAllPlugins={false}
            />
    )
}