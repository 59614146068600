import axios from 'axios';
import { initialState } from '../_reducers/AdminReducer'
import { ADMIN_LOGIN, ADMIN_LOGOUT, ADMIN_SCHOOL } from './types';

export async function loginAdmin(dataToSubmit: { account: string; password: string; }) {
    const req =  await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/adminLogin`, dataToSubmit);
    return {
        type : ADMIN_LOGIN,
        payload : {
            adminData : {
                result : req.data.resultCode,
                adminAccount : dataToSubmit.account,
                adminName : req.data.resultData.adminName,
                adminUuid : req.data.resultData.adminUuid,
                userType: req.data.resultData.userType,
                adminSchooluuid: req.data.resultData.adminSchooluuid,
                adminSchoolname: req.data.resultData.adminSchoolname,
            },
            schoolInfo:{
                schoolUuid: "",
                schoolName: "",
            },
            loginStatus : !!req.data.resultCode
        }
    }      
}

export async function logoutAdmin() {
    return {
        type : ADMIN_LOGOUT,
        payload : initialState
    }
}

export async function authenticateCheck(dataToSubmit: { adminUuid: string;}) {
    return {data : true}
}
// export async function changeInitPw(dataToSubmit: { account: string; password: string; }) {
//     return {data : true}
// }
export async function changePw(dataToSubmit: { account: string; password: string; }) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/changePw`, dataToSubmit);
}

export async function checkPw(dataToSubmit: { password: string; }) {
    let testResult = dataToSubmit.password === '123' ? true: false
    return {data : testResult}
}
export async function changePw2(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/changePw2`, dataToSubmit);
}
export async function setAdminSchool(dataToSubmit : {schoolName:string, schoolUuid: string}) {
    return {
        type : ADMIN_SCHOOL,
        payload : {schoolName:dataToSubmit.schoolName, schoolUuid:dataToSubmit.schoolUuid}
    }
}

export async function getSchoolList(dataToSubmit: any) {
    // console.log(dataToSubmit)
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getSchoolList`, dataToSubmit);
}
export async function setSchoolList(dataToSubmit: any) {
    // console.log(dataToSubmit)
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/setSchoolList`, dataToSubmit);
}

export async function getUserListBySchool(dataToSubmit: any) {
    // console.log(dataToSubmit)
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getUserListBySchool`, dataToSubmit);
}

export async function createUserList(dataToSubmit: any) {
    // console.log(dataToSubmit)
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/createUserList`, dataToSubmit);
}

export async function setInitUserPw(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/setInitUserPw`, dataToSubmit);
}
export async function deleteStudentList(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteStudentList`, dataToSubmit);
}
export async function deleteAdminList(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteAdminList`, dataToSubmit);
}
export async function getUserGradeHistory(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getUserGradeHistory`, dataToSubmit);
}
export async function getUserInfo(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getUserInfo`, dataToSubmit);
}
export async function checkUserGradeInfo(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/checkUserGradeInfo`, dataToSubmit);
}
export async function editGradeHistory(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/editGradeHistory`, dataToSubmit);
}
export async function getAdminInfo(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getAdminInfo`, dataToSubmit);
}
export async function searchTeacherList(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/searchTeacherList`, dataToSubmit);
}

export async function setVersion(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/setVersion`, dataToSubmit);
}
export async function getVersionList() {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getVersionList`);
}

export async function setPolicy(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/setPolicy`, dataToSubmit);
}
export async function getPolicy(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getPolicy`, dataToSubmit);
}
export async function getPolicyList() {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getPolicyList`);
}
export async function checkDupAccount(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/checkDupAccount`, dataToSubmit);
}
export async function createAdmin(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/createAdmin`, dataToSubmit);
}
export async function getSessionId() {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getSessionId`);
}
export async function checkAuthInfo(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/checkAuthInfo`, dataToSubmit);
}
export async function checkNeis(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/checkNeis`, dataToSubmit);
}
export async function downladStudentListByExcel(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/downladStudentListByExcel`, dataToSubmit
    ,{
        "responseType" : "blob"
    }
    );
}

