import React, { useState } from 'react'
import MypageHeader from './MypageHeader'
import { Button, Collapse, Input } from 'antd';
import { SearchOutlined, UpOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Search } = Input;

function Faq(props:any) {
  let {isStudent=true} = props;
  const faqData = [
    ["(학생/학부모) 회원가입은 어떻게 하나요?", "담임 선생님 혹은 담당자 선생님께 말씀드리면 아이디와 비밀번호를 발급받아주실 거예요."],
    ["(교사/직원) 회원가입은 어떻게 하나요?", "관리자 페이지에서 회원가입 버튼을 클릭한 후 절차에 따라 가입을 진행해 주세요."],
    ["배경음은 어떻게 끄나요?", "왼쪽 상단 [메뉴] 버튼을 클릭한 후 [설정] 창에서 배경음 버튼을 클릭해 OFF로 바꿔주세요."],
    ["권한이 없다고 떠요.","혹시 학생이신가요?<br/>선생님의 승인이 필요하니 선생님께 승인을 요청해 보세요."],
    ["전시실 벽에 있는 스크린을 크게 보고 싶어요.","스크린 상단의 돋보기 버튼을 클릭하면 전체 화면으로 볼 수 있어요."]
  ]
  const onChange = (key: string | string[]) => {
    // console.log(key);
  };
  const [showingFaq, setShowingFaq] = useState(faqData);
  const [searchName, setSearchName] = useState("");
  const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setSearchName(val)
}
  const searchHandler = () => {
    let temp = faqData.slice()
    if(searchName !== "") temp = temp.filter((c:any) => c[0].includes(searchName) || c[1].includes(searchName))
    setShowingFaq(temp)
}
  return (
    <>
    {isStudent && <MypageHeader page="faq"/>}
    <div className='mypageWrapper' style={!isStudent ?{marginLeft:0, marginRight:0}:{}}>
      <div className='faqWrapper fapWrapper2'>
        {isStudent && 
          <div className='inquiryTopBtnContainter'>
             <div className='inquiryTopBtnSection'>
              <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}
              prefix={<SearchOutlined/>} allowClear={true}
              /></div>
              <Button onClick={()=>searchHandler()}>검색</Button>
          </div>}
          <Collapse 
          onChange={onChange} bordered={false}
          expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
          style={isStudent ? {fontSize:'16px', backgroundColor:'white'} : {backgroundColor:'white'}}
          >
            {showingFaq.length>0 && showingFaq.map((c,id)=>{
              return(
              <Panel header={c[0]} key={id} >
                {c[1].length>0 && c[1].split("<br/>").map((c:any,id:any)=>{
                  return <p key={id}>{c}</p>
                })}
              </Panel>
              )
            })}
            
          </Collapse>
      </div>
    </div>
    </>
  )
}

export default Faq