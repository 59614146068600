import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../constants/router.js'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../_reducers/index';
import { logoutUser, downloadCheck } from '../../../_actions/UserAction';
import { Button, Modal } from 'antd';
import './css/UserNav.css';
import logoimg from './css/images/logo.png';
import modalCloseIcon from '../pages/main/css/images/close.png';

const UserNav = (props: any) => {
  let { isNavShowing = true } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData: { userName }, loginStatus } = useSelector((state: RootState) => state.userReducer);
  const logoutUserCallback = useCallback(async () => dispatch(await logoutUser()), [dispatch]);
  const handleLogout = () => {
      logoutUserCallback()
      .then((res)=>{
        if(!res.payload.loginStatus) navigate(ROUTES.USER_MAIN_F);
      })
  }
  const checkDownload = () => {
    downloadCheck()
      .then((res) => {
        console.log(res)
    })
}
const btnFunc = (route:string) => {
  if(loginStatus){
    navigate(route)
  } else {
    return(
      Modal.info({
      content : (<div className='mainCampusModalContent'>로그인이 필요합니다.</div>),
      closable:true,
      okText:"확인",
      className:"mainCampusModal",
      onOk() { 
        navigate(ROUTES.USER_LOGIN_F, { state: {page:"", type:'login', data: route} })
      },
      onCancel(){
        navigate(ROUTES.USER_MAIN_F)
      },
      closeIcon:(<img src={modalCloseIcon}/>),
      centered:true
    })
    )
  }
}
  return (
      <div className='userNavWrapper'>
        <div><img className='userNavLogo pointer' src={logoimg} alt="인천메타캠퍼스로고" onClick={()=>navigate(ROUTES.USER_COMMON)} /></div>
        <div className='userNavBtnWrapper'>
          {isNavShowing &&
            <>
              <div className='userNavBtn pointer' onClick={() => btnFunc(ROUTES.USER_DOWNLOAD_F)}>다운로드</div>
              <div className='userNavBtn pointer' onClick={() => btnFunc(ROUTES.USER_PROFILE_F)}>마이페이지</div>
              {loginStatus ?
                <div className='userNavBtn login pointer' onClick={() => handleLogout()}>{userName} | 로그아웃</div>
                : <div className='userNavBtn login pointer' onClick={() => navigate(ROUTES.USER_LOGIN_F)}>로그인</div>}
            </>}
        </div>
      </div>
  )
}
export default UserNav
