import React from 'react'
import * as ROUTES from '../../constants/router'
import { useNavigate } from 'react-router-dom';
import './css/Footer.css';
import logoimg from './css/images/logo.png';
import incheonLogo from './css/images/incheonLogo.png';


const Footer = () => {
  let navigate = useNavigate();
    return (
      <div className='footerwrapper'>
        <div>
          <div><img className='userFooterLogo' src={logoimg} alt="인천메타캠퍼스로고" /></div>
          <div className='footerBtnWrapper'>
            <div className='usertermBtn pointer' onClick={()=>navigate(ROUTES.USER_POLICY_F, { state: {page:"", type:'login', data: 0} })}>이용약관</div>
            <div className='btnLine'></div>
            <div className='privacypolicyBtn pointer'onClick={()=>navigate(ROUTES.USER_PRIVACY_F, { state: {page:"", type:'login', data: 1} })}>개인정보처리방침</div>
            <div className='btnLine'></div>
            <div className='privacypolicyBtn pointer'onClick={()=>navigate(ROUTES.ADMIN_MAIN_F)}>관리자페이지</div>
          </div>
          <div className='address'>인천광역시교육청   주소 : 21554 인천광역시 남동구 정각로9 (구월동)</div>
          <div className='help'>서비스 문의 : 032-320-0006(AI융합교육과) | 시스템 문의 : 1599-1432(뉴클 주식회사)</div>
          <div className='copyright'>Copyright ⓒ INCHEON Metropolitan City Office of Education. All Rights Reserved.</div>
        </div>
        <div>
          <img className='incheonLogo' src={incheonLogo} alt="인천광역시교육청로고"/>
        </div>
      </div>
    )
  }
export default Footer