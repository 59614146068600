import { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import { deleteCommentOfEditor } from '../../../../constants/common';
import { Button } from 'antd';
import { getPolicy, setPolicy } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { infoMessage } from '../../../../Utils/Utils';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

function Register(props:any) {
  const navigate = useNavigate();
  const params = useParams();
  const [index, setindex] = useState(0);
  useEffect(()=>{
    setindex(Number(params.detail))
}, [params])
//이용약관
const [policyContents, setpolicyContents] = useState('');
//개인정보 처리 방침
const [privacyContents, setprivacyContents] = useState('');
const {adminData:{adminUuid}} = useSelector((state : RootState) => state.adminReducer);
const SubmitHandler = () => {
  setPolicy({policyContents, privacyContents, adminUuid, policyIndex:index})
  .then((res)=>{
    if(res.data) infoMessage("등록되었습니다.", ()=>navigate(ROUTES.ADMIN_TERMS_F))
  })
}
useEffect(()=>{
  if(index>0){
    getPolicy({policyIndex:index})
    .then((res)=>{
      if(res.data){
        setpolicyContents(res.data.policyContents)
        setprivacyContents(res.data.privacyContents)
      }
    })
  }
}, [index])
  return (
    <div className='adminContentSection'>
    <div className='adminListHeader'>약관 관리</div>
      <div>
          <div className='adminExcelInfo'>
            에디터 특성상 입력하자 마자 저장을 하게 되면
            제대로 저장이 안되는 경우가 있습니다.<br/>
            복사 + 붙여넣기를 하는 경우 제대로 내용이 옮겨 갔는지 확인해주세요.</div>
          <li className='adminGradeInfoHeader adminPolicyTitle'>이용약관</li>
          <SunEditor
            width={'100%'}
            height={'35vh'}
            setContents={policyContents}
            onChange={(value) =>
                setpolicyContents(deleteCommentOfEditor(value))
            }
            setOptions={{
                buttonList: [
                    ['fontSize'],
                    ['bold', 'underline', 'horizontalRule', 'italic'],
                    [
                        'fontColor',
                        'hiliteColor',
                        'outdent',
                        'indent',
                        'align',
                        'table',
                        'lineHeight',
                        'horizontalRule',
                    ],
                ],
            }}
        />
        <li className='adminGradeInfoHeader adminPolicyTitle'>개인정보 처리방침</li>
        <SunEditor
            width={'100%'}
            height={'35vh'}
            setContents={privacyContents}
            onChange={(value) =>
                setprivacyContents(deleteCommentOfEditor(value))
            }
            setOptions={{
                buttonList: [
                    ['fontSize'],
                    ['bold', 'underline', 'horizontalRule', 'italic'],
                    [
                        'fontColor',
                        'hiliteColor',
                        'outdent',
                        'indent',
                        'align',
                        'table',
                        'lineHeight',
                        'horizontalRule',
                    ],
                ],
            }}
        />
        <div className='adminExcelRegisterBtn'>
          <Button type='primary' size='large' onClick={SubmitHandler} disabled={privacyContents.length<1||policyContents.length<1}>등록</Button>
        </div>
      </div>
    </div>
  )
}

export default Register