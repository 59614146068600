import axios from 'axios';

export async function getCheckList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getCheckList`, dataToSubmit);
}
export async function getCheck(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getCheck`, dataToSubmit);
}
export async function saveCheck(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveCheck`, dataToSubmit);
}
export async function saveCheckStatus(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveCheckStatus`, dataToSubmit);
}
export async function deleteCheck(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteCheck`, dataToSubmit);
}
