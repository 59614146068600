import { Button, Input } from 'antd';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { setAdminSchool, setSchoolList, getSchoolList } from '../../../../_actions/AdminAction';
import schoolData from './data.json'
const { Search } = Input;

interface DataType {
    schoolName : string;
    schoolUuid : string;
  }

function School(props:any) {
    let {isAuth, setInfoFunc} = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const setAdminSchoolCallback = useCallback(async (body : {schoolName : string, schoolUuid: string}) => dispatch(await setAdminSchool(body)), [dispatch]);
    const [searchedList, setSearchedList] = useState<DataType[]>([])
    const setSchoolInfoList = () => {
        // console.log("setSchoolInfoList")
        setSchoolList(schoolData)
        .then((res)=>console.log(res.data))
    }
    const onSearch = (value: string) => {
        getSchoolList({schoolName:value})
        .then((res)=>{
            if(res.data){
                // console.log(res.data)
                setSearchedList(res.data)
            }
        })
    }
    const setSchoolInfoAndMovePage=(schoolName : string, schoolUuid: string)=>{
        setAdminSchoolCallback({schoolName, schoolUuid})
        .then(()=>{
            navigate(ROUTES.ADMIN_MAIN_F)
        })
    }
    const confirmFunc = (schoolName:any, schoolUuid:any) => {
        if(isAuth){
            setSchoolInfoAndMovePage(schoolName, schoolUuid)
        } else {
            setInfoFunc({schoolName, schoolUuid})
        }
    }
  return (
    <>
        <div className={`adminSchoolWrapper ${isAuth && 'adminSchoolWrapper2'}`}>
            <div className='adminSchoolHeader'>{isAuth && '진입할 '}학교를 검색해 주세요</div>
            <Search
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            />
            <div className='adminSchoolSubHeader'>학교 이름</div>
            {searchedList.length>0 && searchedList.map((c,id)=>{
                return(
                    <div className='adminSchoolLine' key={c.schoolUuid}>
                        <div>{c.schoolName}</div>
                        <Button onClick={()=>confirmFunc(c.schoolName, c.schoolUuid)}>{isAuth ?'진입' : '등록'}</Button>
                    </div>
                )
            })}
        </div>
    </>
    
  )
}

export default School