import React, { useState } from 'react'
import { checkPw } from '../../../../_actions/UserAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { userTypeIntToString, userTypeStringToInt } from '../../../../constants/auth';
import PasswordChange from './PasswordChange';
import MypageHeader from './MypageHeader'
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import confirmBtn from './css/images/confimBtn.png';
import './css/mypage.css';

function Profile() {
  const {userData:{userUuid, userName, userAccount, userType, grade, gradeClass, gradeNumber, schoolName, kidsName}} = useSelector((state : RootState) => state.userReducer);
  const [isAuth, setIsAuth] = useState(false);
  const [isPwEditing, setIsPwEditing] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [msg, setMsg] = useState("")
  const onPasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setMsg("")
    setUserPassword(event.currentTarget.value)
}
const onSubmitHandler = (event: { preventDefault: () => void; }) => {
  event.preventDefault();
  let body = {password: passwordIncoding(userPassword), userUuid}
  checkPw(body).then(((response)=>{
    if(response.data){
      setIsAuth(true)
    } else {
      setMsg("비밀번호가 올바르지 않습니다.")
    }
  })).catch((error) => {
      console.log(error)
      if(error.response.data) setMsg(error.response.data)
  });
}
  return (
    <>
    <MypageHeader page="profile"/>
    <div className='mypageWrapper'>
      
      {isAuth ?
      <div>
        {!isPwEditing ?
        <>
        <div className='userTypeWrapper'>
          <span className='userType'>{userTypeIntToString(userType)}</span>
        </div>
        
          <div className='userInfoWrapper userInfoWrapper2'>
            
            <div className='userInfoContainer userInfoContainer3'>
              <div>아이디</div>
              <div>{userAccount}</div>
            </div>
            <div className='userInfoContainer userInfoContainer3'>
              <div>이름</div>
              <div>{userName}</div>
            </div>
            <div className='userInfoContainer userInfoContainer3'>
              <div>소속</div>
              <div className='userInfoContainer4'>
              {schoolName}&nbsp;&nbsp;{userType === userTypeStringToInt("학생") && <span>{grade}학년 {gradeClass}반 {gradeNumber}번</span>}
              </div>
            </div>
            {userType === userTypeStringToInt("학부모") &&
            <div className='userInfoContainer userInfoContainer3'>
              <div>자녀</div>
              <div>{kidsName}</div>
            </div>
            }
            <div className='userInfoContainer userInfoContainer3'>
              <div>비밀번호</div>
              <div><Button size='large' onClick={()=>setIsPwEditing(true)}>변경</Button></div>
            </div>
          </div>
          </>
        :
        <PasswordChange/>
      }
    </div>
      :
      <div className='profilePWWrapper'>
        <div className='profiletext1'>본인 확인을 해주세요.</div>
        <div className='profiletext2'>고객님의 개인정보 보호를 위해 비밀번호를 다시 한번 확인합니다.</div>
        <div className='profiletext3'>메타캠퍼스 비밀번호를 입력해 주세요.</div>
        <div className='passwordBox userLoginInput'><Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='userPassword' value={userPassword}
          placeholder='비밀번호' onChange={onPasswordHandler}/></div>
          <div className='msgBox'>{msg}</div>
          <div className='loginBtnWrapper' onClick={onSubmitHandler}>
            <div >확인</div>
          </div>
      </div>
      }
    </div>
    </>
  )
}

export default Profile