import React, { useEffect, useState } from 'react'
import { getPolicy } from '../../../../_actions/AdminAction';
import SunEditor from 'suneditor-react';

function Policy() {
    //이용약관
    const [policyContents, setpolicyContents] = useState('');
    useEffect(()=>{
        getPolicy({policyIndex:0})
        .then((res)=>{
            if(res.data){
                setpolicyContents(res.data.policyContents)
              }
        })
    }, [])
    return (
        <div>
            <div>
                {noEditableSunEditor(policyContents)}
            </div>
        </div>
    )
}

export default Policy

export const noEditableSunEditor = (PRESENTATION_CONTENTS:any) => {
    return(
        <SunEditor lang="ko" height='auto'
            setOptions={{ 
                resizingBar: false,
                defaultStyle : `font-size : 1.5rem`
            }}
            hideToolbar={true}
            disableToolbar={true}
            disable={true}
            setContents={PRESENTATION_CONTENTS}
            setAllPlugins={false}
            />
    )
}