import axios from 'axios';
import { initialState } from '../_reducers/UserReducer'
import { USER_LOGIN, USER_LOGOUT, USER_INITPWCHANGED } from './types';

export async function loginUser(dataToSubmit: { account: string; password: string; }) {
    const req =  await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/userLogin`, dataToSubmit);
    return {
        type : USER_LOGIN,
        payload : {
            userData : {
                result : req.data.resultCode,
                userAccount : dataToSubmit.account,
                userName : req.data.resultData.userName,
                userUuid : req.data.resultData.userUuid,
                passwordChange : !!req.data.resultData.passwordChange, 
                userType : req.data.resultData.userType,
                schoolName:req.data.resultData.schoolName,
                grade: req.data.resultData.grade,
                gradeClass: req.data.resultData.gradeClass,
                gradeNumber: req.data.resultData.gradeNumber,
            },
            loginStatus : !!req.data.resultCode
        }
    }
}

export async function logoutUser() {
    const req = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/userLogout`);
    return {
        type : USER_LOGOUT,
        payload : {...initialState, loginStatus : !req.data}
    }
}

export async function changeInitPw(dataToSubmit: any) {
    const req = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/changeInitPw`, dataToSubmit);
    return {
        type : USER_INITPWCHANGED,
        payload : req.data
    }
}
export async function changePw(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/changePw`, dataToSubmit);

}

export async function checkPw(dataToSubmit: any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/checkPw`, dataToSubmit);
}
export async function downloadCheck() {
    return await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/downloadCheck`);
}