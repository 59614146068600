import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Table, Tag } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import MypageHeader from './MypageHeader'
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { getInquiryList } from '../../../../_actions/InquiryAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import searchIcon from './css/images/search.png'
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
interface DataType {
  inquiryUuid : string;
  inquiryHeader: string;
  inquiryIndex: number;
  inquiryCategory : string;
  writerName: string;
  createdDate: string;
  inquiryStatus: number;
}
function InquiryList() {
  let navigate = useNavigate();
  const {userData} = useSelector((state : RootState) => state.userReducer);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [inquiryList, setInquiryList] = useState<DataType[]>([]);
  const [inquiryListSaved, setInquiryListSaved] = useState<DataType[]>([]);
  const onChangeHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setSearchKeyword(event.currentTarget.value)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'inquiryIndex',
      key: 'inquiryIndex',
      render: (text, record, index) => <div style={{textAlign:'center'}}>{inquiryList.length+1 - ((currentPage-1)*10+(index+1))}</div>,
    },
    {
      title: '제목',
      dataIndex: 'inquiryHeader',
      key: 'inquiryHeader',
      render: (text, record) => <div>{text}</div>,
      width:'50%'
    },
    {
      title: '상태',
      key: 'inquiryStatus',
      dataIndex: 'inquiryStatus',
      render: (text) => {
          let color = !!!text ? 'geekblue' : 'green';
            return (
              <>
                {!!!text ? <div className='waiting'>답변대기</div> : <div className='finished'>답변완료</div>}
              </>
              // <Tag color={color} key={text}>
              //   {!!!text ? "답변대기" : "답변완료"}
              // </Tag>
            );
          }
    },
    {
      title: '작성일',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text, record) => {
        let today = dayjs();
        // let dday = dayjs(text);
        let dayjsFormat = dayjs(text).format('YYYY-MM-DD HH:mm');
        let dayjsFormat2 = dayjs(text).format('HH:mm');
        let diff = today.diff(dayjsFormat, "day");
      return(<div style={{textAlign:'center'}}>{diff === 0 ? dayjsFormat2 :dayjsFormat}</div>)
    }
  }
  ];
  useEffect(()=>{
      getInquiryList({userUuid : userData.userUuid, limit : 500, showType:""})
      .then((res)=>{
        if(res.data) setInquiryList(res.data); setInquiryListSaved(res.data)
      })
  },[])
  const searchHandler=()=>{
    let temp = inquiryListSaved.slice()
    if(searchKeyword !=="") temp =temp.filter((c:DataType) => c.inquiryHeader.includes(searchKeyword))
    setInquiryList(temp)
  }
  const [currentPage, setcurrentPage] = useState(1);
  const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
      setcurrentPage(pagination.current || 1);
      console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
    <MypageHeader page="inquiry"/>
    <div className='mypageWrapper'>
      
        <div className='inquiryWrapper'>
          <div className='inquiryTopBtnContainter'>
            <div className='inquiryTopBtnSection'>
              {/* <img src={searchIcon} width={24}/> */}
              <Input type='text' value={searchKeyword} onChange={onChangeHandler} allowClear
              prefix={<SearchOutlined/>} placeholder='검색할 제목을 입력해 주세요.'/>
            </div>
            <Button onClick={()=>{searchHandler()}}>검색</Button>
          </div>
          < Table showSorterTooltip={false} className='userInquiryTable' columns={columns} dataSource={inquiryList} rowKey={(render)=>render.inquiryUuid}
           pagination={{ position: ['bottomCenter'] }}
           onChange={onCurrentPageChange}
           onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {navigate(ROUTES.USER_INQUIRYLIST_F+'/'+record.inquiryIndex)}, // click row
            };
          }}
           />
          <div className='inquiryBtnContainter'>
            <Button type="primary"  onClick={()=>navigate(ROUTES.USER_INQUIRY_F)}>글쓰기</Button>
          </div>
        </div>
    </div>
    </>
  )
}

export default InquiryList

const InquiryItem = () => {

}