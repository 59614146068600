import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../constants/router.js'
import { logoutAdmin } from '../../../_actions/AdminAction';
import type { MenuProps } from 'antd';
import { Button, Layout, Menu } from 'antd';
import { userTypeStringToInt } from '../../../constants/auth.js';
import logoimg from '../images/logo.png';
import './AdminNav.css'
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export const SiderAdmin:any = (props:any) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [path, setPath] = useState("");
    useEffect(()=>{
        setPath(location.pathname)
    }, [location.pathname])
    let {isOpened, isNavShowing, userType, schoolUuid} = props;
    const logoutAdminCallback = useCallback(async () => dispatch(await logoutAdmin()), [dispatch]); 
    const handleLogout = () => {
        logoutAdminCallback()
        navigate(ROUTES.ADMIN_LOGIN_F)
    }
    function getItem(
        label: React.ReactNode,
        key: React.Key,
        // icon?: React.ReactNode,
        children?: MenuItem[],
      ): MenuItem {
        return {
          key,
        //   icon,
          children,
          label,
        } as MenuItem;
      }
    //직원
    const items1: MenuItem[] = [
        getItem('학교 선택', ROUTES.ADMIN_SCHOOL_F),
        getItem('내 정보', ROUTES.ADMIN_INFO_F),
        getItem('계정 관리', 'sub1', [
            getItem('학생', ROUTES.ADMIN_STUDENT_F)
        ]),
        getItem('채널관리', ROUTES.ADMIN_CHANNELLIST_F),
        getItem('시스템관리', 'sub2', [
            getItem('공지사항', ROUTES.ADMIN_NOTICELIST_F),
            getItem('문의관리', ROUTES.ADMIN_INQUIRYLIST_F)
        ]),
        ];
    const items1_1: MenuItem[] = [
        getItem('학교 선택', ROUTES.ADMIN_SCHOOL_F),
        getItem('내 정보', ROUTES.ADMIN_INFO_F),
        getItem('채널관리', ROUTES.ADMIN_CHANNELLIST_F),
        getItem('시스템관리', 'sub2', [
            getItem('공지사항', ROUTES.ADMIN_NOTICELIST_F),
            getItem('문의관리', ROUTES.ADMIN_INQUIRYLIST_F),
            getItem('자주 묻는 질문', ROUTES.ADMIN_FAQ_F)
        ]),
    ];
    
    //교사
    const items2: MenuItem[] = [
        getItem('내 정보', ROUTES.ADMIN_INFO_F),
        getItem('계정 관리', 'sub1', [
            getItem('학생', ROUTES.ADMIN_STUDENT_F),
            getItem('학부모', ROUTES.ADMIN_PARENTS_F)
        ]),
        getItem('채널관리', ROUTES.ADMIN_CHANNELLIST_F),
        getItem('팀 관리', ROUTES.ADMIN_TEAMLIST_F),
        getItem('시스템관리', 'sub2', [
            getItem('공지사항', ROUTES.ADMIN_NOTICELIST_F),
            getItem('문의관리', ROUTES.ADMIN_INQUIRYLIST_F),
            getItem('자주 묻는 질문', ROUTES.ADMIN_FAQ_F)
        ]),
        ];
    //슈퍼관리자
    const items3: MenuItem[] = [
        getItem('학교 선택', ROUTES.ADMIN_SCHOOL_F),
        getItem('내 정보', ROUTES.ADMIN_INFO_F),
        getItem('계정 관리', 'sub1', [
            getItem('학생', ROUTES.ADMIN_STUDENT_F),
            getItem('학부모', ROUTES.ADMIN_PARENTS_F),
            getItem('교사', ROUTES.ADMIN_TEACHER_F),
            getItem('직원', ROUTES.ADMIN_STAFF_F)
        ]),
        getItem('채널관리', ROUTES.ADMIN_CHANNELLIST_F),
        getItem('팀 관리', ROUTES.ADMIN_TEAMLIST_F),
        getItem('시스템관리', 'sub2', [
            getItem('공지사항', ROUTES.ADMIN_NOTICELIST_F),
            getItem('문의관리', ROUTES.ADMIN_INQUIRYLIST_F),
            getItem('자주 묻는 질문', ROUTES.ADMIN_FAQ_F),
            getItem('점검관리', ROUTES.ADMIN_SYSTEM_F),
            getItem('버전관리', ROUTES.ADMIN_APPVERSION_F),
            getItem('약관관리', ROUTES.ADMIN_TERMS_F)
        ]),
        ];
    const items3_1: MenuItem[] = [
        getItem('학교 선택', ROUTES.ADMIN_SCHOOL_F),
        getItem('내 정보', ROUTES.ADMIN_INFO_F),
        getItem('채널관리', ROUTES.ADMIN_CHANNELLIST_F),
        getItem('팀 관리', ROUTES.ADMIN_TEAMLIST_F),
        getItem('시스템관리', 'sub2', [
            getItem('공지사항', ROUTES.ADMIN_NOTICELIST_F),
            getItem('문의관리', ROUTES.ADMIN_INQUIRYLIST_F),
            getItem('자주 묻는 질문', ROUTES.ADMIN_FAQ_F),
            getItem('점검관리', ROUTES.ADMIN_SYSTEM_F),
            getItem('버전관리', ROUTES.ADMIN_APPVERSION_F),
            getItem('약관관리', ROUTES.ADMIN_TERMS_F)
        ]),
        ];
    const onClickHandler = (props:any) => {
        let { item, key, keyPath, domEvent } = props;
        navigate(key, { state: {page:""} })
    }
    const showMenuByuserType = () => {
        //3-직원, 4-교사, 5-슈퍼관리자
        // console.log(userType, schoolUuid)
        if(userType=== userTypeStringToInt('직원') && schoolUuid.length>1) return items1
        if(userType=== userTypeStringToInt('직원') && schoolUuid.length<1) return items1_1
        if(userType=== userTypeStringToInt('교사')) return items2
        if(userType=== userTypeStringToInt('슈퍼') && schoolUuid.length>1) return items3
        if(userType=== userTypeStringToInt('슈퍼') && schoolUuid.length<1) return items3_1
    }
    return (
        <>
        {isNavShowing &&
        <Sider trigger={null} collapsible collapsed={!isOpened}
        style={{display:'flex', flexDirection:'column', textAlign: 'center', borderRight: "1px solid #f4f4f4", backgroundColor:"white"}}>
        {
            isOpened &&
            <div className='adminSiderWrapper'>
                <div className='adminLogo logoWrapper' onClick={()=>navigate(ROUTES.ADMIN_MAIN_F)} ><img className='userNavLogo pointer' src={logoimg} alt="인천메타캠퍼스로고" /></div>
                <div className='adminLogo logoutBtnWrapper'>
                    <Button onClick={()=>handleLogout()}>로그아웃</Button>
                </div>
                
                <Menu
                mode="inline"
                theme="dark"
                selectedKeys={[path]}
                // defaultSelectedKeys={[ROUTES.ADMIN_INFO_F]}
                // defaultOpenKeys={['sub1']}
                style={{ height: '100%' }}
                items={showMenuByuserType()}
                onClick={onClickHandler}
                />
            </div>
    }
        </Sider>}
        </>
    )
}
