import * as ROUTES from '../../../../constants/router.js'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import './css/mypage.css'
function MypageHeader(props:any) {
  let {page="profile"} = props;
  let navigate = useNavigate();
  const checkPage = (type:string, str:string) => {
    if(type === str) return 'primary'
    return 'default';
  }
  return (
      <div className='mypageHeaderWrapper'>
        <Button className={`${page==='profile' ? "selected" : ""}`} onClick={()=>navigate(ROUTES.USER_PROFILE_F)}>프로필</Button>
        <Button className={`${page==='inquiry' ? "selected" : ""}`} onClick={()=>navigate(ROUTES.USER_INQUIRYLIST_F)}><img/>문의하기</Button>
        <Button className={`${page==='faq' ? "selected" : ""}`} onClick={()=>navigate(ROUTES.USER_FAQ_F)}>자주 묻는 질문</Button>
      </div>
  )
}

export default MypageHeader