import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Register from './Register';
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import { getPolicyList} from '../../../../_actions/AdminAction';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
import dayjs from 'dayjs';

export interface DataType {
    policyIndex:number;
    policyContents: string;
    privacyContents : string;
    createdDate:string;
  }
  function PolicyList() {
    const navigate = useNavigate();
    const columns: ColumnsType<DataType> = [
        {
            title: '번호',
            dataIndex: 'policyIndex',
            sorter: {
                compare: (a, b) => a.policyIndex-b.policyIndex,
              },
            render: (text, row, index) => {
                    return(<div>{(currentPage-1)*10+(index+1)}</div>)
                }
        },
        {
            title: '버전',
            dataIndex: 'policyIndex',
            sorter: {
                compare: (a, b) => a.policyIndex-b.policyIndex,
              },
            render: (text, row, index) => {
                return(<div>Version {text}</div>)
            }
        },
        {
            title: '기간',
            dataIndex: 'createdDate',
            sorter: {
                compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
              },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD');
                let dayjsFormat2 = index == 0 ? null : dayjs(policyList[index-1]['createdDate']).format('YYYY-MM-DD');
                // return(<div>{index===0 ? dayjsFormat : dayjsFormat+' ~ '+dayjsFormat2}</div>)
                return(
                    <>
                    {index == 0 ? <div>{dayjsFormat}</div>
                    :<div>{dayjsFormat} ~ {dayjsFormat2}</div>}
                    </>
                    )
            }
        },
        {
            title: '상태',
            dataIndex: 'createdDate',
            render: (text, row, index) => {
                return(<div>{index===0 ? '활성' : '비활성'}</div>)
            }
        },
    ];
    const [policyList, setPolicyList] = useState<DataType[]>([]);
    const [index, setIndex] = useState(0);
    useEffect(()=>{
        getPolicyListFunc()
    },[])
    
    const getPolicyListFunc = () => {
        getPolicyList()
        .then((res)=>{
            if(res.data){
                let temp = res.data;
                console.log(temp)
                setPolicyList(temp)
            }  
        })
        .catch((error: { response: { data: any; }; "": any; })=>{
            if(error.response.data) alertMessage(error.response.data)
        })
    }
    const [currentPage, setcurrentPage] = useState(1);
    const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        setcurrentPage(pagination.current || 1);
        console.log('params', pagination, filters, sorter, extra);
    };
  return (
    <div className='adminContentSection'>
    <div className='adminListHeader'>약관 관리</div>
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'></div>
                <div className='adminBtnSection'>
                    <Button type='primary' onClick={()=>navigate(ROUTES.ADMIN_TERMSCREATE_F)}>등록</Button>
                </div>
            </div>
                < Table showSorterTooltip={false} columns={columns} dataSource={policyList} tableLayout={"auto"}
                rowKey={(render)=>render.policyIndex}
                onChange={onCurrentPageChange}
                onRow={(record, rowIndex) => {
                    return {
                    onClick: (event) => {navigate(ROUTES.ADMIN_TERMS_F+"/"+record.policyIndex)}
                    // {setIndex(record.policyIndex); setPage("수정")}, // click row
                    };
                }}
                />
        </div>
    </div>
  )
}

export default PolicyList
