import React, { useEffect, useState } from 'react'
import { Button, Table, Input, Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { getAdmissionYearList } from '../../../../constants/common';
import { confirmMessage} from '../../../../Utils/Utils';
import { deleteStudentList, downladStudentListByExcel, getUserListBySchool, setInitUserPw } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;

export interface DataType {
    admissionYear: string;
    grade: number;
    gradeClass: number;
    gradeNumber: number;
    userName : string;
    userAccount : string;
  }
  function StudentList() {
    const navigate = useNavigate();
    const {schoolInfo:{schoolUuid}} = useSelector((state : RootState) => state.adminReducer);
    const [searchName, setSearchName] = useState("");
    const [searchYear, setSearchYear] = useState("");
    const [searchYearList, setSearchYearList] = useState<String[]>([]);
    const [searchGrade, setSearchGrade] = useState<number>(0);
    const [searchGradeClass, setSearchGradeClass] = useState<number>(0);
    const [page, setPage] = useState("");
    const [studentList, setStudentList] = useState([]);
    const [studentListSaved, setStudentListSaved] = useState([]);
    const [selectedlist, setSelectedlist] = useState([]);
    const [selectedUserData, setSelectedUserData] = useState<DataType|null>();
    const columns: ColumnsType<DataType> = [
        {
            title: '입학연도',
            dataIndex: 'admissionYear',
            sorter: {
                compare: (a, b) => a.admissionYear.localeCompare(b.admissionYear),
            },
            width:'12.5%'
        },
        {
            title: '학년',
            dataIndex: 'grade',
            sorter: {
                compare: (a, b) => a.grade - b.grade,
            },
            width:'12.5%'
        },
        {
            title: '반',
            dataIndex: 'gradeClass',
            sorter: {
            compare: (a, b) => a.gradeClass - b.gradeClass,
            },
            width:'12.5%'
        },
        {
            title: '번호',
            dataIndex: 'gradeNumber',
            sorter: {
                compare: (a, b) => a.gradeNumber - b.gradeNumber,
            },
            width:'12.5%'
        },
        {
            title: '이름',
            dataIndex: 'userName',
            sorter: {
                compare: (a, b) => a.userName.localeCompare(b.userName),
            },
            width:'25%'
        },
        {
            title: '아이디',
            dataIndex: 'userAccount',
            sorter: {
                compare: (a, b) => a.userAccount.localeCompare(b.userAccount),
            },
            width:'25%'
        },
        {
            title: '',
            dataIndex: 'userAccount',
            width:'25%',
            render(value, record, index) {
                return(<Button type='primary' onClick={()=>startEditmode(record)}>수정</Button>)
            },
        }
    ];
  const startEditmode = (row:DataType) => {
    // console.log(row)
    navigate(ROUTES.ADMIN_STUDENTITEM_I+row.userAccount)
    setPage("- 수정 하기")
    setSelectedUserData(row)
  }
  const endEditmode = () => {
    setPage("")
    setSelectedUserData(null)
  }
  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

    const onChangeSearchYear = (value: string) => {
        setSearchYear(value);       
        };
    const onChangeSearchGrade =(val: any) => {
        if(val>=0 && val<=6) setSearchGrade(val);
        if(val === null) setSearchGrade(0)     
        };
    const onChangeSearchGradeClass = (val: any) => {
        if(val>=0) setSearchGradeClass(val);
        if(val === null) setSearchGradeClass(0)
        };
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    const pageHandler=(data:any)=>{
        // 목록 다시불러오는 함수 추가
        if(data === "") getUserList()
        setPage(data)
    }
    useEffect(()=>{
        if(page===""){
        let temp = getAdmissionYearList()
        setSearchYearList(temp);
        getUserList()}
    },[page])
    const getUserList = () => {
        getUserListBySchool({schoolUuid : schoolUuid, userType : 1})
        .then((res)=>{
            if(res.data){
                let temp = res.data.sort((a: { userAccount: any; },b: { userAccount: any; })=>b.userAccount.localeCompare(a.userAccount))
                setStudentList(temp)
                setStudentListSaved(temp)
            }  
        })
    }
const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRowKeys)
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    }),
};
const setInitPassword = () => {
    confirmMessage("비밀번호 초기화", <div>정말로 비밀번호를 초기화 하시겠습니까?<br/>진행하시면 초기 비밀번호 ‘1111’로 변경됩니다.</div>, 'primary', ()=>{
        setInitUserPw(selectedlist)
        .then((res)=>{
            if(res.data) alert(`${selectedlist.length}명의 계정 비밀번호가 초기화되었습니다.`)

        })
    })
}
const deleteUser = () => {
    // console.log(selectedlist)
    confirmMessage("학생 정보 삭제", <div>정말로 삭제하시겠습니까?<br/>삭제하시면 해당 학생 아이디는 더 이상 사용이 불가능하며,<br/>삭제 대신 수정을 통해 학생 정보를 수정할 수 있습니다.</div>, 'primary', ()=>{
        deleteStudentList(selectedlist)
        .then((res)=>{
            if(res.data){
                getUserList()
                alert(`${selectedlist.length}명이 삭제되었습니다.`)}
        })
    })
}
const searchHandler = () => {
    let temp = studentListSaved.slice()
    if(searchName !== "") temp = temp.filter((c:DataType) => c.userName.includes(searchName))
    if(searchYear !== "") temp = temp.filter((c:DataType) => c.admissionYear === searchYear)
    if(searchGrade !== 0) temp = temp.filter((c:DataType) => c.grade === searchGrade)
    if(searchGradeClass) temp = temp.filter((c:DataType) => c.gradeClass === searchGradeClass)
    setStudentList(temp)
}
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학생 관리{page}</div>
        <div>
            <div className='adminSearcWrapper' style={{justifyContent:'flex-end'}}><DownloadBtn/></div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                    <Select className='wideSelect' onChange={onChangeSearchYear} value={searchYear} defaultValue={""}>
                        <Option value= "">입학연도</Option>
                        {searchYearList.length>0 && searchYearList.map((c,i)=>{
                            return(
                                <Option value={c} key={i}>{c}</Option>
                            )
                        })}
                    </Select>
                    <Select onChange={onChangeSearchGrade} value={searchGrade} defaultValue={0}>
                        <Option value= {0}>학년</Option>
                        {Array.from({length: 6}, (_, i) => i + 1).map((c,id)=>{
                            return(<Option key={c} value={c}>{c}</Option>)
                        })}
                    </Select>
                    <Select onChange={onChangeSearchGradeClass} value={searchGradeClass} defaultValue={0}>
                        <Option value= {0}>반</Option>
                        {Array.from({length: 20}, (_, i) => i + 1).map((c,id)=>{
                            return(<Option key={c} value={c}>{c}</Option>)
                        })}
                    </Select>
                    <Input value={searchName} placeholder='학생 이름을 입력해 주세요.' onChange={onChangeSearchName}/>
                    <Button onClick={()=>searchHandler()}>검색</Button>
                    
                </div>
                <div className='adminBtnSection'>
                    <Button disabled={selectedlist.length===0} onClick={()=>setInitPassword()}>비밀번호 초기화</Button>
                    <Button disabled={selectedlist.length===0} onClick={()=>deleteUser()}>삭제</Button>
                    <Button onClick={()=>navigate(ROUTES.ADMIN_STUDENTCREATELIST_F)}>일괄 등록</Button>
                    <Button onClick={()=>navigate(ROUTES.ADMIN_STUDENTCREATE_F)}>등록</Button>
                </div>
            </div>
            
            < Table showSorterTooltip={false} columns={columns} dataSource={studentList} tableLayout={"auto"}
            rowKey={(render)=>render.userAccount}
            // onRow={(record, rowIndex) => {
            //     return {
            //       onClick: (event) => {startEditmode(record)}, // click row
            //     };
            //   }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}/>
        </div>
        {/* <EditStudent endEditmode={endEditmode} userData={selectedUserData} schoolUuid={schoolUuid}/> */}  
    </div>
  )
}

export default StudentList

const DownloadBtn = () => {
    const {schoolInfo:{schoolUuid, schoolName}} = useSelector((state : RootState) => state.adminReducer);
    const defaultValue = new Date().getFullYear();
    const [startYear, setStartYear] = useState<number>(defaultValue);
    const [endYear, setEndYear] = useState<number>(defaultValue);
    useEffect(()=>{
        if(endYear<=startYear){
            setEndYear(startYear)
        }
    },[startYear, endYear])
    const onSubmitHandler = async() => {
        const axiosResponse = await downladStudentListByExcel({startYear : `${startYear}`, endYear : `${endYear}`, schoolUuid})
        const blob = new Blob([axiosResponse.data], { type: `text/csv;charset=utf-8;` });
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `인천메타캠퍼스_${schoolName}_${startYear ===endYear ? startYear : startYear+'~'+endYear}_학생목록.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
    }
    return(
        <>
        <div>
            <span> 입학연도 :&nbsp;&nbsp;</span>
            <Select onChange={setStartYear} value={startYear} defaultValue={defaultValue}>
                {Array.from({length: 10}, (_, i) => defaultValue - i).map((c,id)=>{
                    return(<Option key={c} value={c}>{c}</Option>)
                })}
            </Select>
            <span> ~ </span>
            <Select onChange={setEndYear} value={endYear} defaultValue={defaultValue}>
                {Array.from({length: 10}, (_, i) => defaultValue - i).map((c,id)=>{
                    return(c>=startYear && <Option key={c} value={c}>{c}</Option>)
                })}
            </Select>
        </div>
        &nbsp;
        <Button onClick={()=>onSubmitHandler()}>엑셀 다운로드</Button>
        </>
        
    )
}