import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as ROUTES from '../../../../constants/router'
import { loginAdmin, setAdminSchool } from '../../../../_actions/AdminAction'
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { Input, Button } from 'antd';
import { userTypeStringToInt } from '../../../../constants/auth';
import logoimg from '../../images/logo.png'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


function LoginPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const loginAdminCallback = useCallback(async (body : {account : string, password: string}) => dispatch(await loginAdmin(body)),
    [dispatch]
    );
  const [adminAccount, setAdminAccount] = useState("")
  const [adminPassword, setAdminPassword] = useState("")
  const [msg, setMsg] = useState("")
  const onAdminAccountHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setMsg("")
    setAdminAccount(event.currentTarget.value)
  }
  const onPasswordHandler = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setMsg("")
    setAdminPassword(event.currentTarget.value)
  }
  const setAdminSchoolCallback = useCallback(async (body : {schoolName : string, schoolUuid: string}) => dispatch(await setAdminSchool(body)), [dispatch]); 
  const setSchoolInfoAndMovePage=(schoolName : string, schoolUuid: string)=>{
    setAdminSchoolCallback({schoolName, schoolUuid})
    .then(()=>navigate(ROUTES.ADMIN_MAIN_F))
}

  const onSubmitHandler = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    let body = {account: adminAccount, password: passwordIncoding(adminPassword)}
    // console.log(body)
    loginAdminCallback(body).then(((response)=>{
      // console.log(response)
      if(response.payload.loginStatus){
        if(response.payload.adminData.userType === userTypeStringToInt('교사')) {
            let schoolName = response.payload.adminData.adminSchoolname
            let schoolUuid = response.payload.adminData.adminSchooluuid
          setSchoolInfoAndMovePage(schoolName, schoolUuid)
        } else {
          navigate(ROUTES.ADMIN_MAIN_F)
        }
        
      } else {
        setMsg('아이디와 비밀번호를 확인해 주세요.')
      }
    })).catch((error) => {
        console.log(error)
        if(error.response.data) setMsg(error.response.data.resultMsg)
    });
  }
  return (
    <div className='adminCenterWrapper adminLoginWrapper'>
      <div className='adminCenterContainer'>
        <div className='adminLoginLogoWrapper' ><img src={logoimg} alt="인천메타캠퍼스로고" /></div>
        <div className='adminCenterHeader'>ADMIN</div>
        <div className='adminErrMsg'>{msg}</div>
        <div className='adminLoginInput'>
          <Input type='text' name='adminAccount' value={adminAccount} size="large"
        placeholder='아이디' onChange={onAdminAccountHandler}/></div>
        <div className='adminLoginInput'>
          <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='adminPassword' value={adminPassword} size="large"
        placeholder='비밀번호' onChange={onPasswordHandler}/></div>
        <div className='flexFull adminLoginBtn'>
          <Button size='large' type="primary" onClick={onSubmitHandler} >로그인</Button></div>
        <div className='flexBetween adminLoginSubBtn'>
          <div className='pointer' onClick={()=>navigate(ROUTES.ADMIN_FINDINFO_F)}><strong><u>아이디/비밀번호 찾기</u></strong></div>
          <div className='pointer' onClick={()=>navigate(ROUTES.ADMIN_SIGNUP_F)}><strong><u>회원가입</u></strong></div>
          
        </div>
      </div>
    </div>
    
  )
}

export default LoginPage