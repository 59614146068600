import axios from 'axios';

export async function getNoticeList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getNoticeList`, dataToSubmit);
}
export async function getNotice(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getNotice`, dataToSubmit);
}
export async function saveNotice(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveNotice`, dataToSubmit);
}
export async function deleteNotice(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteNotice`, dataToSubmit);
}
