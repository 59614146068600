import { useState, useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import UserNav from "./nav/UserNav";
import Footer from '../footer/Footer';
import * as ROUTES from '../../constants/router';
import MainPage from './pages/main/Main';
import ProtectedRoutes from "./ProtectedUsersRoutes";
import UserLogin from './pages/login/UserLogin';
import UserInitPage from './pages/login/InitPasswordPage';
import DownloadPage from './pages/downloadpage/Downloadpage';
import InquiryItem from './pages/mypage/InquiryItem';
import Inquiry from './pages/mypage/Inquiry'
import InquiryList from './pages/mypage/InquiryList'
import Profile from './pages/mypage/Profile'
import Faq from "./pages/mypage/Faq";
import Policy from "./pages/policy/Policy";
import Privacy from "./pages/policy/Privacy";

export default function HomeUser() {
    // const {userData} = useSelector((state : RootState) => state.userReducer);
    // const { userUuid, accountLevel } = userData;
    const location = useLocation();
    let pathname =  location.pathname;
    const [ accountLevel, setAccountLevel ] = useState(0)
    const [ isNavShowing, setisNavShowing ] = useState(true)
    useEffect(()=>{
        let noNavePageList = ['/users/login','/users/init', '/users/terms', '/users/terms_p']
        if(noNavePageList.indexOf(pathname)>-1){setisNavShowing(false)}
        if(noNavePageList.indexOf(pathname)<0 && !isNavShowing){setisNavShowing(true)}
        // console.log(noNavePageList.indexOf(pathname), isNavShowing)
    }, [pathname])
    return (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', minHeight:'100vh'}}>
            <UserNav isNavShowing={isNavShowing}/>
            <div style={pathname !== ROUTES.USER_COMMON ? {paddingTop:'80px', minHeight: '800px'}:{}}>
                <Routes>
                    <Route path={ROUTES.USER_POLICY} element={<Policy/>}/>
                    <Route path={ROUTES.USER_PRIVACY} element={<Privacy/>}/>
                    {/* 로그인 전 */}
                    <Route element={<ProtectedRoutes isUser={false} isInit={true} />}>
                        <Route path="/*" element={<MainPage/>} />
                        <Route path={ROUTES.USER_MAIN} element={<Navigate replace to={ROUTES.USER_COMMON}/>} />
                        <Route path={ROUTES.USER_LOGIN} element={<UserLogin/>}/>
                        <Route path={ROUTES.USER_POLICY} element={<Policy/>}/>
                    </Route>
                    {/* 로그인 후 & 패스워드 변경 필요 */}
                    <Route element={<ProtectedRoutes isUser={true} isInit={false} />}>
                        <Route path={ROUTES.USER_INITPW} element={<UserInitPage/>}/>
                    </Route>
                    {/* 로그인 후 */}
                    <Route element={<ProtectedRoutes isUser={true} isInit={true} />}>
                        <Route path={ROUTES.USER_DOWNLOAD} element={<DownloadPage/>}/>
                        <Route path={ROUTES.USER_PROFILE} element={<Profile/>}/>
                        <Route path={ROUTES.USER_INQUIRY} element={<Inquiry/>}/>
                        <Route path={ROUTES.USER_INQUIRYITEM} element={<InquiryItem/>}/>
                        <Route path={ROUTES.USER_INQUIRYLIST} element={<InquiryList/>}/>
                        <Route path={ROUTES.USER_FAQ} element={<Faq/>}/>
                    </Route>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}