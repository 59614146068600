import { Button, Checkbox, Collapse, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { Steps } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { passwordValidCheck } from '../../../../constants/common';
import { alertMessage, infoMessage, passwordIncoding } from '../../../../Utils/Utils';
import { checkAuthInfo, checkDupAccount, createAdmin, getPolicy, getSessionId } from '../../../../_actions/AdminAction';
import * as ROUTES from '../../../../constants/router';
import School from '../school/School';
import SunEditor from 'suneditor-react';

const { Panel } = Collapse;

declare global {
    interface Window {
      epkiInit: (data:string) => void;
      epkiSign: (data:string, successFunc:any) => void;      
      }
    
    }

function AdminSignUp() {
const [page, setPage] = useState(0);
const [userType, setUserType] = useState(3);
const [userTypeString, setUserTypeString] = useState("직원");
const [adminNeis, setAdminNeis] = useState("");
useEffect(()=>{
    if(userType===3 && userTypeString !=="직원") setUserTypeString("직원")
    if(userType===4 && userTypeString !=="교사") setUserTypeString("교사")
}, [userType])
const settingType = (type:any) => {
    setUserType(type)
    setPage(1)
}
    return (
        <div className='adminCenterWrapper adminSignUpWrapper'>
            {page >0 && 
            <div className='adminSignUpProgess'>
            <Steps current={page-1}
                labelPlacement="vertical"
                items={[
                {
                    title: '이용약관 동의'
                },
                {
                    title: '본인인증'
                },
                {
                    title: '회원정보 입력'
                },
                {
                    title: '회원가입 완료'
                }
                ]}
            />
            
            </div>
            }
            <div className={`adminCenterContainer adminSignUpContainer ${page>0 && 'adminCenterContainerW' }`}>
                {page>0&&<div className='adminCenterHeader'>{userTypeString} 회원 가입</div>}
                {
                page === 0 ?
                    <>
                        <div className='adminCenterHeader'>회원 가입</div>
                        <div className='adminSignUpTypeBtnWrapper'>
                            <Button onClick={()=>settingType(3)}>직원</Button>
                            <Button onClick={()=>settingType(4)}>교사</Button>
                        </div>
                        
                    </>
                : page === 1 ? <CheckAgreement setPage={setPage}/>
                :page === 2 ? <CheckAuth setPage={setPage} setAdminNeis={setAdminNeis}/>
                :page === 3 ? <SignUpForm userType={userType} setPage={setPage} adminNeis={adminNeis}/>
                :page === 4 ? <FinishPage/>
                : null
                }
            </div>
        </div>
    )
}

export default AdminSignUp

const CheckAgreement = (props:any) => {
    let { setPage } = props;
    const [agree, setAgree] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    //이용약관
    const [policyContents, setpolicyContents] = useState('');
    //개인정보 처리 방침
    const [privacyContents, setprivacyContents] = useState('');
    useEffect(()=>{
        if(agree1 && agree2 && !agree) setAgree(true)
    }, [agree, agree1, agree2])
    useEffect(()=>{
        getPolicy({policyIndex:0})
        .then((res)=>{
            if(res.data){
                setpolicyContents(res.data.policyContents)
                setprivacyContents(res.data.privacyContents)
              }
        })
    }, [])
    const faqData = [
        ["인천교육청 가상캠퍼스 메타버스 이용약관 (필수)", policyContents],
        ["개인정보 수집 및 이용 (필수)", privacyContents]
      ]
    return(
        <>
            <div className='adminCenterSubHeader'>서비스 이용약관 및 개인정보 처리방침 동의</div>
            <div className='adminCheckBoxWrapper'>
                <Checkbox onChange={()=>{setAgree(!agree)}} checked={agree} name="agree" value={agree}>서비스 이용약관 및 개인정보 처리방침 동의</Checkbox>
            </div>
            <div className='adminPolicyWrapper'>
                <Collapse 
                onChange={()=>console.log()} bordered={false}
                expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
                style={{backgroundColor:'white', fontSize:'13px', margin:'10px auto'}}
                >
                {faqData.length>0 && faqData.map((c,id)=>{
                    return(
                    <Panel header={c[0]} key={id}>
                    {noEditableSunEditor(c[1])}
                    </Panel>
                    )
                })}            
                </Collapse>
            </div>
            <div className='adminCenterBottom'><Button disabled={!agree} size='large' type='primary' onClick={()=>setPage(2)}>동의 및 다음</Button></div>
        </>
    )
}
const noEditableSunEditor = (PRESENTATION_CONTENTS:any) => {
    return(
        <SunEditor lang="ko" height='auto'
            setOptions={{ 
                resizingBar: false,
                defaultStyle : `font-size : 1.3rem`
            }}
            hideToolbar={true}
            disableToolbar={true}
            disable={true}
            setContents={PRESENTATION_CONTENTS}
            setAllPlugins={false}
            />
    )
}
const CheckAuth = (props:any) => {
    let { setPage, setAdminNeis } = props;
    const [output, setOutput] = useState("");
    useEffect(()=>{
        getSessionId()
        .then((res)=>{
            window.epkiInit(res.data)
        }) 
    }, [])
    const setInfo = (data:string) => {
        // console.log(data)
        setOutput(data)
        
    }
    const authBtn = () => {
        window.epkiSign(`${process.env.REACT_APP_SALT}`, setInfo);
    }
    useEffect(()=>{
        if(output && output!=='fail'){
        checkAuthInfo({output})
        .then((res)=>{ 
            if(res.data && res.data !=='실패'){
                    setAdminNeis(res.data)
                    setPage(3)
            } else {
                alertMessage("인증서 확인이 실패했습니다.")
            }
        })
        .catch((err)=>{
            if(err.response.data) alertMessage(err.response.data)
            console.log(err)
        })
    }
    }, [output])
    return(
        <div className='adminAuthWrapper'>
            <div className='adminCenterSubHeader adminCenterSubHeader3'>본인 인증</div>
            <div className='adminCenterBody'>회원님의 본인확인을 진행해주세요.</div>
            <div className='adminCenterBody'>인천교육청 가상 캠퍼스 서비스 이용을 위해 본인확인이 필요합니다.</div>
            <div className='adminCenterBody' style={{color:'red'}}>※ 재발급 받은 새로운 인증서는 새로운 계정으로 가입되오니 이용에 참고 바랍니다.</div>
            <div className='adminCenterBottom'><Button onClick={()=>authBtn()} size='large' type='primary'>인증하기</Button></div>
        </div>
    )
}


const SignUpForm = (props:any) => {
    let { setPage, userType, adminNeis } = props;
    
    const [userName, setUserName] = useState("");
    const [userAccount, setUserAccount] = useState("");
    const [userAccountChecked, setUserAccountChecked] = useState(3); // 0 불가 1 가능 3 체크 전
    const [userAccountMsg, setUserAccountMsg] = useState("");
    const [schoolData, setSchoolData] = useState({schoolUuid : "", schoolName : ""});
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [newPasswordMsg, setNewPasswordMsg] = useState("");
    const [passwordConfirmMsg, setPasswordConfirmMsg] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [validBtn, setValidBtn] = useState(false);
    useEffect(()=>{
        if(userName!==''
        && userAccount!==''
        && userAccountChecked===1
        && newPassword!==''
        && newPassword===passwordConfirm
        && schoolData.schoolUuid!==''
        ){
            setValidBtn(true)
        } else {
            setValidBtn(false)
        }
    }, [userName, userAccount, userAccountChecked, newPassword, passwordConfirm, schoolData])
    useEffect(()=>{
        setUserAccountChecked(3)
    }, [userAccount])
    const onPasswordHandler = (event: { currentTarget: { name : React.SetStateAction<string>, value: React.SetStateAction<string>; }; }) => {
      setNewPasswordMsg(""); setPasswordConfirmMsg("")
      if(event.currentTarget.name === 'newPassword') setNewPassword(event.currentTarget.value)
      if(event.currentTarget.name === 'passwordConfirm') setPasswordConfirm(event.currentTarget.value)
      }
    const onChange = (event: { currentTarget: { name : React.SetStateAction<string>, value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    if(event.currentTarget.name === 'userName') setUserName(val)
    if(event.currentTarget.name === 'userAccount') setUserAccount(val)
    }
  const onSubmitHandler = (event: { preventDefault: () => void; }) => {
      event.preventDefault();

      if(newPassword ==="") return alertMessage("비밀번호를 입력하세요.")
      if(passwordConfirm ==="") return alertMessage("비밀번호를 다시 한번 입력하세요.")
      if(!passwordValidCheck(newPassword)) return setNewPasswordMsg("비밀번호가 올바르지 않습니다.")
      if(newPassword !== passwordConfirm) return setPasswordConfirmMsg("불일치 합니다. 비밀번호를 확인해 주세요.")
      if(adminNeis ==="") return alertMessage("인증서 정보가 올바르지 않습니다.")
      if(schoolData.schoolUuid ==="") return alertMessage("소속을 선택해주세요.")
      
      let body = {
        adminAccount : userAccount,
        adminName : userName,
        adminSchooluuid : schoolData.schoolUuid,
        adminPassword: passwordIncoding(newPassword),
        userType,
        adminNeis
      }
      console.log(body)
      createAdmin(body)
      .then((res)=>{
        if(res.data){
            setPage(4) 
        } else {
            return(
                Modal.error({
                    content : "존재하는 계정이거나 동일한 인증서로 가입된 내역이 있습니다.",
                    className : 'adminAuthModal',
                    width: '300px',
                    bodyStyle:{textAlign:'center'},
                    okText :'확인',
                    centered:true
                })
                )
        }
      })
      .catch((err)=>{
        console.log(err)
        if(err.response.data) alertMessage(err.response.data)
    })
    }
    const checkDupAccountFunc = ()=>{
        if(userAccount !==''){
        checkDupAccount({adminAccount : userAccount})
        .then((res)=>{
            if(res.data) setUserAccountChecked(1)
            else setUserAccountChecked(0)
        })
        .catch((err)=>{
            console.log(err)
        if(err.response.data) alertMessage(err.response.data)
    })
    }
    }
    const setSchoolInfo=(data:any)=>{
        setSchoolData(data)
        setModalOpen(false)
    }
    useEffect(()=>{
        if(userType ===3){
            setSchoolData({schoolUuid:"ice", schoolName:"인천교육청"})
        }
    },[userType])
    return (
      <div>
        <div className='required'>* 필수 정보</div>
        <div className='adminCenterSubHeader adminCenterSubHeader2'>회원 정보 입력</div>
        <div className='adminSignUpFormWrapper'>
            <div className='formLineWrapper'>
                <div><span className='requiredMark'>*</span> 이름 </div>
                <div><Input size='large' name='userName' value={userName} placeholder='이름을 입력해 주세요.' onChange={onChange}/></div>
            </div>
            <div className='formLineWrapper'>
                <div><span className='requiredMark'>*</span> 아이디 </div>
                <div><Input size='large' name='userAccount' value={userAccount} placeholder='아이디를 입력해 주세요.' onChange={onChange}/></div>
                <div><Button onClick={()=>checkDupAccountFunc()}>중복 확인</Button><span>{userAccountChecked===0?<span style={{color:'red'}}>실패!</span>:userAccountChecked===1?'성공!':null}</span></div>
            </div>
            <div className='formLineWrapper'>
                <div><span className='requiredMark'>*</span> 비밀번호 입력 </div>
                <div>
                    <Input.Password size='large' type='password' name='newPassword' value={newPassword} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    placeholder='비밀번호를 입력해 주세요.' onChange={onPasswordHandler} required/>
                    <div>{newPasswordMsg}</div>
                </div>
            </div>  
            <div className='formLineWrapper formLineWrapperL'>
                <div><span className='requiredMark'>*</span> 비밀번호 확인 </div>
                <div>
                    <Input.Password size='large' type='password' name='passwordConfirm' value={passwordConfirm} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    placeholder='다시 한번 입력해 주세요.' onChange={onPasswordHandler} required/>
                    <div>{passwordConfirmMsg.length>0 ? passwordConfirmMsg : '최소8~최대16글자 (영문+숫자+기호) 기호 : !@#$%^&*()_+'}</div>
                </div>
            </div>
            <div className='formLineWrapper'>
                <div><span className='requiredMark'>*</span> 소속</div>
                <div>{schoolData.schoolName} {userType ===4 && <Button onClick={()=>setModalOpen(true)}>선택</Button>}</div>
            </div> 
        </div>  
        <div className='adminCenterBottom'><Button type='primary' size='large' disabled={!validBtn} onClick={onSubmitHandler}>회원가입</Button></div>
        <Modal title="교사 검색" centered open={modalOpen} onCancel={()=>setModalOpen(false)} footer={[]} width={700}>
            <School setInfoFunc={setSchoolInfo}/>
        </Modal>
      </div>
    )
  }
  const FinishPage = () => {
    let navigate = useNavigate();
    return(
        <div className='adminSignUpFinishWrapper'>
            <div className='adminCenterSubHeader'>가입이 완료되었습니다.</div>
            <div>모든 회원가입 절차가 완료되었습니다.<br/>로그인 후 모든 서비스를 이용할 수 있습니다.</div>
            <div className='adminCenterBottom'>
                <Button type='primary' size='large' onClick={()=>navigate(ROUTES.ADMIN_LOGIN_F)}>로그인 화면으로</Button>
            </div>
        </div>
        
    )
  }