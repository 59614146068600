import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from '../../constants/router.js'
import AdminLogin from "./pages/login/AdminLogin";
import AdminNav from "./nav/AdminNav";
import Main from './pages/main/Main'
import PasswordChange from './pages/mypage/PasswordChange'
import StudentList from './pages/student/StudentList'
import School from './pages/school/School'
import { useEffect, useState } from "react";
import Footer from '../footer/Footer';
import { SiderAdmin } from "./nav/AdminSider";
import { Layout } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../_reducers/index.js";
import ParentsList from "./pages/parents/ParentsList";
import TeacherList from "./pages/teacher/TeacherList";
import StaffList from "./pages/staff/StaffList";
import ChannelList from "./pages/channel/ChannelList";
import ChannelTeam from "./pages/channelTeam/ChannelTeam";
import NoticeList from "./pages/notice/NoticeList";
import Faq from "../user/pages/mypage/Faq";
import InquiryList from "./pages/inquiry/InquiryList";
import Version from "./pages/version/Version";
import PolicyList from "./pages/policy/PolicyList";
import SystemList from "./pages/system/SystemList";
import AdminSignUp from "./pages/login/AdminSignUp";
import FindAccounInfo from "./pages/login/FindAccounInfo";
import ProtectedAdminRoutes from "./ProtectedAdminRoutes";
import './css/admin.css';
import ChannelItem from "./pages/channel/ChannelItem";
import ChannelTeamItem from "./pages/channelTeam/Register";
import Register from "./pages/student/Register";
import RegisterByExcel from "./pages/student/RegisterByExcel";
import EditStudent from "./pages/student/EditStudent";
import RegisterParents from "./pages/parents/Register";
import RegisterByExcelParents from "./pages/parents/RegisterByExcel";
import RegisterTeacher from "./pages/teacher/Register";
import RegisterStaff from "./pages/staff/Register";
import MyInfo from "./pages/mypage/MyInfo";
import RegisterNotice from "./pages/notice/Register";
import InquiryReply from "./pages/inquiry/InquiryReply";
import InquiryItem from "./pages/inquiry/InquiryItem";
import RegisterSystem from "./pages/system/Register";
import RegisterTerms from "./pages/policy/Register";

const { Header, Content, Sider } = Layout;

export default function HomeUser() {
    const location = useLocation();
    let pathname =  location.pathname;
    
    const {adminData:{userType}, schoolInfo:{schoolName, schoolUuid} } = useSelector((state : RootState) => state.adminReducer);
    
    const [ isNavShowing, setisNavShowing ] = useState(true)
    const [ isSidebarOpened, setIsSidebarOpened ] = useState(true)
    const sidebarHandler=()=>{
        setIsSidebarOpened(!isSidebarOpened)
    }
    useEffect(()=>{
        let noNavePageList = ['/admin/login', '/admin/signUp', '/admin/findAdminInfo']
        if(noNavePageList.indexOf(pathname)>-1){setisNavShowing(false)}
        if(noNavePageList.indexOf(pathname)<0 && !isNavShowing){setisNavShowing(true)}
        // console.log(noNavePageList.indexOf(pathname), isNavShowing)
    }, [pathname])
    return (
        <Layout style={{display:'flex', justifyContent:'flex-start', width:'100vw'}}>
            <SiderAdmin isOpened={isSidebarOpened} isNavShowing={isNavShowing} userType={userType} schoolUuid={schoolUuid}/>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', minHeight:'100vh', width:'100%'}}>
                <AdminNav isNavShowing={isNavShowing} sidebarHandler={sidebarHandler}/>
                <Routes>
                    {/* isAdmin={false} */}
                    <Route element={<ProtectedAdminRoutes isAdmin={false} isSelectedSchool={false} />} >
                        <Route path="/*" element={<Navigate replace to={ROUTES.ADMIN_LOGIN_F}/>} />
                        <Route path={ROUTES.ADMIN_LOGIN} element={<AdminLogin/>}/>
                        <Route path={ROUTES.ADMIN_SIGNUP} element={<AdminSignUp/>}/>
                        <Route path={ROUTES.ADMIN_FINDINFO} element={<FindAccounInfo/>}/>
                    </Route>

                    {/* isAdmin={true} isSelectedSchool={false} */}
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={false} useTypeArr={[3,4,5]} />} >
                        <Route path={ROUTES.ADMIN_MAIN} element={<Main/>}/>
                        <Route path={ROUTES.ADMIN_INFO} element={<MyInfo/>}/>
                        <Route path={ROUTES.ADMIN_CHANGEPW} element={<PasswordChange/>}/>
                        <Route path={ROUTES.ADMIN_FAQ} element={
                        <div className='adminContentSection'>
                            <div className='adminListHeader'>자주 묻는 질문</div>
                            <Faq isStudent={false}/>
                        </div>}/>

                        <Route path={ROUTES.ADMIN_NOTICELIST} element={<NoticeList/>}/>
                        <Route path={ROUTES.ADMIN_NOTICECREATE} element={<RegisterNotice/>}/>
                        <Route path={ROUTES.ADMIN_NOTICEITEM} element={<RegisterNotice/>}/>
                        <Route path={ROUTES.ADMIN_NOTICELIST+"/*"} element={<NoticeList/>}/>
                        
                        <Route path={ROUTES.ADMIN_INQUIRYLIST} element={<InquiryList/>}/>
                        <Route path={ROUTES.ADMIN_INQUIRYREPLY} element={<InquiryReply/>}/>
                        <Route path={ROUTES.ADMIN_INQUIRYITEM} element={<InquiryItem/>}/>
                        <Route path={ROUTES.ADMIN_INQUIRYLIST+"/*"} element={<InquiryList/>}/>

                        <Route path={ROUTES.ADMIN_CHANNELITEM} element={<ChannelItem/>}/>
                        <Route path={ROUTES.ADMIN_CHANNELLIST+"/*"} element={<ChannelList/>}/>
                    </Route>
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={false} useTypeArr={[3,5]} />} >
                        <Route path={ROUTES.ADMIN_SCHOOL} element={<School isAuth={true}/>}/>
                    </Route>
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={false} useTypeArr={[4,5]} />} >
                        <Route path={ROUTES.ADMIN_TEAMITEM} element={<ChannelTeamItem/>}/>
                        <Route path={ROUTES.ADMIN_TEAMLIST+"/*"} element={<ChannelTeam/>}/>
                    </Route>
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={false} useTypeArr={[5]} />} >
                        <Route path={ROUTES.ADMIN_APPVERSION} element={<Version/>}/>
                        <Route path={ROUTES.ADMIN_TERMS} element={<PolicyList/>}/>
                        <Route path={ROUTES.ADMIN_TERMSITEM} element={<RegisterTerms/>}/>
                        <Route path={ROUTES.ADMIN_TERMSCREATE} element={<RegisterTerms/>}/>
                        <Route path={ROUTES.ADMIN_TERMS+"/*"} element={<PolicyList/>}/>
                        <Route path={ROUTES.ADMIN_SYSTEM} element={<SystemList/>}/>
                        <Route path={ROUTES.ADMIN_SYSTEMCREATE} element={<RegisterSystem/>}/>
                        <Route path={ROUTES.ADMIN_SYSTEM+"/*"} element={<SystemList/>}/>
                    </Route>

                    {/* isAdmin={true} isSelectedSchool={true} */}
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={true} useTypeArr={[3,4,5]} />} >
                        
                        <Route path={ROUTES.ADMIN_STUDENT} element={<StudentList/>}/>
                        <Route path={ROUTES.ADMIN_STUDENTCREATE} element={<Register/>}/>
                        <Route path={ROUTES.ADMIN_STUDENTCREATELIST} element={<RegisterByExcel/>}/>
                        <Route path={ROUTES.ADMIN_STUDENTITEM} element={<EditStudent/>}/>
                        <Route path={ROUTES.ADMIN_STUDENT+"/*"} element={<StudentList/>}/>
                        
                    </Route>
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={true} useTypeArr={[4,5]} />} >
                        <Route path={ROUTES.ADMIN_PARENTS} element={<ParentsList/>}/>
                        <Route path={ROUTES.ADMIN_PARENTSCREATE} element={<RegisterParents/>}/>
                        <Route path={ROUTES.ADMIN_PARENTSCREATELIST} element={<RegisterByExcelParents/>}/>
                        <Route path={ROUTES.ADMIN_PARENTSITEM} element={<RegisterParents/>}/>
                        <Route path={ROUTES.ADMIN_PARENTS+"/*"} element={<ParentsList/>}/>
                    </Route>
                    <Route element={<ProtectedAdminRoutes isAdmin={true} isSelectedSchool={true} useTypeArr={[5]} />} >
                        <Route path={ROUTES.ADMIN_TEACHER} element={<TeacherList/>}/>
                        <Route path={ROUTES.ADMIN_TEACHER} element={<TeacherList/>}/>
                        <Route path={ROUTES.ADMIN_TEACHERCREATE} element={<RegisterTeacher/>}/>
                        <Route path={ROUTES.ADMIN_TEACHERITEM} element={<RegisterTeacher/>}/>
                        <Route path={ROUTES.ADMIN_TEACHER+"/*"} element={<TeacherList/>}/>

                        <Route path={ROUTES.ADMIN_STAFF} element={<StaffList/>}/>
                        <Route path={ROUTES.ADMIN_STAFF} element={<StaffList/>}/>
                        <Route path={ROUTES.ADMIN_STAFFCREATE} element={<RegisterStaff/>}/>
                        <Route path={ROUTES.ADMIN_STAFFITEM} element={<RegisterStaff/>}/>
                        <Route path={ROUTES.ADMIN_STAFF+"/*"} element={<StaffList/>}/>
                    </Route>
                    
                </Routes>
            </div>
            {/* <Footer/> */}
        </Layout>
    );
}