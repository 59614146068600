import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { deleteInquiry, getInquiry } from '../../../../_actions/InquiryAction';
import moment from 'moment';
import { phoneformatter } from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { confirmDeleteMessage } from '../../../../Utils/Utils';
import { Button } from 'antd';

function InquiryItem(props:any) {
  // let { selectedUuid="", isUser=true, setTab=()=>{console.log("setTab is null")} } = props;
  let navigate = useNavigate();
  const params = useParams();
  const { id } = useParams()
  const inquiryIndex = id;
  const [createdDate, setCreatedDate] = useState("");
  const [inquiryHeader, setInquiryHeader] = useState("");
  const [replyBody, setReplyBody] = useState("");
  const [inquiryBody, setInquiryBody] = useState("");
  const [inquiryUuid, setInquiryUuid] = useState("");
  const [writerEmail, setWriterEmail] = useState("");
  const [writerPhone, setWriterPhone] = useState("");
  const dateFormat = 'YYYY-MM-DD hh:mm';
  const setTimeformat = (dateData:Date) => {return moment(dateData).format(dateFormat)};
  useEffect(()=>{
    if(params && params.detail){
        setInquiryUuid(params.detail)
    }
  }, [params])

  useEffect(()=>{
    console.log(id)
    getInquiry({inquiryIndex:0 || -1, inquiryUuid})
    .then((res)=>{
      if(res.data){
        if(res.data.createdDate) setCreatedDate(setTimeformat(res.data.createdDate))
        if(res.data.inquiryHeader) setInquiryHeader(res.data.inquiryHeader)
        if(res.data.inquiryBody) setInquiryBody(res.data.inquiryBody)
        if(res.data.replyBody) setReplyBody(res.data.replyBody)
        if(res.data.writerEmail) setWriterEmail(res.data.writerEmail)
        if(res.data.writerPhone) setWriterPhone(res.data.writerPhone)
        if(res.data.inquiryUuid) setInquiryUuid(res.data.inquiryUuid)
      }
    })
  }, [inquiryUuid])
  const deleteInquiryFunc=()=>{
    confirmDeleteMessage(<div>문의 내용을 삭제하시겠습니까?</div>, 'primary', ()=>{
      deleteInquiry({inquiryUuid})
        .then((res)=>{
          if(res.data){
            navigate(ROUTES.ADMIN_INQUIRYLIST_F)
          }
        })
      })
  }
  return (
    <div className='adminContentSection'>
      <div className='adminListHeader' style={{marginBottom:'15px'}}>문의 상세</div>
      <div className='adminRegisterWrapper'>
        <div className='adminRegisterRowWrapper'>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>제목</div>
            <div className='adminRegisterInput'>{inquiryHeader}</div>
            </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>작성일</div>
            <div className='adminRegisterInput'>{createdDate}</div>
            </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>연락처</div>
            <div className='adminRegisterInput'>{phoneformatter(writerPhone)}</div>
            </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>이메일</div>
            <div className='adminRegisterInput'>{writerEmail}</div>
            </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>내용</div>
            <div className='adminRegisterInput'>{inquiryBody.split('<br />').map((c, id)=>{
              return (<div key={id}>{c}</div>)
          })}</div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>답변</div>
            <div className='adminRegisterInput'>{replyBody.split('<br />').map((c, id)=>{
              return (<div key={id}>{c}</div>)
          })}</div>
          </div>
        </div>
        <div className='adminExcelRegisterBtn'>
          <Button size='large' type='primary' onClick={()=>navigate(ROUTES.ADMIN_INQUIRYLIST_F)}>목록 보기</Button>
          <Button size='large' onClick={()=>deleteInquiryFunc()}>문의 삭제</Button>
        </div>
      </div>
    </div>
  )
}

export default InquiryItem