import { Button, Checkbox, Input, InputNumber, Modal, Select, Table } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, onlyNumberAndEnglish, removeDuplication, objectArrToStringArr, textareaDBToString, textareaStringToDB } from '../../../../constants/common';
import { alertMessage } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { getNotice, saveNotice } from '../../../../_actions/NoticeAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import TextArea from 'antd/es/input/TextArea';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

export interface DataType {
  noticeUuid : string;
  noticeHeader : string;
  adminUuid : string;
  noticeBody : string;
  noticeStatus1 : number;
  noticeStatus2 : number;
  noticeStatus3 : number;
  noticeStatus4 : number;
}

function Register(props:any) {
  // let {isEditing=false, userType, noticeUuid=""}=props;
  const navigate = useNavigate();
  const params = useParams();
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const [noticeUuid, setNoticeUuid] = useState("");
  const [noticeHeader, setNoticeHeader] = useState("");
  const [noticeBody, setNoticeBody] = useState("");
  const [noticeStatus1, setNoticeStatus1] = useState(0);
  const [noticeStatus2, setNoticeStatus2] = useState(0);
  const [noticeStatus3, setNoticeStatus3] = useState(0);
  const [noticeStatus4, setNoticeStatus4] = useState(0);
  const [noticeStatus, setNoticeStatus] = useState(1);
  const [noticeView, setNoticeView] = useState(0);
  const [teamUserList, setTeamUserList] = useState<any[]>([]);
  const [validBtn, setValidBtn] = useState(false);
  const [msg, setMsg] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  useEffect(()=>{
    if(params && params.detail){
      if(params.detail === 'create'){
        setIsEditing(false)
      } else if(params.detail.length>0) {
        setIsEditing(true)
        setNoticeUuid(params.detail)
      }
    }
  }, [params])
  const onChangeNoticeHeader = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setNoticeHeader(val);       
  };
  const onChangeNoticeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoticeBody(event.currentTarget.value);       
  };

  useEffect(()=>{
    if(isEditing && noticeUuid.length>0){
      getNotice({noticeUuid, adminUuid : adminData.adminUuid})
      .then((res)=>{
        if(res.data){
          // console.log(res.data)
          setNoticeHeader(res.data.noticeHeader)
          setNoticeBody(textareaDBToString(res.data.noticeBody))
          setNoticeView(res.data.noticeView)
          setNoticeStatus1(res.data.noticeStatus1)
          setNoticeStatus2(res.data.noticeStatus2)
          setNoticeStatus3(res.data.noticeStatus3)
          setNoticeStatus4(res.data.noticeStatus4)
        }
      })
    }
  }, [noticeUuid])
  useEffect(()=>{
    let valid = noticeHeader.length>0 && noticeBody.length>0 && (!!noticeStatus || (!!noticeStatus1 || !!noticeStatus2 || !!noticeStatus3 || !!noticeStatus4))
    setValidBtn(valid)
    setMsg("")
    if((!!noticeStatus1 && !!noticeStatus2 && !!noticeStatus3 && !!noticeStatus4)){
      setNoticeStatus(1);setNoticeStatus1(0);setNoticeStatus2(0);setNoticeStatus3(0);setNoticeStatus4(0);
    }
  }, [noticeHeader, noticeBody, noticeStatus1, noticeStatus2, noticeStatus3, noticeStatus4, noticeStatus])

// 교사는 직원 컨트롤 XXX
const submitHandler =() => {
  let data = {
    adminUuid : adminData.adminUuid,
    noticeUuid,
    noticeHeader,
    noticeBody : textareaStringToDB(noticeBody),
    noticeStatus1 : !!noticeStatus ? 1 : noticeStatus1,
    noticeStatus2 : !!noticeStatus ? 1 : noticeStatus2,
    noticeStatus3 : !!noticeStatus ? 1 : noticeStatus3,
    noticeStatus4 : !!noticeStatus ? 1 : noticeStatus4,
    noticeView,
  }
  if(!validBtn) return setMsg("* 제목과 내용을 입력해 주세요.")
  saveNotice(data)
  .then((res)=>{
    if(res.data) navigate(ROUTES.ADMIN_NOTICELIST_F)
  })
}
const onCheckFunc =  (e:CheckboxChangeEvent) => {
  if(e.target.name ==='noticeStatus'){ setNoticeStatus(1);setNoticeStatus1(0);setNoticeStatus2(0);setNoticeStatus3(0);setNoticeStatus4(0);}
  if(e.target.name ==='noticeStatus1'){ setNoticeStatus(0);setNoticeStatus1(noticeStatus1===1?0:1);}
  if(e.target.name ==='noticeStatus2'){ setNoticeStatus(0);setNoticeStatus2(noticeStatus2===1?0:1);}
  if(e.target.name ==='noticeStatus3'){ setNoticeStatus(0);setNoticeStatus3(noticeStatus3===1?0:1);}
  if(e.target.name ==='noticeStatus4'){ setNoticeStatus(0);setNoticeStatus4(noticeStatus4===1?0:1);}
}
useEffect(()=>{
  if(!(adminData.userType === userTypeStringToInt("직원") || adminData.userType === userTypeStringToInt("슈퍼"))){
    setNoticeStatus(0)
  }
}, [adminData.userType])
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>공지사항 {isEditing ? "- 수정 하기" : "- 등록 하기"}</div>
        <div>
      <div className='adminRegisterRow'>
        <div className='adminRegisterTitle'>제목</div>
        <Input type='text' value={noticeHeader} onChange={onChangeNoticeHeader} placeholder='' />
      </div>
      <div className='adminRegisterRow' style={{alignItems:'flex-start'}}>
        <div className='adminRegisterTitle'>내용</div>
        <TextArea value={noticeBody} onChange={onChangeNoticeBody} placeholder='' rows={10} style={{ resize: 'none' }} />
      </div>
      <div className='adminRegisterRow'>
        <div className='adminRegisterTitle'>공개 유형</div>
        <div className='adminRegisterInputF'>
          {(adminData.userType === userTypeStringToInt("직원") || adminData.userType === userTypeStringToInt("슈퍼")) && <><Checkbox onChange={onCheckFunc} checked={!!noticeStatus} name="noticeStatus" value={!!noticeStatus} /> 전체&nbsp;</>}
          <Checkbox onChange={onCheckFunc} checked={!!noticeStatus1} name="noticeStatus1" value={!!noticeStatus1} /> 학생&nbsp;
          <Checkbox onChange={onCheckFunc} checked={!!noticeStatus2} name="noticeStatus2" value={!!noticeStatus2} /> 학부모&nbsp;
          {(adminData.userType === userTypeStringToInt("직원") || adminData.userType === userTypeStringToInt("슈퍼")) && <><Checkbox onChange={onCheckFunc} checked={!!noticeStatus3} name="noticeStatus3" value={!!noticeStatus3} /> 직원&nbsp;</>}
          <Checkbox onChange={onCheckFunc} checked={!!noticeStatus4} name="noticeStatus4" value={!!noticeStatus4} /> 교사&nbsp;
        </div>
      </div>
      <br/><br/>
      <div className='adminExcelRegisterBtn'>
        <Button  size='large' onClick={() => navigate(ROUTES.ADMIN_NOTICELIST_F)}>취소</Button><Button  size='large' type="primary" onClick={()=>{submitHandler()}}>등록</Button>
      </div>
      <div style={{textAlign:'center'}}>{msg}</div>
    </div>
    </div>
  )
}

export default Register