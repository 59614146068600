import React, { useEffect, useState } from 'react'
import MypageHeader from './MypageHeader'
import { useParams, useSearchParams } from 'react-router-dom';
import { deleteInquiry, getInquiry } from '../../../../_actions/InquiryAction';
import moment from 'moment';
import { phoneformatter } from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { confirmDeleteMessage } from '../../../../Utils/Utils';
import { Button, Modal } from 'antd';

function InquiryItem(props:any) {
  let { selectedUuid="", isUser=true, setTab=()=>{console.log("setTab is null")} } = props;
  let navigate = useNavigate();
  const { id } = useParams()
  const inquiryIndex = id;
  const [createdDate, setCreatedDate] = useState("");
  const [inquiryHeader, setInquiryHeader] = useState("");
  const [replyBody, setReplyBody] = useState("");
  const [inquiryBody, setInquiryBody] = useState("");
  const [inquiryUuid, setInquiryUuid] = useState(selectedUuid);
  const [writerEmail, setWriterEmail] = useState("");
  const [writerPhone, setWriterPhone] = useState("");
  const dateFormat = 'YYYY-MM-DD hh:mm';
  const setTimeformat = (dateData:Date) => {return moment(dateData).format(dateFormat)};
  useEffect(()=>{
    console.log(id)
    getInquiry({inquiryIndex:Number(inquiryIndex) || -1, inquiryUuid:selectedUuid})
    .then((res)=>{
      if(res.data){
        if(res.data.createdDate) setCreatedDate(setTimeformat(res.data.createdDate))
        if(res.data.inquiryHeader) setInquiryHeader(res.data.inquiryHeader)
        if(res.data.inquiryBody) setInquiryBody(res.data.inquiryBody)
        if(res.data.replyBody) setReplyBody(res.data.replyBody)
        if(res.data.writerEmail) setWriterEmail(res.data.writerEmail)
        if(res.data.writerPhone) setWriterPhone(res.data.writerPhone)
        if(res.data.inquiryUuid) setInquiryUuid(res.data.inquiryUuid)
      }
    })
  }, [id, selectedUuid])
  const deleteInquiryFunc=()=>{
    return(
      Modal.confirm({
      content : (<div className='mainCampusModalContent'>문의 내용을 삭제하시겠습니까?</div>),
      okText:"삭제",
      cancelText:"취소",
      centered:true,
      className:"mainCampusModal",
      onOk() { 
        deleteInquiry({inquiryUuid})
        .then((res)=>{
          if(res.data){
            if(isUser) navigate(ROUTES.USER_INQUIRYLIST_F)
            else setTab(0)
          }
        })
      },
      onCancel(){

      }
    })
    )
  }
  return (
    <>
    {isUser && <MypageHeader page="inquiry"/>}
    <div className='mypageWrapper'>
      <div className='mypageInquiryWrapper'>
        <div className='mypageInquiryHeader'>Q. {inquiryHeader}</div>
        <div className='mypageInquiryInfoWrapper'>
          <div><span>작성일</span><span>{createdDate}</span></div> 
          <div><span>연락처</span><span>{phoneformatter(writerPhone)}</span></div>
          <div><span>이메일</span><span>{writerEmail}</span></div>
        </div>
        
        <div className='mypageInquiryBody'>{inquiryBody.split('<br />').map((c, id)=>{
            return (<div key={id}>{c}</div>)
        })}</div>
        <div className='mypageInquiryReply'><strong>운영자 </strong>{replyBody.split('<br />').map((c, id)=>{
            return (<div key={id}>{c}</div>)
        })}</div>
      </div>
      <div className='mypageInquiryBtnWrapper'>
        <Button onClick={()=>isUser ? navigate(ROUTES.USER_INQUIRYLIST_F) : setTab(0)}>목록 보기</Button>
        <Button onClick={()=>deleteInquiryFunc()}>문의 삭제</Button>
      </div>
      
    </div>
    </>
  )
}

export default InquiryItem 