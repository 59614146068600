import { Button, Checkbox, DatePicker, Input, InputNumber, Modal, Select, Switch, Table, TimePicker } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, onlyNumberAndEnglish, removeDuplication, objectArrToStringArr, textareaDBToString, textareaStringToDB } from '../../../../constants/common';
import { alertMessage } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { getCheck, saveCheck } from '../../../../_actions/SystemCheckAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;
dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { TextArea } = Input;

export interface DataType {
  systemCheckUuid : string;
  systemCheckHeader : string;
  adminUuid : string;
  systemCheckBody : string;
  systemCheckStatus : number;
}

function Register(props:any) {
  const navigate = useNavigate();
  const params = useParams();
  // let {isEditing=false, selectedcheckIndex=0}=props;
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const [selectedcheckIndex, setselectedcheckIndex] = useState(0);
  const [systemCheckHeader, setSystemCheckHeader] = useState("");
  const [systemCheckBody, setSystemCheckBody] = useState("");
  const [systemCheckStatus, setSystemCheckStatus] = useState(1);
  const [validBtn, setValidBtn] = useState(false);
  const [msg, setMsg] = useState("");
  const [openTime, setOpenTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  useEffect(()=>{
    if(params && params.detail){
        setselectedcheckIndex(Number(params.detail))
    }
  }, [params])

  const onChangeSystemCheckHeader = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setSystemCheckHeader(val);       
  };
  const onChangeSystemCheckBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSystemCheckBody(event.currentTarget.value);       
  };
  const onChangeOpenTime = (time: Dayjs|null, timeString: string) => {
    console.log(timeString)
      setOpenTime(timeString);
    };
  const onChangeEndTime = (time: Dayjs|null, timeString: string) => {
    setEndTime(timeString);
  };
  const format = 'HH:mm';
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current:Dayjs) => {
    // Can not select days before today and today
    return current < dayjs().add(-1, "day").endOf('day');
    // if (!dates) {
    //   return false;
    // }
    // const tooLate = dates[0] && current.diff(dates[0], 'month') >= 3;
    // const tooEarly = dates[1] && dates[1].diff(current, 'month') >= 3;
    // return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    }
  };
  // useEffect(()=>{
  //   if(isEditing && systemCheckUuid.length>0){
  //     getCheck({systemCheckUuid, adminUuid : adminData.adminUuid})
  //     .then((res)=>{
  //       if(res.data){
  //         console.log(res.data)
  //         setSystemCheckHeader(res.data.systemCheckHeader)
  //         setSystemCheckBody(textareaDBToString(res.data.systemCheckBody))

  //       }
  //     })
  //   }
  // }, [isEditing])
  useEffect(()=>{
    let valid = systemCheckHeader.length>0 && systemCheckBody.length>0 
    setValidBtn(valid)
    setMsg("")

  }, [systemCheckHeader, systemCheckBody])

const submitHandler =() => {
  if(openTime ==='' || endTime ==='') return alertMessage("시작/종료 시간을 지정해주세요.")
  if(!dates || !dates[0] || !dates[1]) return alertMessage("기간을 선택해주세요.")
  // if(dayjs(openTime, 'HH:mm').diff(dayjs(endTime, 'HH:mm'),'m')>0 ) return alertMessage("시작/종료 시간을 확인해주세요.")
  let startDateFormat = dates !== null && dates[0] !==null ? dates[0].format("YYYY-MM-DD") : "YYYY-MM-DD";
  let endDateFormat = dates !== null && dates[1] !==null ? dates[1].format("YYYY-MM-DD") : "YYYY-MM-DD";

  let data = {
    adminUuid : adminData.adminUuid,
    systemCheckIndex : selectedcheckIndex,
    systemCheckHeader,
    systemCheckBody : textareaStringToDB(systemCheckBody),
    systemCheckStatus,
    openTime,
    endTime,
    startDate : startDateFormat, 
    endDate : endDateFormat,
  }
  console.log(data)
  saveCheck(data)
  .then((res)=>{
    console.log(res.data)
    if(res.data) navigate(ROUTES.ADMIN_SYSTEM_F)
  })
}

  return (
    <div className='adminContentSection'>
    <div className='adminListHeader'>점검 관리</div>
      <div className='adminRegisterRowWrapperW'>
        <div className='adminRegisterRowWrapper'>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>제목</div>
            <div className='adminRegisterInput'><Input type='text' value={systemCheckHeader} onChange={onChangeSystemCheckHeader} placeholder='' /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>점검 시작 시간</div>
            <div className='adminRegisterInput'><TimePicker locale={locale} onChange={onChangeOpenTime} defaultValue={dayjs('00:00', 'HH:mm')} format={format} /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>점검 종료 시간</div>
            <div className='adminRegisterInput'><TimePicker locale={locale} onChange={onChangeEndTime} defaultValue={dayjs('00:00', 'HH:mm')} format={format} /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>점검 기간</div>
            <div className='adminRegisterInput'>
                <RangePicker 
                  locale={locale}
                  // disabled={readOnlyValue}
                  value={dates || value}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => setDates(val)}
                  onChange={(val) => setValue(val)}
                  onOpenChange={onOpenChange} /></div>
          </div>
        </div>
        <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>내용</div>
            <div className='adminRegisterInput'>
              <TextArea value={systemCheckBody} onChange={onChangeSystemCheckBody} placeholder='' style={{ resize: 'none' }} maxLength={100} />
            </div>
        </div>
        <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>활성 여부</div>
            <div className='adminRegisterInput'>
              <Switch checkedChildren="활성" unCheckedChildren="비활성" checked={!!systemCheckStatus} onChange={(val)=>{setSystemCheckStatus(val?1:0)}}/>
          </div>
        </div>
        <div className='adminExcelRegisterBtn'>
          <Button type="primary" onClick={()=>{submitHandler()}}>저장</Button>
        </div>
      </div>
    </div>
  )
}

export default Register