export const USER_COMMON = '/users';
export const USER_ALL = '/users/*';
export const USER_MAIN = '/main';
export const USER_MAIN_F = '/users/main';
export const USER_LOGIN = '/login';
export const USER_LOGIN_F = '/users/login';
export const USER_INITPW = '/init';
export const USER_INITPW_F = '/users/init';

export const USER_DOWNLOAD = '/download';
export const USER_DOWNLOAD_F = '/users/download';

export const USER_PROFILE = '/profile';
export const USER_PROFILE_F = '/users/profile';
export const USER_INQUIRYLIST = '/inquiryList';
export const USER_INQUIRYLIST_F = '/users/inquiryList';
export const USER_INQUIRYITEM = '/inquiryList/:id';
export const USER_INQUIRYITEM_F = '/users/inquiryList/:id';
export const USER_INQUIRY = '/inquiry';
export const USER_INQUIRY_F = '/users/inquiry';
export const USER_FAQ = '/faq';
export const USER_FAQ_F = '/users/faq';
export const USER_POLICY = '/terms';
export const USER_POLICY_F = '/users/terms';
export const USER_PRIVACY = '/terms_p';
export const USER_PRIVACY_F = '/users/terms_p';

export const ADMIN_FINDINFO = "/findAdminInfo" // 클라랑 맞춘 주소, 변경 금지
export const ADMIN_FINDINFO_F = "/admin/findAdminInfo" // 클라랑 맞춘 주소, 변경 금지
export const ADMIN_COMMON = '/admin';
export const ADMIN_ALL = '/admin/*';
export const ADMIN_LOGIN = '/login';
export const ADMIN_LOGIN_F = '/admin/login';
export const ADMIN_SIGNUP = '/signUp';
export const ADMIN_SIGNUP_F = '/admin/signUp';

export const ADMIN_MAIN = '/main';
export const ADMIN_MAIN_F = '/admin/main';

export const ADMIN_SCHOOL = '/school';
export const ADMIN_SCHOOL_F = '/admin/school';

export const ADMIN_INFO = '/myinfo';
export const ADMIN_INFO_F = '/admin/myinfo';
export const ADMIN_CHANGEPW = '/changePw';
export const ADMIN_CHANGEPW_F = '/admin/changePw';

export const ADMIN_STUDENT = '/student';
export const ADMIN_STUDENT_F = '/admin/student';
export const ADMIN_STUDENTITEM = '/student/:detail';
export const ADMIN_STUDENTITEM_F = '/admin/student/:detail';
export const ADMIN_STUDENTITEM_I = '/admin/student/';
export const ADMIN_STUDENTCREATE = '/student/register';
export const ADMIN_STUDENTCREATE_F = '/admin/student/register';
export const ADMIN_STUDENTCREATELIST = '/student/registerList';
export const ADMIN_STUDENTCREATELIST_F = '/admin/student/registerList';

export const ADMIN_PARENTS = '/parents';
export const ADMIN_PARENTS_F = '/admin/parents';
export const ADMIN_PARENTSITEM = '/parents/:detail';
export const ADMIN_PARENTSITEM_F = '/admin/parents/:detail';
export const ADMIN_PARENTSITEM_I = '/admin/parents/';
export const ADMIN_PARENTSCREATE = '/parents/register';
export const ADMIN_PARENTSCREATE_F = '/admin/parents/register';
export const ADMIN_PARENTSCREATELIST = '/parents/registerList';
export const ADMIN_PARENTSCREATELIST_F = '/admin/parents/registerList';

export const ADMIN_TEACHER = '/teacher';
export const ADMIN_TEACHER_F = '/admin/teacher';
export const ADMIN_TEACHERITEM = '/teacher/:detail';
export const ADMIN_TEACHERITEM_F = '/admin/teacher/:detail';
export const ADMIN_TEACHERITEM_I = '/admin/teacher/';
export const ADMIN_TEACHERCREATE = '/teacher/register';
export const ADMIN_TEACHERCREATE_F = '/admin/teacher/register';

export const ADMIN_STAFF = '/staff';
export const ADMIN_STAFF_F = '/admin/staff';
export const ADMIN_STAFFITEM = '/staff/:detail';
export const ADMIN_STAFFITEM_F = '/admin/staff/:detail';
export const ADMIN_STAFFITEM_I = '/admin/staff/';
export const ADMIN_STAFFCREATE = '/staff/register';
export const ADMIN_STAFFCREATE_F = '/admin/staff/register';

export const ADMIN_CHANNELLIST = '/channel';
export const ADMIN_CHANNELLIST_F = '/admin/channel';
export const ADMIN_CHANNELITEM = '/channel/:detail';
export const ADMIN_CHANNELITEM_F = '/admin/channel/:detail';
export const ADMIN_CHANNELITEM_I = '/admin/channel/';
export const ADMIN_CHANNELCREATE = '/channel/create';
export const ADMIN_CHANNELCREATE_F = '/admin/channel/create';

export const ADMIN_TEAMLIST = '/team';
export const ADMIN_TEAMLIST_F = '/admin/team';
export const ADMIN_TEAMITEM = '/team/:detail';
export const ADMIN_TEAMITEM_F = '/admin/team/:detail';
export const ADMIN_TEAMITEM_I = '/admin/team/';
export const ADMIN_TEAMCREATE = '/team/create';
export const ADMIN_TEAMCREATE_F = '/admin/team/create';


export const ADMIN_NOTICELIST = '/notice';
export const ADMIN_NOTICELIST_F = '/admin/notice';
export const ADMIN_NOTICEITEM = '/notice/:detail';
export const ADMIN_NOTICEITEM_F = '/admin/notice/:detail';
export const ADMIN_NOTICECREATE = '/notice/create';
export const ADMIN_NOTICECREATE_F = '/admin/notice/create';

export const ADMIN_INQUIRYLIST = '/inquiry';
export const ADMIN_INQUIRYLIST_F = '/admin/inquiry';
export const ADMIN_INQUIRYITEM = '/inquiry/:detail';
export const ADMIN_INQUIRYITEM_F = '/admin/inquiry/:detail';
export const ADMIN_INQUIRYCREATE = '/inquiry/:detail';
export const ADMIN_INQUIRYCREATE_F = '/admin/inquiry/:detail';
export const ADMIN_INQUIRYREPLY = '/inquiry/reply/:detail';
export const ADMIN_INQUIRYREPLY_F = '/admin/inquiry/reply/:detail';


export const ADMIN_FAQ = '/faq';
export const ADMIN_FAQ_F = '/admin/faq';
export const ADMIN_SYSTEM = '/system';
export const ADMIN_SYSTEM_F = '/admin/system';
export const ADMIN_SYSTEMCREATE = '/system/register';
export const ADMIN_SYSTEMCREATE_F = '/admin/system/register';

export const ADMIN_APPVERSION = '/appVersion';
export const ADMIN_APPVERSION_F = '/admin/appVersion';
export const ADMIN_TERMS = '/terms';
export const ADMIN_TERMS_F = '/admin/terms';
export const ADMIN_TERMSITEM = '/terms/:detail';
export const ADMIN_TERMSITEM_F = '/admin/terms/:detail';
export const ADMIN_TERMSCREATE = '/terms/register';
export const ADMIN_TERMSCREATE_F = '/admin/terms/register';