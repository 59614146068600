import { Button, Input, InputNumber, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, getAdmissionYearList, getUserAccount } from '../../../../constants/common';
import { createUserList } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { alertMessage, confirmMessage, infoMessageTitle } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

function Register(props:any) {
  const navigate = useNavigate();
  let {isEditing=false}=props;
  const {schoolInfo:{schoolUuid} } = useSelector((state : RootState) => state.adminReducer);
  const [userName, setUserName] = useState("");
  const [yearList, setYearList] = useState<String[]>([]);
  const [admissionYear, setAdmissionYear] = useState("");
  const [grade, setGrade] = useState(0);
  const [gradeClass, setGradeClass] = useState(0);
  const [gradeNumber, setGradeNumber] = useState(0);
  
  const [msg, setMsg] = useState("");
  const onChangeUserName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setUserName(trimAndNoSpecial(val));       
};
  useEffect(()=>{
    let temp = getAdmissionYearList()
    setYearList(temp);
    // console.log(temp)
},[])

const onChangeGrade =(event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
  let val:number = Number(event.currentTarget.value);
  if(val>0 && val<=6) setGrade(val);       
  };
const onChangeGradeClass = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
  let val:number = Number(event.currentTarget.value);
  if(val>0) setGradeClass(val);
  };
  const onChangeGradeNumber = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:number = Number(event.currentTarget.value);
    if(val>0) setGradeNumber(val);
    };
  const submitHandler=()=>{
    let account = getUserAccount(schoolUuid, admissionYear, grade, gradeClass, gradeNumber)
    let data = {
      userAccount :  account,
      admissionYear,
      grade : Number(grade),
      gradeClass : Number(gradeClass),
      gradeNumber : Number(gradeNumber),
      userName,
      userType: userTypeStringToInt('학생'),
      schoolUuid,
      kidsName : ""
    }
    if(
      !isEditing&&
    (  userName.length ===0 ||!account || admissionYear.length ===0 || grade ===0 || gradeClass ===0 || gradeNumber ===0)) return setMsg("입력필드를 확인해 주세요.")
    createUserList([data])
    .then((res)=>{
        if(res.data.length===0){
          Modal.info({
            title: '등록 완료되었습니다.',
            content: (
                <div>
                    <div>이름 : {userName}</div>
                    <div>아이디 : {account}</div>
                </div>
            ),
            onOk() {
              navigate(ROUTES.ADMIN_STUDENT_F)
            },
            centered:true
        })
        } else {
          infoMessageTitle("데이터 중복", <div>중복된 데이터 입니다.<br/><br/>
          이름 : {userName} <br/>입학연도 : {admissionYear} 학년 : {grade} 반 : {gradeClass} 번호 : {gradeNumber}
          <br/><br/>입력된 정보를 확인해 주세요.
          </div>, ()=>console.log('확인'))
            }
    })
    .catch((err)=>{
      console.log(err);
      if(err.response.data) alertMessage(err.response.data)
    })
} 
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학생 관리 - 등록 하기</div>
      <div className='adminRegisterWrapper'>
        <div className='adminRegisterRowWrapper'>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>이름</div>
            <div className='adminRegisterInput'><Input size='large' type='text' value={userName} onChange={onChangeUserName} placeholder='이름을 입력해 주세요.'/></div>
          </div>
          {isEditing ?
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>아이디</div>
            {/* <div>{account}</div> */}
          </div>:
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>아이디</div>
            <div>{
            (admissionYear!==''&&grade>0&&gradeClass>0&&gradeNumber>0) ?
            getUserAccount(schoolUuid, admissionYear, grade, gradeClass, gradeNumber) : ' 하단에 기입한 입학정보로 아이디가 자동 생성됩니다.'}</div>
          </div>
        }
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>입학 정보</div>
            <div></div>
          </div>
          
        </div>
        <div>
          <div className='adminGradeInfoRow'>
              <div>
                <div>학교코드</div>
                <div className='adminSchoolUuidRow'>{schoolUuid}</div>
              </div>
              <div className='adminAdmissionRow'>
                <div className='adminGradeInfoRowTitle'>입학 년도</div>
                <Select onChange={setAdmissionYear} value={admissionYear} defaultValue={""}>
                  <Option value= "">선택해 주세요.</Option>
                  {yearList.length>0 && yearList.map((c,i)=>{
                      return(
                          <Option value={c} key={i}>{c}</Option>
                      )
                  })}
                </Select>
              </div>
              <div>
                <div className='adminGradeInfoRowTitle'>학년</div>
                <Select value={grade} onChange={(val)=>setGrade(val)}>
                    <Option value={0}>학년 입력</Option>
                    {Array.from({length: 6}, (_, i) => i + 1).map((c,id)=>{
                        return(<Option key={c} value={c}>{c}</Option>)
                    })}
                </Select>
              </div>
              <div>
                <div className='adminGradeInfoRowTitle'>반</div>
                <Select value={gradeClass} onChange={(val)=>setGradeClass(val)}>
                    <Option value={0}>반 입력</Option>
                    {Array.from({length: 20}, (_, i) => i + 1).map((c,id)=>{
                        return(<Option key={c} value={c}>{c}</Option>)
                    })}
                </Select>
              </div>
              <div>
                <div className='adminGradeInfoRowTitle'>번호</div>
                <Select value={gradeNumber} onChange={(val)=>setGradeNumber(val)}>
                    <Option value={0}>번호 입력</Option>
                    {Array.from({length: 40}, (_, i) => i + 1).map((c,id)=>{
                        return(<Option key={c} value={c}>{c}</Option>)
                    })}
              </Select>
            </div> 
        </div>
        <br/><br/>
        <div className='adminExcelRegisterBtn'>
          <Button type='primary' size='large' onClick={submitHandler}>등록</Button>
        </div>
          <div style={{textAlign:'center'}}>{msg}</div>
        </div>
      </div>
    </div>
  )
}

export default Register