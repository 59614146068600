import { compose  } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import persistReducer, { rootReducer } from "./_reducers";
import ReduxThunk from "redux-thunk";
// import promiseMiddleware from 'redux-promise';
import { persistStore } from "redux-persist";


declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(promiseMiddleware, ReduxThunk)));

const store = configureStore({
                  reducer: persistReducer,
                  middleware: [ReduxThunk],
                });


export const persistor = persistStore(store)
export type RootReducerType = ReturnType<typeof rootReducer>

export default store;



