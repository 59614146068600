import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { alertMessage } from '../../../../Utils/Utils';
import './css/UserDownload.css';
import downloadBtn from './css/images/downloadBtn.png';
import downloadIcon from './css/images/file_download.png'
import downloadIcon2 from './css/images/file_download2.png'
import { DownOutlined } from '@ant-design/icons';

const downLoadColumns = [
  {
      title: '항목',
      dataIndex: 'title',
      key: 'title',
      width:'20%'
  },
  {
      title: '최소 사양',
      dataIndex: 'minimum',
      key: 'minimum',
      width:'40%'
  },
  {
      title: '권장 사양',
      dataIndex: 'recommend',
      key: 'recommend',
      width:'40%'
  }
  ];
export const downLoadDataSource = [
  {
      key: '1',
      title: '운영체재',
      minimum: "Windows 10",
      recommend : "Windows 10"
    },
    {
      key: '2',
      title: 'CPU',
      minimum: "intel i5 이상",
      recommend : "intel i5 이상"
    },
    {
      key: '3',
      title: '그래픽카드',
      minimum: "Geforce GTX 660 이상",
      recommend : "Geforce GTX 660 이상"
    }
];
function Downloadpage() {
  const {userData} = useSelector((state : RootState) => state.userReducer);
    
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const downloadAppFunc = () => {
    try{
      if(!userData.userUuid || userData.userUuid.length<1) return alertMessage("유효한 접근이 아닙니다.");
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/api/user/download?userUuid=${userData.userUuid}`;
        link.setAttribute('download', 'MetaCampus.exe');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    } catch (e) {
        console.log(e)
    }
}
const downloadGuideFunc2 = () => {
  try{
    if(!userData.userUuid || userData.userUuid.length<1) return alertMessage("유효한 접근이 아닙니다.");
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/api/user/guideBook?userUuid=${userData.userUuid}`;
      link.setAttribute('download', 'MetaCampusUserGuide.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 
  } catch (e) {
      console.log(e)
  }
}
  return (
    <div className='downloadPage'>
      <div className='downloadWrapper1'>
        <div className='downloadTitle'>다운로드/설치</div>
        <div className='downloadGuideText'>메타버스 다운로드/설치 안내</div>
      </div>
      <div className='downloadwrapper2'>
        <div className='downloadBtnWrapper pointer'><div className='downloadBtn' onClick={()=>downloadAppFunc()}><div>인천메타캠퍼스 설치</div><img src={downloadIcon}/></div></div>
        <div className='downloadGuideText2'>PC 사양을 먼저 확인해 주세요!</div>
        <div className='downloadTableWrapper'>
          <div className='downloadGuideText3'><DownOutlined style={{fontSize:'13px'}} /> 최소사양 & 권장사양 안내</div>
          < Table showSorterTooltip={false} className='downloadtable' dataSource={downLoadDataSource} columns={downLoadColumns} pagination={false}/>
        </div>
        <div className='downloadBtnWrapper2 pointer'><div className='downloadBtn2' onClick={()=>downloadGuideFunc2()}><div>사용자 가이드북 다운로드</div><img src={downloadIcon2}/></div></div>
      </div>
    </div>
  )
}

export default Downloadpage