import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table, Input,Select, Tabs } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import ChannelItem from './ChannelItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { userTypeStringToInt } from '../../../../constants/auth';
import { deleteChannel, getChannelList } from '../../../../_actions/ChannelAction';
import { confirmMessage } from '../../../../Utils/Utils';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

export interface DataType {
  channelIndex:number; 
  channelUuid:string;
  channelHeader: string;
  eventLimit : number;
  adminName : string;
}
const eventLocationArr = ["", "메인광장", "학교", "이벤트홀", "인천교육청"]
function ChannelList() {
  const {state} = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const [eventLocation, setEventLocation] = useState<number|null>(state.type ==="eventLocation" ? state.data : adminData && adminData.userType === userTypeStringToInt("직원") ? 4 : 1);
  useEffect(()=>{
    if(state !==null && state.type ==="eventLocation"){
      setEventLocation(state.data)
      getChannelListByEventLocation(state.data)
    }
  }, [state])
  const [channelList, setChannelList] = useState([]);
  const [channelListSaved, setChannelListSaved] = useState([]);
  const [selectedlist, setSelectedlist] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchName, setSearchName] = useState("");
  useEffect(()=>{
      if(eventLocation!==null) getChannelListByEventLocation(eventLocation)
  },[eventLocation])
  const columns: ColumnsType<DataType> = [
      {
          title: '번호',
          dataIndex: 'index',
          render: (text, row, index) => {
                  return(<div>{(currentPage-1)*10+(index+1)}</div>)
              }
      },
      {
          title: '채널이름',
          dataIndex: 'channelHeader',
          sorter: {
              compare: (a, b) => a.channelHeader.localeCompare(b.channelHeader),
          }
      },
      {
          title: '참여인원',
          dataIndex: 'eventLimit',
          sorter: {
              compare: (a, b) => a.eventLimit - b.eventLimit,
          }
      },
      {
          title: '담당자',
          dataIndex: 'adminName',
          sorter: {
              compare: (a, b) => a.adminName.localeCompare(b.adminName),
          },
      }
  ];
  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRowKeys)
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    }),
};
const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
  let val:string = event.currentTarget.value.toString();
  setSearchName(val)
}
const searchHandler = () => {
  let temp = channelListSaved.slice()
  if(searchName !== "" && searchCategory==='channelHeader') temp = temp.filter((c:DataType) => c.channelHeader.includes(searchName))
  if(searchName !== "" && searchCategory==='eventLimit') temp = temp.filter((c:DataType) => c.eventLimit.toString().includes(searchName))
  if(searchName !== "" && searchCategory==='adminName') temp = temp.filter((c:DataType) => c.adminName.includes(searchName))
  if(searchName !== "" && searchCategory==='') temp = temp.filter((c:DataType) => c.channelHeader.includes(searchName) || c.eventLimit.toString().includes(searchName) || c.adminName.includes(searchName))
  setChannelList(temp)
}
const getChannelListByEventLocation = (eventLocationNum:number) => {
  getChannelList({eventLocation : eventLocationNum, adminUuid : adminData.adminUuid})
  .then((res)=>{
    setChannelList(res.data)
    setChannelListSaved(res.data)
  })
  .catch((err)=>{
    if(err.response.data) alert(err.response.data)
  })
}
const deleteChannelByUser = () => {
  confirmMessage("채널 삭제", <div>정말로 삭제 하시겠습니까?<br/>삭제하시면 데이터복구 불가능합니다.</div>, 'primary', ()=>{
    deleteChannel(selectedlist)
    .then((res)=>{
      if(res.data && eventLocation !== null) getChannelListByEventLocation(eventLocation)
    })
})
  
}
const [currentPage, setcurrentPage] = useState(1);
const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setcurrentPage(pagination.current || 1);
    console.log('params', pagination, filters, sorter, extra);
};
  return (
    <div className='adminContentSection'>
    <div className='adminListHeader'>채널 관리</div>
        <div>
          <div className='adminSearcWrapper'>
            <div className='adminSearcSection'>
              <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                    <Option value= "">전체</Option>
                    <Option value= "channelHeader">채널명</Option>
                    <Option value= "eventLimit">참여인원</Option>
                    {/* <Option value= "">유형</Option> */}
                    <Option value= "adminName">담당자</Option>
                </Select>
              <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}/>
              <Button onClick={()=>searchHandler()}>검색</Button>
            </div>
            <div className='adminBtnSection'>
              <Button onClick={()=>deleteChannelByUser()}>삭제</Button>
              <Button type='primary' onClick={()=>navigate(ROUTES.ADMIN_CHANNELCREATE_F, {state: {page:"", type:'eventLocation', data: eventLocation}})}>채널 생성</Button>
            </div>
          </div>
          <div>
            <Tabs size='large' activeKey={`${eventLocation}`} items={
              adminData.userType === userTypeStringToInt("직원")?
            [
              {key: '4', label: `${eventLocationArr[4]}`}
            ]
            : adminData.userType === userTypeStringToInt("교사")?
            [
              {key: '1', label: `${eventLocationArr[1]}`},
              {key: '2', label: `${eventLocationArr[2]}`},
              {key: '3', label: `${eventLocationArr[3]}`}
            ]
            : adminData.userType === userTypeStringToInt("슈퍼")?
            [
              {key: '1', label: `${eventLocationArr[1]}`},
              {key: '2', label: `${eventLocationArr[2]}`},
              {key: '3', label: `${eventLocationArr[3]}`},
              {key: '4', label: `${eventLocationArr[4]}`}
            ]
            :[{key: '0', label: '오류'}]
          } onChange={(key)=>setEventLocation(Number(key))} />
          </div>
          < Table showSorterTooltip={false} columns={columns} dataSource={channelList} tableLayout={"auto"}
            rowKey={(render)=>render.channelUuid}
            onChange={onCurrentPageChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {navigate(ROUTES.ADMIN_CHANNELITEM_I+record.channelUuid, {state: {page:"", type:'eventLocation', data: eventLocation}})}, // click row
              };
            }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}/>
        </div>
      </div>
    )
  }
export default ChannelList

