
import { useSelector } from 'react-redux';
import { RootState } from '../../../_reducers/index';

import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import {Layout} from 'antd';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../constants/router.js'
import { useEffect } from 'react';
import './AdminNav.css'

const {Header} = Layout;
const AdminNav = (props:any) => {
  let navigate = useNavigate();
  let { isNavShowing=true, sidebarHandler } = props;  
  const {adminData, schoolInfo, loginStatus} = useSelector((state : RootState) => state.adminReducer);
  useEffect(()=>{
    if(schoolInfo.schoolUuid==='' && adminData.userType!==5) navigate(ROUTES.ADMIN_SCHOOL_F)
  }, [schoolInfo])
  return (
    <>
      {isNavShowing && 
      <Header className='adminNav'>
          {loginStatus ? 
          <div>
              <MenuOutlined style={{fontSize:'20px', marginRight:'20px'}} onClick={sidebarHandler}/>
              <div className='adminNavSchoolName'>{schoolInfo.schoolName}</div>
          </div>
          :
          <div>
          </div>
          }
      </Header>}  
    </>
  )
}
export default AdminNav
