import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { getInquiry, saveReply } from '../../../../_actions/InquiryAction';
import moment from 'moment';
import { phoneformatter, textareaStringToDB } from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { Button, Input } from 'antd';
import { alertMessage } from '../../../../Utils/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
const { TextArea } = Input;

function InquiryReply(props:any) {
  // let { selectedUuid } = props;
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const navigate = useNavigate();
  const params = useParams();
  const [inquiryUuid, setInquiryUuid] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [inquiryHeader, setInquiryHeader] = useState("");
  const [inquiryBody, setInquiryBody] = useState("");
  const [writerEmail, setWriterEmail] = useState("");
  const [writerPhone, setWriterPhone] = useState("");
  const [replyBody, setReplyBody] = useState("");
  const [replyStatus, setReplyStatus] = useState(0);
  const dateFormat = 'YYYY-MM-DD hh:mm';
  const setTimeformat = (dateData:Date) => {return moment(dateData).format(dateFormat)};
  useEffect(()=>{
    if(params && params.detail){
      setInquiryUuid(params.detail)
    }
  }, [params])

  useEffect(()=>{
    if(inquiryUuid.length>0){
    getInquiry({inquiryUuid, inquiryIndex:0})
    .then((res)=>{
      if(res.data){
        if(res.data.createdDate) setCreatedDate(setTimeformat(res.data.createdDate))
        if(res.data.inquiryHeader) setInquiryHeader(res.data.inquiryHeader)
        if(res.data.inquiryBody) setInquiryBody(res.data.inquiryBody)
        if(res.data.writerEmail) setWriterEmail(res.data.writerEmail)
        if(res.data.writerPhone) setWriterPhone(res.data.writerPhone)
        if(res.data.replyBody){ setReplyBody(res.data.replyBody); setReplyStatus(res.data.replyBody.length); }
      }
    })}
  }, [inquiryUuid])
  const onChangeReplyBody = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    if(val.length>1000) return false;
    setReplyBody(val);       
};
const saveReplyFunc=()=>{
  if(replyBody.length>0 && inquiryUuid.length>0){
    let data = {
      inquiryUuid,
      replyBody: textareaStringToDB(replyBody),
      adminUuid : adminData.adminUuid
    };
    saveReply(data)
    .then((res)=>{
      if(res.data) setReplyStatus(1)
    })
    .catch((err)=>{
      if(err.response.data) alertMessage(err.response.data)
    })
    
  }
}
  return (
    <div className='adminContentSection'>
      <div className='adminListHeader' style={{marginBottom:'15px'}}>문의 답변</div>
      <div className='adminRegisterWrapper'>
        <div className='adminRegisterRowWrapper' style={{width:'900px'}}>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>제목</div>
            <div className='adminRegisterInput'>{inquiryHeader}</div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>작성일</div>
            <div className='adminRegisterInput'>{createdDate}</div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>연락처</div>
            <div className='adminRegisterInput'>{phoneformatter(writerPhone)}</div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>이메일</div>
            <div className='adminRegisterInput'>{writerEmail}</div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>내용</div>
            <div className='adminRegisterInput'>{inquiryBody.split('<br />').map((c, id)=>{
                return (<div key={id}>{c}</div>)
            })}</div>
          </div>
          <div className='adminRegisterRow'>
              {!!replyStatus ?
              <>
                <div className='adminRegisterTitle'>답변</div>
                <div className='adminRegisterInput'>
                  {replyBody.split('<br />').map((c, id)=>{
                      return (<div key={id}>{c}</div>)
                  })}
                </div>
              </>
            :
            <>
                <div className='adminRegisterTitle'>답변</div>
                <div className='adminRegisterInput' style={{width:"100%"}}>
                  <TextArea rows={5} value={replyBody} onChange={onChangeReplyBody}
                  style={{ resize: 'none'}}/>                
                </div>
            </>
            }
          </div>
          </div>
          {!replyStatus && 
          <div className='adminExcelRegisterBtn'>
            <Button  size='large' onClick={() => props.setTab(0)}>취소</Button>
            <Button size='large' type="primary" onClick={saveReplyFunc}>전송</Button>
          </div>}
        </div>
      </div>
  )
}

export default InquiryReply