import React, { useEffect, useRef, useState } from 'react'
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import * as xlsx from 'xlsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { trimAndNoSpecial, getUserAccount, numberToString, onlyNumberAndEnglish } from '../../../../constants/common';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {DataType} from './ParentsList'
import { createUserList } from '../../../../_actions/AdminAction';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

function RegisterByExcel(props:any) {
    const navigate = useNavigate();
    const {schoolInfo:{schoolUuid}, adminData} = useSelector((state : RootState) => state.adminReducer);
    const fileInput = useRef<HTMLInputElement>(null);
    const uploadTriggerFunc = () => {
        setMsg("")
        if(fileInput.current !== null)fileInput.current.value = "";
        fileInput.current?.click();
    }
    const [msg, setMsg] = useState("");
    const [parentsList, setParentsList] = useState([]);
    const [selectedlist, setSelectedlist] = useState([]);
    const [isSelected, setIsSelected] = useState(true);
    const [savedList, setSavedList] = useState([]);
    const [isFailed, setIsFailed] = useState(0);
    
    const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        var file = e.target.files !==null ? e.target.files[0] : false;
        if(!!!file) return alertMessage("파일이 없습니다.")
        let fileLeng = file.name.length;
        let fileDot = file.name.lastIndexOf(".");
        var fileType = file.name.substring(fileDot+1, fileLeng).toLowerCase();
        // var fileType = e.target.files !==null ? e.target.files[0].type : '';
        console.log(fileType)
        if(fileType !== "xlsx" // 타입 체크 필요
        //  ||fileType !== "application/vnd.ms-excel"
         ){
            setMsg("지원되지 않는 파일 형식입니다.")
            return;
        }
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if(e.target === null) return false;
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                jsonToDataArr(json)
                // exportData(json); // excel to json 개발자용
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    const exportData = (data:any) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(data)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";
    
        link.click();
      };
    const jsonToDataArr = (json:any)=>{
        let tempArr:any=[]
        if(json.length<0) return;
        for(let i=0; i<json.length; i++){
            if(json[i] === undefined
                || json[i]['이름'] === undefined
                || json[i]['자녀'] === undefined
                || json[i]['아이디'] === undefined
            ){
                return alert("알맞은 양식을 업로드해주세요.")
            } else if( !onlyNumberAndEnglish(json[i]['아이디'])){
                return alert("아이디는 영문, 숫자 조합만 가능합니다.")
            }
            tempArr.push({
                userAccount : `${json[i]['아이디']}`,
                admissionYear :  ``,
                grade :  0,
                gradeClass :  0,
                gradeNumber :  0,
                userName :  trimAndNoSpecial(`${json[i]['이름']}`),
                schoolUuid :  schoolUuid,
                userType : 2,
                kidsName : `${json[i]['자녀']}`
            })
        }
        // 서버에 키값 체크?
        setParentsList(tempArr)
        // return json;
    }
    const columns: ColumnsType<DataType> = [
        {
            title: '',
            dataIndex: 'key',
        },
        {
            title: '이름',
            dataIndex: 'userName',
        },
        {
            title: '자녀',
            dataIndex: 'kidsName',
        },
        {
            title: '아이디',
            dataIndex: 'userAccount',
        }
      ];
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedlist(selectedRows)
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: DataType) => ({
        //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //   name: record.name,
        }),
    };
    const deleteStudent =()=>{
        confirmMessage("학생 정보 삭제",<div><div>정말로 삭제 하시겠습니까?</div><div>삭제하시면 데이터복구 불가능합니다.</div></div>, "primary", async ()=>{
            let temp =parentsList.slice()
            for(let i=0; i< selectedlist.length; i++){
                let idx = temp.indexOf(selectedlist[i]);
                console.log(idx)
                temp.splice(idx,1)
            }
            setParentsList(temp)
        })
        
    }
    const submitHandler=()=>{
        createUserList(parentsList)
        .then((res)=>{
            setIsSelected(false)
                setIsFailed(res.data.length)
                let temp = parentsList.slice()
                temp = temp.filter((c:any)=> res.data.includes(c.userAccount))
                setSavedList(temp)
        })
        .catch((err)=>{
            if(err.response.data) alertMessage(err.response.data)
        })
    }
    useEffect(()=>{
        if(parentsList.length>0){
            let temp = parentsList.filter((character:any, idx, arr)=>{
                return arr.findIndex((item:any) => item.userAccount === character.userAccount) === idx
            });
            if(temp.length !== parentsList.length){
                alertMessage("중복된 아이디가 있습니다.")
                props.pageHandler("")
                }
        }
    }, [parentsList])
    const downloadFunc = () => {
        try{
            if(!adminData.adminUuid || adminData.adminUuid.length<1) return alertMessage("유효한 접근이 아닙니다.");;
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/download?adminUuid=${adminData.adminUuid}&type=parents`;
            link.setAttribute('download', '학부모등록양식(첫행고정).xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); 
        } catch (e) {
            console.log(e)
        }
    }
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학부모 관리 - 일괄 등록</div>
        <div className='adminExcelInfo'>
        <input type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{visibility:'hidden', position:'absolute'}}
        ref={fileInput} onChange={readUploadFile}/>
        <div >엑셀을 업로드하여 다수의 학생 정보를 한번에 입력할 수 있습니다.<br/>
        정해진 엑셀 양식에 입력하여 업로드 하세요.( 지원하는 파일 양식 : xlsx )
            <span onClick={()=>downloadFunc()}><strong><u>엑셀 양식 다운로드</u></strong></span></div>
            <Button size='large' type='primary' onClick={uploadTriggerFunc} disabled={selectedlist.length>0}>업로드</Button>
            <div className='adminExelMsg'>{msg}</div>
        </div>
        {parentsList.length>0 && 
        <>{isSelected ?
        <div>
            <div className='adminExcelBtnWrapper'>
                <div>등록 학생 수:{parentsList.length}명</div>
                <button disabled={selectedlist.length<1} onClick={()=>deleteStudent()}>삭제</button>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={parentsList} rowKey={(render)=>render.userAccount}
            tableLayout={"auto"}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}
            pagination={{ position: ['bottomCenter'] }}/>
            <div className='adminExcelRegisterBtn' >
                <Button type='primary' size='large' onClick={()=>submitHandler()}>등록</Button>
            </div>
        </div>
        :
        <div>
            <div className='adminExcelRegisterResult'>
                <div>일괄 등록 되었습니다.</div>
                <div>등록 학생 수:{parentsList.length - isFailed}명</div>
            </div>
            <div className='adminExcelFail'>등록 실패 학생 수 : <span style={isFailed>0 ?{color:'red'} : {}}>{isFailed}</span>명</div>
            < Table showSorterTooltip={false} columns={columns} dataSource={savedList} rowKey={(render)=>render.userAccount}
            pagination={{ position: ['bottomCenter'] }}/>
            <div className='adminExcelRegisterBtn'>
                <Button type='primary' size='large' onClick={()=>navigate(ROUTES.ADMIN_PARENTS_F)}>확인</Button>
            </div>
        </div>
        }
        </>}
        
    </div>
  )
}

export default RegisterByExcel