import axios from 'axios';

export async function saveChannel(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveChannel`, dataToSubmit);
}

export async function getChannelList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getChannelList`, dataToSubmit);
}
export async function deleteChannel(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteChannel`, dataToSubmit);
}
export async function getChannel(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getChannel`, dataToSubmit);
}
export async function searchChannelList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/searchChannelList`, dataToSubmit);
}
export async function searchTeamUserList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/searchTeamUserList`, dataToSubmit);
}
export async function saveTeam(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/saveTeam`, dataToSubmit);
}
export async function getTeamList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getTeamList`, dataToSubmit);
}
export async function getTeam(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getTeam`, dataToSubmit);
}
export async function deleteTeamList(dataToSubmit:any) {
    return await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/deleteTeamList`, dataToSubmit);
}