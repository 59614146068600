import { Button, Table } from "antd";
import Faq from "../../../user/pages/mypage/Faq";
import { getNoticeList } from "../../../../_actions/NoticeAction";
import { alertMessage } from "../../../../Utils/Utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../../../constants/router';
import { getInquiryList } from "../../../../_actions/InquiryAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../_reducers";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";
import { downLoadDataSource } from "../../../user/pages/downloadpage/Downloadpage";
export interface noticeType {
  noticeUuid:string;
  noticeIndex:number;
  adminName: string;
  noticeHeader : string;
  noticeStatus1 : number;
  noticeStatus2 : number;
  noticeStatus3 : number;
  noticeStatus4 : number;
  schoolName : string;
  noticeView:number;
  createdDate:string;
}
interface inquiryType {
  inquiryUuid : string;
  inquiryHeader: string;
  inquiryIndex: number;
  inquiryCategory : string;
  writerName: string;
  createdDate: string;
  inquiryStatus: number;
}
const noticeColumns = [
{
    title: '제목',
    dataIndex: 'noticeHeader',
    key: 'noticeHeader',
    width:'60%',
    ellipsis: true,
},
{
    title: '날짜',
    dataIndex: 'createdDate',
    key: 'createdDate',
}
];

const inquiryDataSource = [
    {
        key: '1',
        inquiryHeader: '',
        createdDate: null
      },
      {
        key: '2',
        inquiryHeader: '',
        createdDate: null
      },
      {
        key: '3',
        inquiryHeader: '',
        createdDate: null
      }
  ];
const inquiryColumns: ColumnsType<inquiryType> = [
{
    title: '제목',
    dataIndex: 'inquiryHeader',
    key: 'inquiryHeader',
    width:'60%',
    ellipsis: true,
},
{
    title: '날짜',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (text, record) => {
      let dayjsFormat = dayjs(text).format('YYYY-MM-DD');
    return(<div>{dayjsFormat}</div>)},
}
];

const downLoadColumns = [
{
    title: '항목',
    dataIndex: 'title',
    key: 'title',
},
{
    title: '최소 사양',
    dataIndex: 'minimum',
    key: 'minimum',
},
{
    title: '권장 사양',
    dataIndex: 'recommend',
    key: 'recommend',
}
];
export default function Main() {
  const navigate = useNavigate();
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  useEffect(()=>{
    getNoticeListFunc()
    getInquiryListFunc()
  }, [])
  const [noticeList, setNoticeList] = useState<noticeType[]>([]);
  const [inquiryList, setInquiryList] = useState<inquiryType[]>([]);
  const getNoticeListFunc = () => {
    getNoticeList({userType : 5, limit : 3}) // 관리자 페이지에서는 전체 열람 가능
    .then((res)=>{
        if(res.data){
            let temp = res.data;
            setNoticeList(temp)
        }  
    })
    .catch((error: { response: { data: any; }; "": any; })=>{
        if(error.response.data) alertMessage(error.response.data)
    })
}
const getInquiryListFunc = () => {
  getInquiryList({userUuid : adminData.adminUuid, limit : 3, showType:"all"})
    .then((res)=>{
      if(res.data) setInquiryList(res.data)
    })
  .catch((error: { response: { data: any; }; "": any; })=>{
      if(error.response.data) alertMessage(error.response.data)
  })
}

  // 선택된 학교 없으면 학교페이지로 보내야함 navigate(key, { state: {page:""} })
    return (
        <div className="adminMainWarpper">
            <div className="adminMainTopWarpper">
                <div className="adminMainTopContainer">
                    <div className="adminMainHeader">공지사항</div>
                    < Table showSorterTooltip={false} dataSource={noticeList} columns={noticeColumns} pagination={false} rowKey={(render)=>render.noticeUuid}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {navigate(ROUTES.ADMIN_NOTICELIST_F+'/'+record.noticeUuid)}, // click row
                      };
                    }}/>
                </div>
                <div className="adminMainTopContainer">
                    <div className="adminMainHeader">문의</div>
                    < Table showSorterTooltip={false} dataSource={inquiryList} columns={inquiryColumns} pagination={false} rowKey={(render)=>render.inquiryUuid}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {navigate(ROUTES.ADMIN_INQUIRYLIST_F+"/"+record.inquiryUuid) }, // click row
                      };
                    }}/>
                </div>
            </div>
            <div className="adminMainDownloadWrapper">
                <div className="adminMainHeader adminMainHeaderB"><span>메타캠퍼스 설치 안내</span>
                <div>
                  <DownloadGiudebtn/>
                  <Downloadbtn/>
                </div>
                
                </div>
                < Table showSorterTooltip={false} dataSource={downLoadDataSource} columns={downLoadColumns} pagination={false}/>
            </div>
            <div>
                <div className="adminMainHeader">자주 묻는 질문</div>
                <Faq isStudent={false}/>
            </div>
            
        </div>
    );
}

export const Downloadbtn = () => {
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const downloadFunc = () => {
    try{
      if(!adminData.adminUuid || adminData.adminUuid.length<1) return alertMessage("유효한 접근이 아닙니다.");
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/downloadAdmin?adminUuid=${adminData.adminUuid}`;
        link.setAttribute('download', 'MetaCampus.exe');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    } catch (e) {
        console.log(e)
    }
}
  return (
      <Button style={{width:'200px'}} type="primary" size="large" onClick={()=>downloadFunc()}>인천메타캠퍼스 설치</Button>
  )
}
export const DownloadGiudebtn = () => {
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  const downloadFunc = () => {
    try{
      if(!adminData.adminUuid || adminData.adminUuid.length<1) return alertMessage("유효한 접근이 아닙니다.");
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/guideBookAdmin?adminUuid=${adminData.adminUuid}`;
        link.setAttribute('download', 'MetaCampusAdminGuide.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    } catch (e) {
        console.log(e)
    }
}
  return (
      <Button style={{width:'250px', marginRight:'20px', background: "#041529"}} type="primary" size="large" onClick={()=>downloadFunc()}>사용자 가이드북 다운로드</Button>
  )
}