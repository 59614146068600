import React, { useEffect, useState } from 'react'
import { Button, Table, Input,Select, Switch } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Register from './Register';
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import { deleteAdminList, getUserListBySchool, setInitUserPw } from '../../../../_actions/AdminAction';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { getCheckList, saveCheckStatus } from '../../../../_actions/SystemCheckAction';
import dayjs from 'dayjs';
import { numberFormatter, textareaDBToSpace } from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';


const { Option } = Select;

export interface DataType {
    systemCheckIndex:number;
    systemCheckHeader : string;
    systemCheckBody : string;
    systemCheckStatus : number;
    createdDate:string;
    startDate:string;
    endDate:string;
  }
  function SystemList() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const columns: ColumnsType<DataType> = [
        {
            title: '번호',
            dataIndex: 'systemCheckIndex',
            sorter: {
                compare: (a, b) => a.systemCheckIndex-b.systemCheckIndex,
              },
            render: (text, row, index) => {
                    return(<div>{(currentPage-1)*10+(index+1)}</div>)
                }
        },
        {
            title: '제목',
            dataIndex: 'systemCheckHeader',
            sorter: {
                compare: (a, b) => a.systemCheckHeader.localeCompare(b.systemCheckHeader),
            }
        },
        {
            title: '내용',
            dataIndex: 'systemCheckBody',
            sorter: {
                compare: (a, b) => a.systemCheckBody.localeCompare(b.systemCheckBody),
            },
            render: (text, row, index) => {
                return(<div>{textareaDBToSpace(text)}</div>)
            }
        },
        {
            title: '시작날짜',
            dataIndex: 'startDate',
            sorter: {
                compare: (a, b) => a.startDate.localeCompare(b.startDate),
            },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD');
                return(<div>{dayjsFormat}</div>)
            }
        },
        {
            title: '마감 날짜',
            dataIndex: 'endDate',
            sorter: {
                compare: (a, b) => a.endDate.localeCompare(b.endDate),
            },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD');
                return(<div>{dayjsFormat}</div>)
            }
        },
        {
            title: '생성 날짜',
            dataIndex: 'createdDate',
            sorter: {
                compare: (a, b) => a.createdDate.localeCompare(b.createdDate),
            },
            render: (text, row, index) => {
                let dayjsFormat = dayjs(text).format('YYYY-MM-DD HH:mm');
                return(<div>{dayjsFormat}</div>)
            }
        },
        {
            title: '',
            dataIndex: 'systemCheckStatus',
            render: (text, row, index) => {
                return(<><SwitchComponent text={text} row={row} index={index} checkList={checkList} adminUuid={adminData.adminUuid} setCheckList={setCheckList}/></>)
            }
        },
    ];
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [searchName, setSearchName] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [page, setPage] = useState("");
    const [checkList, setCheckList] = useState<DataType[]>([]);
    const [checkListSaved, setCheckListSaved] = useState([]);
    const [selectedcheckIndex, setSelectedCheckIndex] = useState(0);
    const {adminData} = useSelector((state : RootState) => state.adminReducer);
  
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    useEffect(()=>{
        getCheckListFunc()
    },[])
    const getCheckListFunc = () => {
        getCheckList({adminUuid : adminData.adminUuid})
        .then((res)=>{
            if(res.data){
                let temp = res.data;
                setCheckList(temp)
                setCheckListSaved(temp)
            }  
        })
        .catch((error: { response: { data: any; }; "": any; })=>{
            if(error.response.data) alertMessage(error.response.data)
        })
    }
const searchHandler = () => {
    setSearchCategory(searchCategory)
    let temp = checkListSaved.slice()
        if(searchCategory === ""){
            temp = temp.filter((c:DataType) => 
           c.systemCheckHeader.includes(searchName) || c.systemCheckBody.includes(searchName) || (searchName==='활성' ? c.systemCheckStatus===1 : true) || (searchName==='비활성' ? c.systemCheckStatus===0 : true) )
        }
        if(searchCategory === "systemCheckHeader"){temp = temp.filter((c:DataType) => c.systemCheckHeader.includes(searchName))}
        if(searchCategory === "systemCheckBody"){temp = temp.filter((c:DataType) => c.systemCheckBody.includes(searchName))}
        if(searchCategory === "systemCheckStatus_1"){temp = temp.filter((c:DataType) => c.systemCheckStatus===1 )}
        if(searchCategory === "systemCheckStatus_0"){temp = temp.filter((c:DataType) => c.systemCheckStatus===0 )}
        setCheckList(temp)
}
const [currentPage, setcurrentPage] = useState(1);
  const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
      setcurrentPage(pagination.current || 1);
      console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className='adminContentSection'>
    <div className='adminListHeader'>점검 관리</div>
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                    <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                        <Option value= "">전체</Option>
                        <Option value= "systemCheckHeader">제목</Option>
                        <Option value= "systemCheckBody">내용</Option>
                        <Option value= "systemCheckStatus_1">활성</Option>
                        <Option value= "systemCheckStatus_0">비활성</Option>
                    </Select>
                    <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}/>
                    <Button onClick={()=>searchHandler()}>검색</Button>
                </div>
                <div className='adminBtnSection'><Button type='primary' onClick={()=>navigate(ROUTES.ADMIN_SYSTEMCREATE_F)}>등록</Button></div>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={checkList} tableLayout={"auto"}
            rowKey={(render)=>render.systemCheckIndex}
            onChange={onCurrentPageChange}
            // onRow={(record, rowIndex) => {
            //     return {
            //       onClick: (event) => {startEditmode(record.systemCheckIndex)}, // click row
            //     };
            //   }}
            />
        </div>
    </div>
  )
}

const SwitchComponent = (props:any) => {
    let {row, text, index, adminUuid, checkList, setCheckList} = props;
    const [loading, setLoading] = useState(false);
    const [validDate, setValidDate] = useState(true);
    const statusChangeHandler =(row:any, index:any) => {
        setLoading(true)
        let checkData = row.systemCheckStatus ===0?1:0
        saveCheckStatus({adminUuid, systemCheckIndex : row.systemCheckIndex, systemCheckStatus : checkData})
        .then((res)=>{
            if(res.data){
                let temp = checkList.slice();
                temp[index].systemCheckStatus = checkData
                setCheckList(temp)
            }
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
        })
    }
    useEffect(()=>{
     let {startDate, endDate} = row;
    }, [row] )
    return(
        <Switch checkedChildren="활성" unCheckedChildren="비활성" checked={!!text} 
                onChange={(val)=>{statusChangeHandler(row, index)}} disabled={loading && !validDate}
                />
    )
}
export default SystemList
