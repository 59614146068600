import React, { useState } from 'react'
import { passwordValidCheck } from '../../../../constants/common';
import { alertMessage, passwordIncoding } from '../../../../Utils/Utils';
import { changePw } from '../../../../_actions/UserAction';
import * as ROUTES from '../../../../constants/router'
import { useNavigate } from 'react-router-dom';
import { Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function PasswordChange() {
  let navigate = useNavigate();
  const {userData} = useSelector((state : RootState) => state.userReducer);
  const [userPassword, setUserPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userPasswordMsg, setUserPasswordMsg] = useState("");
  const [newPasswordMsg, setNewPasswordMsg] = useState("");
  const [passwordConfirmMsg, setPasswordConfirmMsg] = useState("");
  const onPasswordHandler = (event: { currentTarget: { name : React.SetStateAction<string>, value: React.SetStateAction<string>; }; }) => {
    setUserPasswordMsg(""); setNewPasswordMsg(""); setPasswordConfirmMsg("")
    if(event.currentTarget.name === 'userPassword') setUserPassword(event.currentTarget.value)
    if(event.currentTarget.name === 'newPassword') setNewPassword(event.currentTarget.value)
    if(event.currentTarget.name === 'passwordConfirm') setPasswordConfirm(event.currentTarget.value)
    }
const onSubmitHandler = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if(userPassword ===""){
      setUserPasswordMsg("비밀번호를 입력하세요.")
      return;}
    if(newPassword ===""){
      setNewPasswordMsg("비밀번호를 입력하세요.")
      return;}
    if(passwordConfirm ===""){
      setPasswordConfirmMsg("비밀번호를 입력하세요.")
      return;}
    if(!passwordValidCheck(newPassword)) return setNewPasswordMsg("비밀번호가 올바르지 않습니다.")
    if(newPassword !== passwordConfirm) return setPasswordConfirmMsg("비밀번호가 일치하지 않습니다.")
    
    
    let body = {
      password: passwordIncoding(userPassword),
      newPassword: passwordIncoding(newPassword),
      userUuid : userData.userUuid
    }
    changePw(body).then(((response)=>{
        if(!response.data.resultCode){
            if(response.data.resultMsg === 'userPassword') setUserPasswordMsg("비밀번호가 일치하지 않습니다.")
        } else if(response.data.resultCode) {
            setUserPassword(""); setUserPasswordMsg("")
            setNewPassword(""); setNewPasswordMsg("")
            setPasswordConfirm(""); setPasswordConfirmMsg("")
            Modal.info({
              content: (
                <div className='pwChangeModalContent'>
                  <div className='title'>비밀번호 변경 완료</div>
                  <div className='body'>
                    <div>변경하신 비밀번호입니다.</div>
                    <div>꼭 기억해 주세요.</div>
                  </div>
                  <div className='pw'>{newPassword}</div>
                </div>
              ),
              className:"pwChangeModal",
              okText:"확인",
              onOk() { 
                navigate(ROUTES.USER_PROFILE_F)
              },
              centered:true
          }) 
        }
    })).catch((error) => {
        console.log(error)
        if(error.response.data) alertMessage(error.response.data)
    });
    }
  return (
    <div className='pwcgWrapper'>
        <div className='pwchText1'>비밀번호 변경</div>
        <div className='pwchText2'><span className='bluetext'>최소8 ~ 최대16글자</span> (영문+숫자+기호) / 기호 : !@#$%^&*_</div>
        <div className='passwordBox userLoginInput pwbox1'><Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='userPassword' value={userPassword}
        placeholder='기존 비밀번호 입력' onChange={onPasswordHandler} required/></div>
        <div>{userPasswordMsg}</div>
        <div className='passwordBox userLoginInput pwbox1'><Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='newPassword' value={newPassword}
        placeholder='새로운 비밀번호 입력' onChange={onPasswordHandler} required/></div>
        <div>{newPasswordMsg}</div>
        <div className='passwordBox userLoginInput pwbox1'><Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} type='password' name='passwordConfirm' value={passwordConfirm}
        placeholder='새로운 비밀번호 재입력' onChange={onPasswordHandler} required/></div>
        <div>{passwordConfirmMsg}</div>
        <div className='loginBtnWrapper' onClick={onSubmitHandler}>
            <div >비밀번호 변경</div>
          </div>
    </div>
  )
}

export default PasswordChange
