import React, { useEffect, useState } from 'react'
import MypageHeader from './MypageHeader'
import { Button, Input, Modal, Select } from 'antd'
import { inquiryCategoryToInt, isNumeric, phoneformatter, textareaStringToDB } from '../../../../constants/common';
import { confirmMessage, infoMessage } from '../../../../Utils/Utils';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router.js'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { saveInquiry } from '../../../../_actions/InquiryAction';

const { Option } = Select;
const { TextArea } = Input;

function Inquiry(props:any) {
    let { isUser=true, setTab, inquiryUuid="" } = props;
    let navigate = useNavigate();
    const {userData} = useSelector((state : RootState) => state.userReducer);
    const {adminData} = useSelector((state : RootState) => state.adminReducer);
    const [inquiryCategory, setInquiryCategory] = useState("시스템");
    const [writerPhone, setWriterPhone] = useState("");
    const [emailId, setEmailId] = useState("");
    const [emailDomain, setEmailDomain] = useState("");
    const [inquiryHeader, setInquiryHeader] = useState("");
    const [inquiryBody, setInquiryBody] = useState("");
    const onChangeInquiryCategory = (value: string) => {
        setInquiryCategory(value);       
    };
    const onChangeWriterPhone = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString().replaceAll("-","");
        if(!isNumeric(val) || val.length>11) return false;
        setWriterPhone(val)
        
    }
    const onChangeEmailId = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setEmailId(val);       
    };
    const onChangeEmailDomain = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setEmailDomain(val);       
    };
    const onChangeEmailDomainSelect = (value: string) => {
        setEmailDomain(value);       
    };
    const onChangeInquiryHeader = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setInquiryHeader(val);       
    };
    const onChangeInquiryBody = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        if(val.length>1000) return false;
        setInquiryBody(val);       
    };
    const onSubmitHandler = () => {
        if(
            inquiryHeader === "" ||
            inquiryBody === ""
            // ||
            // writerPhone === "" ||
            // emailId === "" ||
            // emailDomain === ""
        ){return false}

        let body = {
            inquiryUuid:"",
            inquiryStatus:0,
            inquiryHeader : inquiryHeader,
            inquiryBody : textareaStringToDB(inquiryBody),
            inquiryCategory : inquiryCategoryToInt(inquiryCategory),
            writerPhone : writerPhone,
            writerEmail : emailId + '@' + emailDomain,
            userUuid: isUser ? userData.userUuid : adminData.adminUuid,
            userType: isUser ? userData.userType : adminData.userType,
        }
        console.log(body)
        saveInquiry(body)
        .then((res)=>{
            if(res.data){
                Modal.info({
                    content : (<div className='mainCampusModalContent'>문의가 등록 되었습니다.</div>),
                okText:"확인",
                closable:false,
                centered:true,
                className:"mainCampusModal",
                onOk() { 
                    isUser ? navigate(ROUTES.USER_INQUIRYLIST_F) : setTab(1)
                }
            })
            }
        })
    };
  return (
    <>
        {isUser && <MypageHeader page="inquiry"/>}
    <div className='mypageWrapper'>
        {isUser && <div className='inquiryTitletext'>문의를 남겨 주시면, <strong>신속하게</strong> 도와 드리겠습니다.</div>}
        <div className='inquiryFormWrapper'>
            <div className='inquiryRowWrapper'>
                <div className='inquiryTypeWrapper'>
                    <div className='inquryType'>문의유형</div>
                    <Select className='inquiryTypeBox' value={inquiryCategory} onChange={onChangeInquiryCategory}>
                        <Option value= "시스템">시스템</Option>
                        <Option value= "수업">수업</Option>
                        <Option value= "계정">계정</Option>
                    </Select>
                </div>
                <div className='inquiryTypeWrapper'>
                    <div className='inquryType'>연&nbsp;락&nbsp;처</div>
                    <Input className='inquiryTypeBox' value={phoneformatter(writerPhone)} onChange={onChangeWriterPhone}/> 
                </div>
            </div>
            <div className='inquiryRowWrapper'>
                <div className='inquiryTypeWrapper'>
                    <div className='inquryType'>이&nbsp;메&nbsp;일</div>
                    <Input className='inquiryTypeBox' type='text' value={emailId} onChange={onChangeEmailId}/>
                    <div style={{textAlign:'center', lineHeight:'30px', marginLeft:'17px', marginRight:'17px'}}>@</div>
                    <Input className='emailInput' type='text' value={emailDomain} onChange={onChangeEmailDomain}/>
                </div>    
                <div className='emailAccount'>
                    <Select onChange={onChangeEmailDomainSelect} defaultValue={""}>
                        <Option value= "">직접입력</Option>
                        <Option value= "naver.com">naver.com</Option>
                        <Option value= "hanmail.net">hanmail.net</Option>
                        <Option value= "gmail.com">gmai
                        l.com</Option>
                    </Select>
                </div>
            </div>
            <div className='inquiryRowWrapper'>
                <div className='inquiryTypeWrapper'>
                    <div className='inquryType'>제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</div>
                    <Input className='titleBox' type='text' value={inquiryHeader} onChange={onChangeInquiryHeader}/> 
                </div>
            </div>
            <div className='inquiryRowWrapper'>
                <div className='inquiryTypeWrapper'>
                    <div className='inquryType'>문의내용</div>
                    <TextArea className='inquiryContentBox' rows={10} value={inquiryBody} onChange={onChangeInquiryBody}
                    style={{ resize: 'none' }}/> 
                </div>
            </div>
        </div>
        <div className='inquiryBottomBtn'>
            <Button onClick={()=>isUser ? navigate(ROUTES.USER_INQUIRYLIST_F) : setTab(1)}>목록보기</Button>
            <Button type='primary' onClick={()=>onSubmitHandler()}>문의하기</Button>
        </div>
    </div>
    </>
  )
}


export default Inquiry