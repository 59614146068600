import React, { useEffect, useState } from 'react'
import { getPolicy } from '../../../../_actions/AdminAction';
import SunEditor from 'suneditor-react';

function Privacy() {
    //개인정보 처리 방침
    const [privacyContents, setprivacyContents] = useState('');
    useEffect(()=>{
        getPolicy({policyIndex:0})
        .then((res)=>{
            if(res.data){
                setprivacyContents(res.data.privacyContents)
              }
        })
    }, [])
    return (
        <div>
            <div>
                {noEditableSunEditor(privacyContents)}
            </div>
        </div>
    )
}

export default Privacy

export const noEditableSunEditor = (PRESENTATION_CONTENTS:any) => {
    return(
        <SunEditor lang="ko" height='auto'
            setOptions={{ 
                resizingBar: false,
                defaultStyle : `font-size : 1.5rem`
            }}
            hideToolbar={true}
            disableToolbar={true}
            disable={true}
            setContents={PRESENTATION_CONTENTS}
            setAllPlugins={false}
            />
    )
}