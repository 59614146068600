import React, { useEffect, useState } from 'react'
import { Button, Table, Input,Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { alertMessage, confirmMessage } from '../../../../Utils/Utils';
import { deleteAdminList, getUserListBySchool, setInitUserPw } from '../../../../_actions/AdminAction';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

export interface DataType {
    adminSchoolname: string;
    userName : string;
    userAccount : string;
    userUuid : string;
  }
  function StaffList() {
    const navigate = useNavigate();
    const columns: ColumnsType<DataType> = [
        {
            title: '번호',
            dataIndex: 'index',
            render: (text, row, index) => {
                return(<div>{(currentPage-1)*10+(index+1)}</div>)
            },
            width:'10%'
        },
        {
            title: '이름',
            dataIndex: 'userName',
            sorter: {
                compare: (a, b) => a.userName.localeCompare(b.userName),
            },
            width:'25%'
        },
        {
            title: '소속',
            dataIndex: 'adminSchoolname',
            sorter: {
                compare: (a, b) => a.adminSchoolname.localeCompare(b.adminSchoolname),
            },
            width:'40%'
        },
        {
            title: '아이디',
            dataIndex: 'userAccount',
            sorter: {
                compare: (a, b) => a.userAccount.localeCompare(b.userAccount),
            },
            width:'25%'
        }
    ];
    const startEditmode = (row:DataType) => {
        navigate(ROUTES.ADMIN_STAFFITEM_I+row.userAccount)
        setPage("- 조회")
        setSelectedUserData(row)
    }
    const endEditmode = () => {
        setPage("")
        setSelectedUserData(null)
    }
    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const [searchName, setSearchName] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [page, setPage] = useState("");
    const [staffList, setStaffList] = useState([]);
    const [staffListSaved, setStaffListSaved] = useState([]);
    const [selectedlist, setSelectedlist] = useState([]);
    const [selectedUserData, setSelectedUserData] = useState<DataType|null>();
    const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
        let val:string = event.currentTarget.value.toString();
        setSearchName(val)
    }
    useEffect(()=>{
        getUserList()
    },[])
    const getUserList = () => {
        getUserListBySchool({schoolUuid : "ice", userType : 3})
        .then((res)=>{
            if(res.data){
                // console.log(res.data)
                let temp = res.data.sort((a: { userAccount: any; },b: { userAccount: any; })=>a.userAccount.localeCompare(b.userAccount))
                setStaffList(temp)
                setStaffListSaved(temp)
            }  
        })
        .catch((error: { response: { data: any; }; "": any; })=>{
            if(error.response.data) alertMessage(error.response.data)
        })
    }
const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRowKeys)
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    }),
};
const setInitPassword = (list:any) => {
    confirmMessage("비밀번호 초기화", <div>정말로 비밀번호를 초기화 하시겠습니까?<br/>진행하시면 초기 비밀번호 ‘1111’로 변경됩니다.</div>, 'primary', ()=>{
        setInitUserPw(list)
        .then((res)=>{
            if(res.data) console.log('초기화 완료')
        })
    })
}
const deleteUser = () => {
    // console.log(selectedlist)
    confirmMessage("직원 정보 삭제", <div>정말로 삭제하시겠습니까?<br/>삭제하시면 데이터복구 불가능합니다.</div>, 'primary', ()=>{
        deleteAdminList(selectedlist)
        .then((res)=>{
            if(res.data) console.log('삭제 완료')
        })
    })
}
const searchHandler = () => {
    let temp = staffListSaved.slice()
    if(searchName !== ""){
        if(searchCategory==="") temp = temp.filter((c:DataType) => c.adminSchoolname.includes(searchName) || c.userName.includes(searchName) || c.userAccount.includes(searchName))
        if(searchCategory==="userName") temp = temp.filter((c:DataType) => c.userName.includes(searchName))
        if(searchCategory==="userAccount") temp = temp.filter((c:DataType) => c.userAccount.includes(searchName))
        if(searchCategory==="adminSchoolname") temp = temp.filter((c:DataType) => c.adminSchoolname.includes(searchName))
    }
    setStaffList(temp)
}

const [currentPage, setcurrentPage] = useState(1);
const onCurrentPageChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    setcurrentPage(pagination.current || 1);
    console.log('params', pagination, filters, sorter, extra);
};
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>직원 관리{page}</div>
        <div>
            <div className='adminSearcWrapper'>
                <div className='adminSearcSection'>
                    <Select className='wideSelect' onChange={setSearchCategory} value={searchCategory} defaultValue={""}>
                        <Option value= "">전체</Option>
                        <Option value= "userName">이름</Option>
                        <Option value= "adminSchoolname">소속</Option>
                        <Option value= "userAccount">아이디</Option>
                    </Select>
                    <Input value={searchName} placeholder='검색어를 입력해 주세요.' onChange={onChangeSearchName}/>
                    <Button onClick={()=>searchHandler()}>검색</Button>
                </div>
                <div className='adminBtnSection'>
                    <Button disabled={selectedlist.length===0} onClick={()=>setInitPassword(selectedlist)}>비밀번호 초기화</Button>
                    <Button disabled={selectedlist.length===0} onClick={()=>deleteUser()}>삭제</Button>
                </div>
            </div>
            < Table showSorterTooltip={false} columns={columns} dataSource={staffList} tableLayout={"auto"}
            rowKey={(render)=>render.userAccount}
            onChange={onCurrentPageChange}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {startEditmode(record)}, // click row
                };
              }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}/>
        </div>
    </div>
  )
}

export default StaffList
