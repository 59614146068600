import { Button, Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, getAdmissionYearList, getUserAccount, onlyNumberAndEnglish } from '../../../../constants/common';
import { createUserList, getUserInfo, setInitUserPw } from '../../../../_actions/AdminAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { alertMessage, confirmMessage, infoMessageTitle } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';
const { Option } = Select;

function Register(props:any) {

  const navigate = useNavigate();
  const params = useParams();
  // let {isEditing=false, userData, setInitPassword}=props;
  const {schoolInfo:{schoolUuid} } = useSelector((state : RootState) => state.adminReducer);
  const [userName, setUserName] = useState("");
  const [userUuid, setUserUuid] = useState("");
  const [userAccount, setUserAccount] = useState("");
  const [kidsName, setKidsName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  
  const [msg, setMsg] = useState("");
  const onChangeUserName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setUserName(trimAndNoSpecial(val));       
  };
  const onChangeUserAccount = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setUserAccount(onlyNumberAndEnglish(val));       
  };
  const onChangeKidsName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setKidsName(trimAndNoSpecial(val));       
  };
  useEffect(()=>{
    if(params && params.detail){
      if(params.detail === 'register'){
        setIsEditing(false)
      } else if(params.detail.length>0) {
        setIsEditing(true)
        setUserAccount(params.detail)
      }
    }
  }, [params])
  const {schoolInfo} = useSelector((state : RootState) => state.adminReducer);
  useEffect(()=>{
    if(isEditing && userAccount.length>0){
      getUserInfo({account : userAccount, schoolUuid: schoolInfo.schoolUuid, userType:`${userTypeStringToInt('학부모')}`})
        .then((res)=>{
          console.log(res.data)
            if(res.data){
              setUserName(res.data.userName)
              setKidsName(res.data.kidsName)
              setUserUuid(res.data.userUuid)
            }
        })
      
    }
  }, [userAccount])
  // useEffect(()=>{
  //   if(isEditing){
  //     setUserName(userData.userName)
  //     setUserAccount(userData.userAccount)
  //     setKidsName(userData.kidsName)
  //   }
  // }, [isEditing])

  const submitHandler=()=>{
    let data = {
      userUuid : isEditing ? userUuid : null,
      userAccount,
      admissionYear:"",
      grade : 0,
      gradeClass : 0,
      gradeNumber : 0,
      userName,
      userType: userTypeStringToInt('학부모'),
      schoolUuid,
      kidsName
    }
    if(
        userName.length ===0 ||userAccount.length ===0||kidsName.length ===0) return setMsg("입력필드를 확인해 주세요.")
    createUserList([data])
    .then((res)=>{
      console.log(res.data)
        if(res.data.length===0){
          Modal.info({
            title: (isEditing ? '수정': '등록')+' 완료되었습니다.',
          content: (
              <div>
                  <div>이름 : {userName}</div>
                  <div>아이디 : {userAccount}</div>
              </div>
          ),
          onOk() { 
            navigate(ROUTES.ADMIN_PARENTS_F)
          },
          centered:true
        })
        } else {
          infoMessageTitle("아이디 중복", <div>중복된 아이디 입니다.
          입력된 정보를 확인해 주세요.
          </div>, ()=>console.log('확인'))
        }
    })
    .catch((err)=>{
      console.log(err);
      if(err.response.data){
          alertMessage(err.response.data)
      }
    })
} 
const setInitPassword = (list:any) => {
  confirmMessage("비밀번호 초기화", <div>정말로 비밀번호를 초기화 하시겠습니까?<br/>진행하시면 초기 비밀번호 ‘1111’로 변경됩니다.</div>, 'primary', ()=>{
      setInitUserPw(list)
      .then((res)=>{
          if(res.data) console.log('초기화 완료')
      })
  })
}
  return (
    <div className='adminContentSection'>
        <div className='adminListHeader'>학부모 관리{isEditing ? "- 수정 하기" : "- 등록 하기"}</div>
        <div>
          <div className='adminRegisterWrapper'>
            <div className='adminRegisterRowWrapper'>
              <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>이름</div>
                <div className='adminRegisterInput'><Input size='large' type='text' value={userName} onChange={onChangeUserName} placeholder='이름을 입력해 주세요.'/></div>
              </div>
              <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>자녀</div>
                <div className='adminRegisterInput'><Input size='large' type='text' value={kidsName} onChange={onChangeKidsName} placeholder='이름을 입력해 주세요.'/></div>
              </div>
              <div className='adminRegisterRow'>
                <div className='adminRegisterTitle'>아이디</div>
                <div className='adminRegisterInput'>{isEditing ? <span>{userAccount}</span>
                :<Input size='large' type='text' value={userAccount} onChange={onChangeUserAccount} placeholder='아이디를 입력해 주세요.' disabled={isEditing}/>}</div>
              </div>
            </div>
            {!isEditing ?
            <div>
              <div className='adminExcelRegisterBtn'>
                <Button type='primary' size='large' onClick={submitHandler}>등록</Button>
              </div>
              <div style={{textAlign:'center'}}>{msg}</div>
            </div>
            :
              <div className='adminExcelRegisterBtn'>
                <Button size='large' onClick={()=>setInitPassword([userAccount])}>비밀번호 초기화</Button>
                <Button type='primary' size='large' onClick={submitHandler}>저장</Button>
              </div>
            }
          </div>
        </div>
    </div>
  )
}

export default Register