export const userTypeStringToInt = (auth) => {
    if(auth.includes('학생')) return 1
    if(auth.includes('학부모')) return 2
    if(auth.includes('직원')) return 3
    if(auth.includes('강사') || auth.includes('교사')) return 4
    if(auth.includes('슈퍼') || auth.includes('수퍼') || auth.includes('관리자')) return 5
}


export function userTypeIntToString(userType){
    // if(typeof userType !== "Number" ) return false;
    if(userType === 1 ) return '학생'
    if(userType === 2 ) return '학부모'
    if(userType === 3 ) return '직원'
    if(userType === 4 ) return '교사'
    if(userType === 5 ) return '슈퍼'
}
