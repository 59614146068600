import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import * as ROUTES from './constants/router.js'

import HomeUser from './components/user/HomeUser';
import HomeAdmin from './components/admin/HomeAdmin';

import TempPage from './components/Temp';
import axios from 'axios';

//선에디터 css
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

function App() {
  if (process.env.REACT_APP_MODE === "production") {
    console.log = function no_console() {};
    console.debug = function no_console() {};
  }
  axios.interceptors.request.use(function (req : any) {
      req.withCredentials = true;
      return req;
    }, function (err) {
      return Promise.reject(err)
    })

  return (
    <Router>
      <div style={{minWidth: '1280px'}}>
        <Routes>
          <Route path="/" element={<Navigate replace to={ROUTES.USER_COMMON}/>} />
          <Route path={ROUTES.USER_ALL} element={<HomeUser />} />
          <Route path={ROUTES.ADMIN_ALL} element={<HomeAdmin />} />
          <Route path="/*" element={<Navigate replace to={ROUTES.USER_COMMON}/>} />
        </Routes>
      </div>
      </Router>
  ); 
}

export default App;
