import { Modal } from 'antd';
import { LegacyButtonType } from "antd/lib/button/button";
const { confirm, info } = Modal;

export function getReadableFileSize(fileSizeInBytes: number) {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0).toFixed(1) + byteUnits[i];
};

export function getReadableMBFileSize(fileSizeInBytes: number) {
    fileSizeInBytes = fileSizeInBytes / (1024 * 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + 'MB';
};

export function confirmMessage(
    titleName:string, content:any, confirmType:LegacyButtonType | undefined, exceFunction:Function, cancelFunction?:Function
) {
    confirm({
        title: titleName,
        okText: '확인',
        okType: confirmType,
        cancelText: '닫기',
        content:content,
        onOk() {
            exceFunction();
        },
        onCancel() {
            cancelFunction && cancelFunction();
        },
    });
}
export function confirmDeleteMessage(content:any, confirmType:LegacyButtonType | undefined, exceFunction:Function, cancelFunction?:Function
) {
    confirm({
        okText: '삭제',
        okType: confirmType,
        cancelText: '닫기',
        content:content,
        icon:null,
        onOk() {
            exceFunction();
        },
        onCancel() {
            cancelFunction && cancelFunction();
        },
    });
}
export function alertMessage(params:any) {
    Modal.error({
        content: params,
        style : {top:'38%', whiteSpace:'pre-line'},
        centered:true
    });  
}

export function infoMessage(params:any, exceFunction:Function) {
    Modal.info({
        content: params,
        onOk(){
            exceFunction && exceFunction()
        },
        centered:true
    }); 
}
export function infoMessageTitle(title:string, params:any, exceFunction:Function) {
    info({
        title,
        content: params,
        onOk(){
            exceFunction && exceFunction()
        }
    }); 
}


export function passwordIncoding(DecodingPassword:string){
    // 암호화 추가
    let salt = `${process.env.REACT_APP_SALT}`;
    const sha256 = require('sha256');
    // console.log(`${salt}${DecodingPassword}`);
    return sha256(`${salt}${DecodingPassword}`)
}
// !@#$%^&*0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ // 8자리