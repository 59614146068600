import { Button, Checkbox, Input, InputNumber, Modal, Select, Table } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import { trimAndNoSpecial, onlyNumberAndEnglish, removeDuplication, objectArrToStringArr } from '../../../../constants/common';
import { alertMessage } from '../../../../Utils/Utils';
import { userTypeStringToInt } from '../../../../constants/auth';
import { getTeam, saveTeam, searchChannelList, searchTeamUserList } from '../../../../_actions/ChannelAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../_reducers';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../constants/router';

const { Option } = Select;

export interface DataType {
  channelUuid : string;
  channelHeader : string;
  eventLimit : number;
  adminUuid : string;
  adminName : string;
}
const initialData =  {
  channelUuid : "",
  channelHeader : "",
  eventLimit : 0,
  adminUuid : "",
  adminName : ""
}
interface TeamUserType {
  grade:number;
  gradeClass:number;
  gradeNumber:number;
  userName : string;
  schoolName : string;
  userAccount:string;
}
const columns: ColumnsType<TeamUserType> = [
  {
      title: '학년',
      dataIndex: 'grade',
      sorter: {
          compare: (a, b) => a.grade - b.grade,
      },
      render: (text, row, index) => {
              return(<div>{text}</div>)
          },
      width:'10%'
  },
  {
    title: '반',
    dataIndex: 'gradeClass',
    sorter: {
        compare: (a, b) => a.gradeClass - b.gradeClass,
    },
    render: (text, row, index) => {
            return(<div>{text}</div>)
        },
        width:'10%'
  },
  {
    title: '번호',
    dataIndex: 'gradeNumber',
    sorter: {
        compare: (a, b) => a.gradeNumber - b.gradeNumber,
    },
    render: (text, row, index) => {
            return(<div>{text}</div>)
        },
        width:'10%'
  },
  {
      title: '이름',
      dataIndex: 'userName',
      sorter: {
          compare: (a, b) => a.userName.localeCompare(b.userName),
      },
      width:'20%'
  },
  {
      title: '학교',
      dataIndex: 'schoolName',
      sorter: {
          compare: (a, b) => a.schoolName.localeCompare(b.schoolName),
      },
      width:'25%'
  },
  {
    title: '아이디',
    dataIndex: 'userAccount',
    sorter: {
        compare: (a, b) => a.userAccount.localeCompare(b.userAccount),
    },
    width:'25%'
}
];
function Register() {
  const params = useParams();
    const navigate = useNavigate();
    const [selectedUuid, setSelectedUuid] = useState("");
    const [readOnlyValue, setReadOnlyValue] = useState(false);
    useEffect(()=>{
      if(params && params.detail){
        if(params.detail === 'create'){

        } else if(params.detail.length>10) {
          setReadOnlyValue(true)
          setSelectedUuid(params.detail)
        }
      }
    }, [params])
  const {adminData} = useSelector((state : RootState) => state.adminReducer);
  let userType = adminData.userType
  const [teamHeader, setTeamHeader] = useState("");
  const [teamLimit, setTeamLimit] = useState(1);
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [channelData, setChannelData] = useState<DataType>(initialData);
  const [channelName, setChannelName] = useState("");
  const [channelDataTemp, setChannelDataTemp] = useState<DataType>(initialData);
  const [channelNameTemp, setChannelNameTemp] = useState("");
  const [teamUserList, setTeamUserList] = useState<any[]>([]);
  const [teamUserListTemp, setTeamUserListTemp] = useState([]);
  const [selectedlist, setSelectedlist] = useState([]);
  const [selectedKeylist, setSelectedKeylist] = useState([]);
  const [deletelist, setDeletelist] = useState<string[]>([]);
  const [validBtn, setValidBtn] = useState(false);
  const [searchInit, setSearchInit] = useState(true);
  
  const adminModalCloseHandler = () => {
    setSearchInit(true)
    setAdminModalOpen(false)
    setChannelData(channelDataTemp)
    setChannelName(channelNameTemp)
    setSearchName(channelNameTemp)
  }
  const adminModalOpenHandler= () => {
    setAdminModalOpen(true)
    setChannelNameTemp("")
    setChannelDataTemp(initialData)
    setSearchName("")
    setChannelList([])
  }
  const userModalCloseHandler = () => {
    setUserModalOpen(false)
    let temp = [...teamUserList, ...selectedlist.slice()];
    let filteredTemp = removeDuplication(temp)
    if(channelData.eventLimit !==0 && filteredTemp.length>channelData.eventLimit) return alertMessage("팀 인원이 채널 인원을 초과하였습니다.")
    if(filteredTemp.length>teamLimit) return alertMessage("팀 인원을 초과하였습니다.")
    setTeamUserList(filteredTemp)
    setSelectedlist([])
    setSelectedKeylist([])
    setDeletelist([])
  }
  const userModalOpenHandler= () => {
    setUserModalOpen(true)
    setTeamUserListTemp([])
    setSearchUserName("")
    setSelectedlist([])
    setSelectedKeylist([])
    setDeletelist([])
  }
  const searchChannelHandler = () => {
    searchChannelList({searchName, adminUuid : adminData.adminUuid})
    .then((res)=>{
      // console.log(res.data)
      if(res.data) setChannelList(res.data); setSearchInit(false);
    })
  }
  const searchUserHandler = () => {
    searchTeamUserList({searchUserName})
    .then((res)=>{
      if(res.data) setTeamUserListTemp(res.data)
    })
  }
  const searchResultSelectHandler = (data:any, name:string) => {
    setSearchName(name)
    setChannelDataTemp(data);
    setChannelNameTemp(name)
  }
  const onChangeSearchName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    setSearchInit(true)
    let val:string = event.currentTarget.value.toString();
    setSearchName(val)
  }
  const onChangeSearchUserName = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setSearchUserName(val)
  }
  const onChangeTeamHeader = (event: { currentTarget: { value: React.SetStateAction<string>; }; }) => {
    let val:string = event.currentTarget.value.toString();
    setTeamHeader(trimAndNoSpecial(val));       
  };
  const onChangeteamLimit = (val: any) => {
    setTeamLimit(val);       
  };
  useEffect(()=>{
    if(readOnlyValue && selectedUuid.length>0){
      getTeam({teamUuid : selectedUuid})
      .then((res)=>{
        if(!!res.data.teamInfo.channelUuid){
        let {teamHeader, teamLimit, eventLimit, channelUuid, channelHeader, adminName, teamAdmin} = res.data.teamInfo;
        setTeamHeader(teamHeader)
        setTeamLimit(teamLimit)
        setChannelData({eventLimit, channelHeader, channelUuid, adminName, adminUuid:teamAdmin})
        setChannelName(channelHeader)
        setTeamUserList(res.data.teamUsers)
        } else {
          alertMessage("유효하지 않은 접근이거나 작업 중 문제가 발생했습니다.")
        }
      })
      .catch((err)=>{if(err.response.data) alertMessage(err.response.data)})
    }
  }, [readOnlyValue])
  useEffect(()=>{
    let valid = !!teamHeader && teamHeader.length>0 && !!channelName && channelName.length>0 && !!teamUserList && teamUserList.length>0
    setValidBtn(valid)
  }, [teamHeader, channelName, teamLimit, teamUserList])
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        setSelectedlist(selectedRows)
        setSelectedKeylist(selectedRowKeys)
    },
    getCheckboxProps: (record: TeamUserType) => ({
    }),
    selectedRowKeys : selectedKeylist
};
const rowSelectionDelete = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    setDeletelist(selectedRowKeys)
  },
  getCheckboxProps: (record: TeamUserType) => ({
  }),
  selectedRowKeys : deletelist
};
const deleteTeamUser = () => {
    let userListTemp = teamUserList.slice();
    userListTemp = userListTemp.filter((c:any) => deletelist.indexOf(c.userAccount)<0);
    setTeamUserList(userListTemp)
    setDeletelist([])
}
const submitHandler =() => {
  if(teamHeader==="") return alertMessage("팀 이름을 입력하세요.");
  if(channelName ==="" || channelData.channelUuid ==="") return alertMessage("채널을 선택하세요.");
  if(channelData.eventLimit !==0 && teamLimit>channelData.eventLimit) return alertMessage("팀 인원이 채널 인원을 초과하였습니다.");
  let data = {
    adminUuid : adminData.adminUuid,
    channelUuid : channelData.channelUuid,
    teamUuid : selectedUuid,
    teamHeader,
    teamLimit,
    teamAdmin : channelData.adminUuid,
    teamNumber : teamUserList.length,
    teamUserList : objectArrToStringArr(teamUserList, 'userUuid')
  }
  if(teamUserList.length>teamLimit) return alertMessage("팀 인원을 초과하였습니다.")
  saveTeam(data)
  .then((res)=>{
    if(res.data) navigate(ROUTES.ADMIN_TEAMLIST_F)
  })
}
  return (
    <div className='adminContentSection'>
      <div className='adminListHeader'>{readOnlyValue?'팀 상세':'팀 생성'}</div>
      
      <div className='adminRegisterWrapper'>
        <div className='adminRegisterRowWrapper'>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>팀 이름</div>
            <div className='adminRegisterInput'><Input size='large' type='text' value={teamHeader} onChange={onChangeTeamHeader} placeholder='' /></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>팀 인원</div>
            <div className='adminRegisterInput'><InputNumber size='large' controls={false} min={1} max={36} value={teamLimit} placeholder='' onChange={onChangeteamLimit}/></div>
          </div>
          <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>채널 선택</div>
            <div className='adminRegisterInputF'><Input size='large' value={channelName} readOnly onClick={()=> adminModalOpenHandler()}/><Button type="primary" onClick={() => adminModalOpenHandler()}>조회</Button></div>
          </div>
          {userType === userTypeStringToInt("슈퍼") && channelData.adminName !=="" &&
            <div className='adminRegisterRow'>
            <div className='adminRegisterTitle'>담당자</div>
            <div className='adminRegisterInputF'>{channelData.adminName}</div>
          </div>}

        </div>
        <div>
          <div className='adminRegisterTableBtnWrapper'>
            <div>목록 학생 수 : {teamUserList.length}</div>
            <div><Button type="primary" onClick={() => userModalOpenHandler()}>학생 추가</Button><Button disabled={deletelist.length===0} onClick={()=>deleteTeamUser()} >삭제</Button></div>
          </div>
          < Table showSorterTooltip={false} columns={columns} dataSource={teamUserList} tableLayout={"auto"}
          rowKey={(render)=>render.userAccount}
          rowSelection={{
              type: 'checkbox',
              ...rowSelectionDelete,
          }}/>
        </div>
        <div className='adminExcelRegisterBtn'>
          <Button onClick={() => navigate(ROUTES.ADMIN_TEAMLIST_F)}>취소</Button><Button type="primary" onClick={()=>{submitHandler()}} disabled={!validBtn}>등록</Button>
        </div>
        <Modal title="채널 선택" centered open={adminModalOpen} okText="추가" onOk={() => adminModalCloseHandler()} cancelText="닫기" onCancel={() => setAdminModalOpen(false)} width={700}>
          <div style={{display:'flex', alignItems:'center'}}><Input value={searchName} placeholder='' onChange={onChangeSearchName}/><Button onClick={()=>searchChannelHandler()}>검색</Button></div>
          <div style={{margin:'20px'}}>{
            channelList.length>0 ? channelList.map((c,i)=>{
              return (<div key={i}>
                <input type='radio' name="channelDataTemp" value={c["channelHeader"]} id={`${c["channelUuid"]}`} onChange={()=>searchResultSelectHandler(c, c["channelHeader"])}/>&nbsp;&nbsp;<label htmlFor={`${c["channelUuid"]}`}>{i+1}. {c["channelHeader"]} /  {c["eventLimit"]} {userType === userTypeStringToInt("슈퍼") && channelData.adminName ==="" ? ` / ${c["adminName"]}` : null}</label>
              </div>)
            })
            :!searchInit?<div>검색 결과가 없습니다.</div>:null
            }</div>
        </Modal>
        <Modal title="학생 검색" centered open={userModalOpen} okText="추가" onOk={() => userModalCloseHandler()} cancelText="닫기" onCancel={() => setUserModalOpen(false)} width={900}>
          <div style={{display:'flex', alignItems:'center'}}><Input value={searchUserName} placeholder='' onChange={onChangeSearchUserName}/><Button onClick={()=>searchUserHandler()}>검색</Button></div>
          <div style={{margin:'20px 0'}}>{
            teamUserListTemp && <div>
                < Table showSorterTooltip={false} columns={columns} dataSource={teamUserListTemp} tableLayout={"auto"}
                rowKey={(render)=>render.userAccount}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}/>
                </div>
            }</div>
        </Modal>
      </div>
      </div>
  )
}

export default Register